import { translate } from '../../common/service/stringResourceService'
import MultilingualString from '../../common/models/multilingualString';
import TypeKind from '../../common/enums/typeKind';
import ViewKind from '../../common/enums/viewKind';
import BlockType from '../../common/enums/blockType';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import FieldKind from '../../common/enums/fieldKind';
import utils from '../../common/components/utils.js';
import Views from '../../common/collections/views';
import Block from '../../common/models/block';
import Blocks from '../../common/collections/blocks';
import Type from '../../common/models/type';
import Types from '../../common/collections/types';
import View from '../../common/models/view';
import Field from '../../common/models/field';
import Constants from '../../common/models/constants';
import BaseSelect from '../../common/components/baseSelect';
import BaseModel from '../../common/models/baseModel';
import CreateModal from '../../common/components/createModal';
import CreateView from '../../EntityView/views/createView';
import CreateField from '../../EntityField/views/createView';
import { icons } from '../../common/components/icons.js';
import { addEntityType } from '../../EntityType/views/indexMainView';
import { addEntityView, addEntityBlock, addEntityField } from '../../common/service/additionUtils';
import {
	recentlyTasks,
	renderAddBlockButton,
	renderAddFieldButton,
	renderAddTypeButton,
	renderAddViewButton
} from "../helper";

var NavigationView = Backbone.View.extend({

	events: {
		'keyup #search-input': 'delayedReload',
		'click .type-selection': 'onClickType',
		'click .navigationAddType': 'addType',
		'click .navigationAddView': 'addView',
		'click .navigationAddBlock': 'addBlock',
		'click .navigationAddField': 'addField',
		'click .navigationAddSharedField': 'addSharedField'
	},


	initialize: function () {
		this.selectedCategories = {};
	},


	render () {
		this.blockOffset = 0;
		this.viewOffset = 0;
		this.canUploadMoreViews = true;
		this.canUploadMoreBlocks = true;
		this.canUploadMoreTypes = true;
		this.canUploadMoreFields = true;
		this.setElement($('#instance-view .instance'));
		$('#instance-view').css('background-color', 'white')
		this.renderSearchBar();
		this.$input.focus();
		this.renderResultBody();
		this.updateButtonsForAddition();
		this.renderAll();
		$('#search-types').on('ps-y-reach-end', () => {
			if (this.canUploadMoreTypes) {
				this.canUploadMoreTypes = false;
				let onSuccessFunc = (types) => {
					types.forEach(type => this.renderType(new Type(type)));
					this.canUploadMoreTypes = true;
					this.typeOffset += 50;
				}, url = app.urls.getTypes;
				this.getMore(url, onSuccessFunc, this.typeOffset);
			}
		});
		$('#search-views').on('ps-y-reach-end', () => {
			if (this.canUploadMoreViews) {
				this.canUploadMoreViews = false;
				let onSuccessFunc = (views) => {
					views.forEach(view => this.renderView(new View(view)));
					this.canUploadMoreViews = true;
					this.viewOffset += 50;
				}, url = app.urls.getViews;
				this.getMore(url, onSuccessFunc, this.viewOffset);
			}
		});
		$('#search-blocks').on('ps-y-reach-end', () => {
			if (this.canUploadMoreBlocks) {
				this.canUploadMoreBlocks = false;
				let onSuccessFunc = (blocks) => {
					blocks.forEach(block => this.renderBlock(new Block(block)));
					this.canUploadMoreBlocks = true;
					this.blockOffset += 50;
				}, url = app.urls.getBlocks;
				this.getMore(url, onSuccessFunc, this.blockOffset);
			}
		});
		$('#search-fields').on('ps-y-reach-end', () => {
			if (this.canUploadMoreFields) {
				this.canUploadMoreFields = false;
				let onSuccessFunc = (fields) => {
					fields.forEach(field => this.renderField(new Field(field)));
					this.canUploadMoreFields = true;
					this.fieldOffset += 50;
				}, url = app.urls.getFields;
				this.getMore(url, onSuccessFunc, this.fieldOffset);
			}
		});
	},



	renderSearchBar: function() {
		this.$el.append(`
			<span style="padding: 0px; width: 100%;">
				<div style="width:100%" class="dropdown form-group">
					<div class="input-with-icons">
						<div class="col-lg-3">
							<div class="input-with-icons">
								<div>
									<input id="search-input" type="text" class="form-control search-input pull-right" placeholder="Search by name or id">
									<ul class="search-suggestions"></ul>
								</div>
								<span class="glyphicon glyphicon-search begin-icon pull-right" style="position: absolute;left:25px;"></span>
								<i class="material-icons notranslate close-icon" style="right: 20px;">close</i>
							</div>
						</div>
					<div class="col-lg-3">
						<div class="data-alignment preferencesData" style="padding-top:2px;display:block;">
							<span id="categories"></span>
						</div>
						<select multiple="multiple" placeholder="Filter by categories" id="categoriesFilter" class="form-control" data-field="categories"></select>
					</div>
					<div class="col-lg-6">
						<a class="recently-edited">Recently edited</a>
						<ul class="recently-edited-list"></ul>
					</div>
				</div>
			</div>
		</span>`);
		this.$input = this.$el.find('#search-input');
		this.$recentlyEdited = this.$el.find('.recently-edited');
		this.$recentlyEditedList = this.$el.find('.recently-edited-list');
		this.searchSuggestions = this.$el.find('.search-suggestions');
		this.$input
			.on('focus', (e) => {
				this.renderSearchQueries();
				this.searchSuggestions.show();
			})
			.on('blur', (e) => {
				this.searchSuggestions.hide();
			});
		this.$recentlyEdited
			.on('click', e => {
				if (this.$recentlyEditedList.hasClass('show')) {
					this.$recentlyEditedList.removeClass('show');
				} else {
					this.$recentlyEditedList.addClass('show');
				}
			});
		this.renderRecentlyEdited();
		if (app.allCategories.models.length) {
			this.$categoriesSelect = this.$el.find($('#categoriesFilter'));
			this.categoriesSelect = new BaseSelect({
				el: this.$categoriesSelect,
				model: new BaseModel({}),
				data: app.allCategories.models.map(this.getName)
			});
			this.$categoriesSelect.on('select2:unselect', (e, a) => {
				this.selectedCategories[e.params.data.id] = false;
				this.renderAll();
			});
			this.$categoriesSelect.on('select2:select', (e, a) => {
				this.selectedCategories[e.params.data.id] = true;
				this.renderAll();
			});
		} else {
			$('#categoriesFilter').hide();
		}
	},

	getName(model) {
		if (!model) {
			return '';
		}
		if (model.get('name') instanceof Backbone.Model) {
			return {text: (new MultilingualString(model.get('name').toJSON())).getCurrentValue(), id: model.get('id')};
		} else {
			return {text: (new MultilingualString(model.get('name'))).getCurrentValue(), id: model.get('id')};
		}
	},

	renderRecentlyEdited() {
		app.utils.getRequest(app.urls.recentlyEdited).then((res) => {
			let result = recentlyTasks(res)
			result.forEach((task) => {
				this.$recentlyEditedList.append(`<li style="margin-bottom: 5px; margin-top: 7px;font-size: 12px;"><a href=${task.link}>${task.titleForLink}</a> (${task.title})</li>`)
			})
		});
	},

	renderResultBody: function() {
		this.$el.append(`
      <div class="search-results row">
        <div id="search-types" class="search-box col-lg-3" style="background: #f1f1f1;">
          <span class="search-header">${translate('types')}</span>
					${renderAddTypeButton()}
          <ul class="search-types-list scrollable filter-results"></ul>
        </div>
        <div id="search-views" class="search-box col-lg-3">
          <span class="search-header">${translate('views')}</span>
					${renderAddViewButton()}
					<ul class="search-views-list scrollable filter-results"></ul>
        </div>
        <div id="search-blocks" class="search-box col-lg-3" style="background: #f1f1f1;">
          <span class="search-header">${translate('blocks')}</span>
					${renderAddBlockButton()}
					<ul class="search-blocks-list scrollable filter-results"></ul>
        </div>
				<div id="search-fields" class="search-box col-lg-3">
					<span class="search-header">${translate('fields')}</span>
					${renderAddFieldButton()}
					<ul class="search-fields-list scrollable filter-results"></ul>
				</div>
      </div>`);
		this.$el.find('.scrollable').perfectScrollbar({
			'suppressScrollX': true
		});
	},

	renderTypes: function(types) {
		this.$el.find('.search-types-list').empty();
		if (!types.length) {
			this.$el.find('.search-types-list').append(`
				<li style="text-align:center;">
					<span style="font-weight:600">No types found</span>
				</li>`)
		}
		types.forEach(type => {
			this.renderType(type);
		})
	},

	renderType: function(type) {
		this.$el.find('.search-types-list').append(`
			<li class="type-selection ${this.selectedType == type.get('id') ? 'active' :
			''}" style="padding:10px;" data-type-id="${type.get('id')}">
				${this.getTypeLabel(type)}
				<a href="${app.urls.open(Constants.ID_TYPE_TYPE, type.get('id'))}" class="item-name" style="max-width: calc(100% - 115px);
    display: inline-block;
    vertical-align: middle">${MultilingualString.getCurrentValue(type.name())}</a>
				<span style="display:none;color:#337ab7;" class="pull-right fa fa-filter"/>
			</li>
			`);
		this.$el.find('.search-types-list').perfectScrollbar('update');
	},

	renderViews: function(views) {
		this.$el.find('.search-views-list').empty();
		if (!views.length) {
			this.$el.find('.search-views-list').append(`
				<li style="text-align:center;">
					<span style="font-weight:600">No views found</span>
				</li>`)
		}
		views.forEach(view => {
			this.renderView(view);
		});
	},

	renderView: function(view) {
		let type = app.types.get(view.get('ownerId'));
		this.$el.find('.search-views-list').append(`
			<li style="padding:10px;margin-left:0px;" class="elem-li item-name">
				${this.getViewIcon(view)}
				<a style="margin-left: 10px" title="${translate('view')}" href="${app.urls.open(Constants.ID_TYPE_VIEW, type.get('id') + '/' + view.get('id') + '/' + view.get('viewKind').toLowerCase())}">${MultilingualString.getCurrentValue(view.name())}</a>
				<span class="material-icons notranslate" style="font-size: 16px;
    vertical-align: middle;">keyboard_arrow_right</span><a style="font-size: 10px;" title="${translate('type')}" href="${app.urls.open(Constants.ID_TYPE_TYPE, view.get('ownerId'))}" >${MultilingualString.getCurrentValue(type.name())}</a>
			</li>
			`);
		this.$el.find('.search-views-list').perfectScrollbar('update');
	},

	renderBlocks: function(blocks) {
		this.$el.find('.search-blocks-list').empty();
		if (!blocks.length) {
			this.$el.find('.search-blocks-list').append(`
				<li style="text-align:center;">
					<span style="font-weight:600">No blocks found</span>
				</li>`)
		}
		blocks.forEach(block => {
			this.renderBlock(block);
		});
	},

	renderBlock: function(block) {
		let type = app.types.get(block.get('ownerId'));
		this.$el.find('.search-blocks-list').append(`
			<li style="padding:10px;margin-left:0px;" class="elem-li item-name">
				${this.getBlockIcon(block)}
				<a class="item-name" title="${translate('system.type.block.name')}" href="${app.urls.blockConstruct(block.get('id'))}">${block.name()}
				</a>
				<span class="material-icons notranslate" style="font-size: 16px;
    vertical-align: middle;">keyboard_arrow_right</span><a style="font-size: 10px;" title="${translate('type')}" href="${app.urls.open(Constants.ID_TYPE_TYPE, block.get('ownerId'))}" >${MultilingualString.getCurrentValue(type.name())}</a>
			</li>
			`);
		this.$el.find('.search-blocks-list').perfectScrollbar('update');
	},

	renderFields: function(fields) {
		this.$el.find('.search-fields-list').empty();
		if (!fields.length) {
			this.$el.find('.search-fields-list').append(`
				<li style="text-align:center;">
					<span style="font-weight:600">No fields found</span>
				</li>`)
		}
		fields.forEach(field => {
			this.renderField(new Field(field));
		});
	},

	renderField: function(field) {
		let type = app.types.get(field.get('ownerId'));
		this.$el.find('.search-fields-list').append(`
			<li style="padding:10px;margin-left:0px;" class="elem-li item-name">
				${this.getFieldIcon(field)}
				<span class="item-name">${MultilingualString.getCurrentValue(field.name())}</span>
				<span class="material-icons notranslate" style="font-size: 16px;
		vertical-align: middle;">keyboard_arrow_right</span><a title="${translate('type')}" href="${app.urls.open(Constants.ID_TYPE_TYPE, field.get('ownerId'))}" style="font-size: 10px;" >${MultilingualString.getCurrentValue(type.name())}</a>
			</li>
			`);
		this.$el.find('.search-fields-list').perfectScrollbar('update');
	},

	getTypeLabel: function(type) {
		let kind;
		switch (type.get('typeKind')) {
			case TypeKind.DICTIONARY:
				kind = 'warning';
				break;
			case TypeKind.REGISTER:
				kind = 'info';
				break;
			case TypeKind.DOCUMENT:
				kind = 'success';
				break;
			case TypeKind.REPORT:
				kind = 'purple';
				break;
			case TypeKind.EMBEDDED:
				kind = 'gray';
				break;
			case TypeKind.TRANSIENT:
				kind = 'dark';
				break;
			default:
				kind = 'gray';
				break;
		}
		return `<span style ="display: inline-block;width: 70px;height: 18px;vertical-align:middle;line-height:1.25;" class="label table-label block-label label-${kind} big-type-kind"> ${translate(type.get('typeKind').toLowerCase())} </span>
		<span style="display: none;" class="label table-label block-label label-${kind} small-type-kind">${translate(type.get('typeKind').toLowerCase())[0]}</span>`
	},

	getViewIcon: getViewIcon,

	getBlockIcon: function(block) {
		let kind = 'result';
		switch (block.get('blockType')) {
			case BlockType.SERVER:
				kind = 'server';
				break;
			case BlockType.CLIENT:
				kind = 'client';
				break;
		}

		if (block.isFillFunction()) {
			if (block.get('fillResultTypeId') == app.model && app.model.get('id')) {
				kind = 'result';
			} else {
				kind = 'source';
			}
		}
		return `<a  style="width: 20px; height: 20px; display: inline-block; padding: 0;line-height:2;" title="${kind.toUpperCase()}" class="table-label label label-${kind} block-label">
							<span>${kind[0].toUpperCase()}</span>
						</a>`;
	},


	getFieldIcon: function(field) {
		let kind;
		if (field.kind() == FieldKind.COLLECTION) {
			kind = 'table';
		} else if (field.kind() == FieldKind.DYNAMIC) {
			kind = 'codepen';
		} else {
			let primitive = field.get('fieldType').primitiveEntityType;
			switch (primitive) {
				case PrimitiveEntityType.INTEGER:
				case PrimitiveEntityType.DOUBLE:
				case PrimitiveEntityType.DECIMAL:
					kind = 'calculator';
					break;
				case PrimitiveEntityType.BOOLEAN:
					kind = 'check-square';
					break;
				case PrimitiveEntityType.BINARY:
					kind = 'file';
					break;
				case PrimitiveEntityType.YEAR:
				case PrimitiveEntityType.YEAR_MONTH:
				case PrimitiveEntityType.MONTH_DAY:
				case PrimitiveEntityType.MONTH:
				case PrimitiveEntityType.DAY_OF_WEEK:
				case PrimitiveEntityType.LOCAL_DATE_TIME:
				case PrimitiveEntityType.LOCAL_TIME:
				case PrimitiveEntityType.LOCAL_DATE:
				case PrimitiveEntityType.DURATION:
				case PrimitiveEntityType.PERIOD:
				case PrimitiveEntityType.ZONED_DATE_TIME:
				case PrimitiveEntityType.TIMESTAMP:
					kind = 'calendar';
					break;
				case PrimitiveEntityType.STRING:
				case PrimitiveEntityType.SYSTEM_STRING:
					kind = 'font';
					break;
				default:
					kind = 'list';

			}
		}
		return `<i class="fa fa-${kind}"></i> `;
	},

	getFieldFunctions: function(field) {
		let fieldFunctions = '';
		if (this.blocksForFields[field.get('id')]) {
			let margin = 70;
			this.blocksForFields[field.get('id')].forEach(block => {
				fieldFunctions += `
				<a class="field-event" style="margin-left: calc(100% - ${margin}px);" href="${app.urls.blockConstruct(block.get('id'))}">
					<i class="${icons[block.get('trigger')].iconClasses}">${icons[block.get('trigger')].icon}</i>
				</a>`;
				margin += 25;
			})
		}
		return fieldFunctions;
	},

	delayedReload () {
		this.selectedType = null;
		if (this.$input.val().length === this.prevLength){
			return;
		}
		this.prevLength = this.$input.val().length;
		if (!this.$input.val().length) {
			this.renderAll();
		} else {
			let that = this;
			if (this.future) {
				clearTimeout(this.future);
			}
			this.future = setTimeout(() => {
				that.addSearchQuery(that.$input.val());
				that.renderAll();
			}, 500);
		}
	},

	renderSearchQueries() {
		this.searchSuggestions
			.empty()
			.append(
				this.getRecentSearchQueries().map(query => `<li style="padding: 5px;
							font-size: 14px;cursor: pointer;">${query}</li>`)
			)
			.on('mousedown', (e) => e.preventDefault())
			.on('click', e => {
				let text = $(e.target).text();
				this.$input.val(text);
				this.delayedReload();
				this.$input.blur();
				this.searchSuggestions.hide();
			});
	},

	addSearchQuery(query) {
		let queries = this.getRecentSearchQueries();
		if (queries.length === 5) {
			queries.pop();
		}
		queries.unshift(query);
		this.setRecentSearchQueries(queries);
		this.renderSearchQueries();
	},

	getRecentSearchQueries() {
		return JSON.parse(localStorage.getItem('recentSearchQueries') || '[]');
	},

	setRecentSearchQueries(searches) {
		localStorage.setItem('recentSearchQueries', JSON.stringify(searches));
	},

	renderAll(skipTypes) {
		if (this.canUploadMoreViews) {
			this.canUploadMoreViews = false;
			let onSuccessFunc = (views) => {
				this.renderViews(new Views(views));
				this.canUploadMoreViews = true;
				this.viewOffset = 50;
			}, url = app.urls.getViews;
			this.getMore(url, onSuccessFunc, 0);
		}
		if (this.canUploadMoreBlocks) {
			this.canUploadMoreBlocks = false;
			let onSuccessFunc = (blocks) => {
				this.renderBlocks(new Blocks(blocks));
				this.canUploadMoreBlocks = true;
				this.blockOffset = 50;
			}, url = app.urls.getBlocks;
			this.getMore(url, onSuccessFunc, 0);
		}
		if (this.canUploadMoreTypes && !skipTypes) {
			this.canUploadMoreTypes = false;
			let onSuccessFunc = (types) => {
				this.renderTypes(new Types(types));
				this.canUploadMoreTypes = true;
				this.typeOffset = 50;
			}, url = app.urls.getTypes;
			this.getMore(url, onSuccessFunc, 0);
		}
		if (this.canUploadMoreFields) {
			this.canUploadMoreFields = false;
			let onSuccessFunc = (fields) => {
				this.renderFields(fields);
				this.canUploadMoreFields = true;
				this.fieldOffset = 50;
			}, url = app.urls.getFields;
			this.getMore(url, onSuccessFunc, 0);
		}
	},

	getMore: function(url, onSuccessFunc, offset) {
		utils.ajaxRequest({
			categoriesIds: this.getSelectedCategories(),
			selectedType: this.selectedType || null,
			searchParam: ((this.$input && this.$input.val()) || ''),
			offset: offset,
			limit: 50
		}, url, 'POST', onSuccessFunc, null, true);
	},



	onClickType: function(e) {
		let target = $(e.currentTarget);
		let typeId = target.data('type-id');
		this.$el.find('.type-selection').removeClass('active');
		if (this.selectedType == typeId) {
			target.removeClass('active');
			this.selectedType = false;
			this.renderAll();
			this.updateButtonsForAddition();
			this.$el.find('a[data-kind="XML"], a[data-kind="DOC"], a[data-kind="SPREADSHEET"]').hide();
		} else {
			target.addClass('active');
			this.selectedType = typeId;
			this.renderAll(true);
			this.updateButtonsForAddition();
			this.$el.find('a[data-kind="XML"], a[data-kind="DOC"], a[data-kind="SPREADSHEET"]').show();
		}
	},

	updateButtonsForAddition: function() {
		if (this.selectedType) {
			this.$el.find('.additionButton').show();
		} else {
			this.$el.find('.additionButton').hide();
		}
	},

	addType: function (event) {
		addEntityType(event, {
			modalInstance: app.createModal2,
			afterCreateOnSuccess: (type) => {
				utils.redirectTo(app.urls.open(Constants.ID_TYPE_TYPE, type.id));
			}
		});
	},


	addView: function (event) {
		if (this.selectedType) {
			addEntityView(event, {
				typeId: this.selectedType,
				afterCreateOnSuccess: (viewId) => {
					utils.redirectTo(app.urls.open(Constants.ID_TYPE_VIEW, viewId));
				}
			});
		} else {
			this.createModal = new CreateModal();
			let viewKind = $(event.currentTarget).data('kind');
			let model = new BaseModel();
			model.set('viewKind', viewKind);
			this.createModal.show({
				url: app.urls.createWebPage,
				headerResource: `add.view`,
				model: model,
				afterCreateOnSuccess: (view) => {
					utils.redirectTo(app.urls.editView(view.id, view.owner.id));
				},
				createView: CreateView
			});
		}
	},

	addBlock: function (event) {
		addEntityBlock(event, {
			typeId: this.selectedType,
			afterCreateOnSuccess: (block) => {
				utils.redirectTo(app.urls.open(Constants.ID_TYPE_BLOCK, block.id));
			}
		});
	},

	addField: function (event) {
		addEntityField(event, {
			typeId: this.selectedType,
			afterCreateOnSuccess: (field) => {
				utils.redirectTo(app.urls.open(Constants.ID_TYPE_TYPE, field[0].ownerId));
			}
		})
	},


	addSharedField: function(event) {
		$(event.currentTarget).parents('.dropdown').removeClass('open')
		let that = this
		app.createModal.show({
			url: app.urls.fieldCreate,
			addShared: true,
			headerResource: `add.field`,
			afterCreateOnSuccess: (data) => {
				app.fields.add(data)
				app.fieldsTreeHelper.add(data)
			},
			createView: CreateField
		});
		event.stopPropagation()
	},

	getSelectedCategories: function() {
		let selectedCategories = [];
		_.keys(this.selectedCategories).forEach((categoryId) => {
			if (this.selectedCategories[categoryId]) {
				selectedCategories.push(categoryId);
			}
		});
		return selectedCategories;
	}

});

export function getViewIcon(view) {
	let kind, title
	switch (view.get('viewKind')) {
		case ViewKind.FORM:
			kind = 'newspaper-o';
			title = 'web.page'
			break;
		case ViewKind.ROW:
			kind = 'table';
			title = 'table'
			break;
		case ViewKind.STRING:
			kind = 'font';
			title = 'text'
			break;
		case ViewKind.DOC:
			kind = 'file-word-o';
			title = 'doc.view'
			break;
		case ViewKind.SPREADSHEET:
			kind = 'file-excel-o'
			title = 'spreadsheet.view'
			break;
		case ViewKind.XML:
			kind = 'file-code-o';
			title = 'xml.view'
			break;
		case ViewKind.WIDGET:
			kind = 'columns';
			title = 'card.grid'
			break;
		case ViewKind.LIST:
			kind = 'list'
			title = 'list.view'
			break
		default:
			kind = '';
			title = ''
			break;
	}
	return `<i class="fa fa-${kind}" title="${translate(title)}"></i>`
}
export default NavigationView;
