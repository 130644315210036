var PropertyKey = {
	TITLE: 'TITLE',
	SIZE: 'SIZE',
	SHOW_HEADER: 'SHOW_HEADER',
	COLLAPSE: 'COLLAPSE',
	TRIGGER: 'TRIGGER',
	VIEW: 'VIEW',
	NAME: 'NAME',
	COMMENT: 'COMMENT',
	VALIGN: 'VALIGN',
	MULTILINE: 'MULTILINE',
	TYPE: 'TYPE',
	TEMPLATE: 'TEMPLATE',
	CAN_ADD: 'CAN_ADD',
	CAN_DELETE: 'CAN_DELETE',
	MIN: 'MIN',
	MAX: 'MAX',
	STEP: 'STEP',
	PATTERN: 'PATTERN',
	CSS: 'CSS',
	STYLE: 'STYLE',
	SOLID: 'SOLID',
	LABEL: 'LABEL',
	LINK: 'LINK',
	FIELD_LABEL: 'FIELD_LABEL',
	SHARED_STYLES: 'SHARED_STYLES',
	REQUIRED: 'REQUIRED',
	DISABLED: 'DISABLED',
	HIDDEN: 'HIDDEN',
	IDENTIFIER: 'IDENTIFIER',
	BEFORE_CUSTOM_SERVER_EVENT: 'BEFORE_CUSTOM_SERVER_EVENT',
	CUSTOM_SERVER_EVENT: 'CUSTOM_SERVER_EVENT',
	AFTER_CUSTOM_SERVER_EVENT: 'AFTER_CUSTOM_SERVER_EVENT',
	PREVIEW: 'PREVIEW',
	UPLOAD: 'UPLOAD',
	ZOOM: 'ZOOM',
	DATA_SOURCE: 'DATA_SOURCE',
	READ_ONLY_VIEW: 'READ_ONLY_VIEW',
	ICON: 'ICON',
	ALIGN: 'ALIGN',
	HEADER: 'HEADER',
	READ_ONLY: 'READ_ONLY',
	IS_LINK: 'IS_LINK',
	CLASSES: 'CLASSES',
	SHOW_TOOLBAR: 'SHOW_TOOLBAR',
	FORM_OPTIONS: 'FORM_OPTIONS',
	BACK_LINK: 'BACK_LINK',
	DROPDOWN_STYLE: 'DROPDOWN_STYLE',
	BUTTON_STYLE: 'BUTTON_STYLE',
	SHOW_ICON: 'SHOW_ICON',
	ADD_ACTION: 'ADD_ACTION',
	DROPDOWN_ALIGN: 'DROPDOWN_ALIGN',
	HAS_CREATE_NEW: 'HAS_CREATE_NEW',
	CAN_REORDER: 'CAN_REORDER',
	HAS_POST_COLUMN: 'HAS_POST_COLUMN',
	PLACEHOLDER: 'PLACEHOLDER',
	HAS_VIEW_LINK: 'HAS_VIEW_LINK',
	MAX_ROW_COUNT: 'MAX_ROW_COUNT',
	HAS_FOLDER_TREE: 'HAS_FOLDER_TREE',
	MIN_DATE: 'MIN_DATE',
	MAX_DATE: 'MAX_DATE',
	WIDTH: 'WIDTH',
	HIDE_TITLE_XS: 'HIDE_TITLE_XS',
	ICON_COLOR: 'ICON_COLOR',
	LINK_FORM_VIEW: 'LINK_FORM_VIEW',
	COMMENT_PLACEMENT: 'COMMENT_PLACEMENT',
	HIDE_CHECKBOX: 'HIDE_CHECKBOX',
	HIDE_FILLFUNCTIONS: 'HIDE_FILLFUNCTIONS',
	INITIAL_STATE: 'INITIAL_STATE',
	CUSTOM_RESOURCES: 'CUSTOM_RESOURCES',
	CLIENT_PAGE_TITLE: 'CLIENT_PAGE_TITLE',
	CLIENT_PAGE_HEAD_ENTRIES: 'CLIENT_PAGE_HEAD_ENTRIES',
	STATIC_RESPONSE: 'STATIC_RESPONSE',
	USE_STATIC_RESPONSE_FOR_CRAWLER: 'USE_STATIC_RESPONSE_FOR_CRAWLER',
	USE_STATIC_RESPONSE_FOR_BROWSER: 'USE_STATIC_RESPONSE_FOR_BROWSER',
	DONT_SHOW_BY_DEFAULT: 'DONT_SHOW_BY_DEFAULT',
	FORMAT: 'FORMAT',
	DEFAULT_SORTING: 'DEFAULT_SORTING',
	LINK_ACTION: 'LINK_ACTION',
	LOAD_IN_EDIT_MODE: 'LOAD_IN_EDIT_MODE',
	FIELD_TYPE: 'FIELD_TYPE',
	FIELD_NAME: 'FIELD_NAME',
	OPEN_LINK_IN_SAME_WINDOW: 'OPEN_LINK_IN_SAME_WINDOW',
	MINUTES_STEP: 'MINUTES_STEP',
	TIME_FORMAT: 'TIME_FORMAT',
	HTML_STRING_POPOVER: 'HTML_STRING_POPOVER',
	FORMATTER: 'FORMATTER',
	CLIENT_VIEW: 'CLIENT_VIEW',
	SHOW_DATE_TIME_IN_POPOVER: 'SHOW_DATE_TIME_IN_POPOVER',
	DURATION_FORMATTER: 'DURATION_FORMATTER',
	ITEM_KIND: 'ITEM_KIND',
	SELECT_WIDTH: 'SELECT_WIDTH',
	SEED_INSTANCES: 'SEED_INSTANCES',
	TRACK_CHANGES_ON_PAGE: 'TRACK_CHANGES_ON_PAGE',
	CSS_THEME: 'CSS_THEME',
	CREATE_NEW_VIEW: 'CREATE_NEW_VIEW',
	USE_TREE_ONLY: 'USE_TREE_ONLY',
	IS_TREE_SELECTABLE: 'IS_TREE_SELECTABLE',
	OPEN: 'OPEN',
	SHOW_ALL_CHILDREN: 'SHOW_ALL_CHILDREN',
	ROW_HEIGHT: 'ROW_HEIGHT',
	SELECTION_TYPE: 'SELECTION_TYPE',
	WIDGET_HEIGHT: 'WIDGET_HEIGHT',
	COLUMNS_QUANTITY: 'COLUMNS_QUANTITY',
	PAGE_SIZE: 'PAGE_SIZE',
	WIDTH_CRITERIA: 'WIDTH_CRITERIA',
	TREE_VIEW_KIND: 'TREE_VIEW_KIND',
	ADD_STRING_VIEW_TO_TITLE: 'ADD_STRING_VIEW_TO_TITLE',
	MODAL_WIDTH: 'MODAL_WIDTH',
	MODAL_HEIGHT: 'MODAL_HEIGHT',
	MODAL_FLOAT: 'MODAL_FLOAT'
};

export default PropertyKey;
