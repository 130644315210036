import {buildDefaultPopover} from "../common/components/utils";
import {translate} from "../common/service/stringResourceService";
import Instant from "../time/models/instant";
import {timeSynchronizer} from "../time/utils";
import Duration from "../time/models/duration";
import Formatter from "../common/components/formatter";
import FormatterInfo from "../common/service/formatterInfo";
import DeploymentForbiddanceReason from "../common/enums/deploymentForbiddanceReason";
import ApplicationType from "../common/enums/applicationType";

export function buildPopover(elem) {
    let status = ''
    if ((elem.classList[0] === 'status-starting') || (elem.classList[0] === 'status-ok')) {
        status = 'respond';
    } else {
        status = 'not.respond';
    }
    buildDefaultPopover($(elem), {
        toggle: 'popover',
        content: translate('app.status.popover.' + status),
        html: true,
        delay: 300,
        container: 'body',
        placement: 'right'
    });
}

export function renderDeployList(options) {
    options = options || {};
    let insertInfoOfDeploy = '';
    options.applications.forEach(elem => {
        let appStatus = '', boxType = '';
        if (elem.deploymentForbiddanceReason !== 'NONE') {
            appStatus = 'status-other';
            boxType = 'box-info';
        } else if (!elem.isAppResponding) {
            appStatus = 'status-fail';
            boxType = 'box-danger';
        } else if (elem.deploymentState !== 'START_CONFIRMED') {
            appStatus = 'status-starting'
            boxType = 'box-warning';
        } else {
            appStatus = 'status-ok';
            boxType = 'box-success';
        }
        insertInfoOfDeploy += `
            <div class="dropdown-deploy" style="display: flex">
                <div style="margin-top: 5px; margin-right: 5px;">
                    <div class="selection-checkbox"></div>
                </div>
                <div class="box ${boxType} box-solid collapsed-box">
                    <div class="box-header with-border" data-widget="collapse">
                        <div class="fa fa-plus pull-right"></div>
                        <div class="app-status">
                            <div class="${appStatus}"></div>
                        </div>
                        <h6 class="box-title">
                            <a class="open-item-link${elem.isAppResponding ? ' let-open' : ''}" href="${ elem.isAppResponding ? window.location.protocol + '//' + elem.domains[0] : '#'}">
                                <span>${elem.title}</span>
                            </a>
                        </h6>
                    </div>
                    <div class="box-body">
                        <span>
                            ${getBodyInfo(elem)}
                        </span>
                    </div>
                </div>
            </div>`
    });
    options.$el.html(insertInfoOfDeploy);
    document.querySelectorAll('a.open-item-link').forEach(elem => {
        elem.addEventListener('click', e => {
            if (elem.attributes.href.nodeValue !== '#') e.stopPropagation()
        })
    });
    Array.from(document.getElementsByClassName('app-status')).forEach((el) => { buildPopover(el.firstElementChild)});
}

const duration = timestamp => {
    let time = Instant.fromJSON(timestamp)
    if (time) {
        let eventAge = timeSynchronizer.getServerEventAge(time)
        if (eventAge.toMillis() < 0) eventAge = Duration.ofNanos(0)
        time = Formatter.formatDuration(eventAge)
    }
    return time
}

export function getBodyInfo(elem) {
    let info = '';
    info += `${translate('application.state')}: ${elem.isAppResponding ? translate('responding') : translate('not.responding')}<br/>`;
    if (elem.deploymentState) {
        info += `
            ${translate('deployment.state')}: ${elem.deploymentState ? translate('container.state.' + elem.deploymentState.toLowerCase()) : '?'}<br/>
            ${translate('deployed.at')}: ${duration(elem.deployedAt)}<br/>
            ${translate('deployed.by')}: ${_.escape(elem.deployedBy)}<br/>
            ${translate('application.version')}: ${FormatterInfo.formatVersion(elem.applicationVersion, elem.buildNumber)}<br/>
            ${translate('builder.version')}: ${_.escape(elem.builderVersion)}`;
    } else {
        let reason;
        if (elem.deploymentForbiddanceReason === DeploymentForbiddanceReason.APPLICATION_IS_NOT_ACTIVE) {
            reason = 'deploy.forbiddance.reason.application.is.not.active';
        } else if (elem.deploymentForbiddanceReason === DeploymentForbiddanceReason.CONTAINER_IS_NOT_ACTIVE) {
            reason = 'deploy.forbiddance.reason.container.is.not.active';
        } else if (elem.deploymentForbiddanceReason === DeploymentForbiddanceReason.CONTAINER_IS_NOT_ASSIGNED) {
            reason = 'deploy.forbiddance.reason.container.is.not.assigned';
        } else if (elem.deploymentForbiddanceReason === DeploymentForbiddanceReason.USER_IS_NOT_ALLOWED) {
            reason = 'deploy.forbiddance.reason.user.is.not.allowed';
        } else if (elem.deploymentForbiddanceReason === DeploymentForbiddanceReason.USER_IS_NOT_CONTRIBUTOR) {
            reason = 'deploy.forbiddance.reason.user.is.not.contributor';
        } else {
            if (elem.applicationType === ApplicationType.REMOTE || elem.applicationType === ApplicationType.REMOTE_USE_CODEJIG_INFRASTRUCTURE) {
                reason = 'remote.app.description';
            } else if (elem.applicationType === ApplicationType.REGULAR) {
                reason = 'application.was.stopped';
            }
        }
        if (reason) {
            info += `${translate('possible.reason')}: ${translate(reason)}`;
        }
    }
    return info;
}