<template>

    <Table :table="{loadMore: loadMore, rowClasses: rowClasses}" :rows="rows" :columns="columns" v-on:rowClicked="onClickCell" class="highViewport"/>

</template>

<script>
import { Table } from '@codejig/ui-components'
import { getRowViewItems, rowItemsToTableColumns } from '../../../utils/rowViewUtils'
import { openLink } from '../../../common/components/utils'

export default {
    props: ['journalTypeId', 'referrerDocumentId'],
    data () {
        return {
            columns: [],
            rows: [],
        }
    },
    methods: {
      loadMore(size, order) {
        return this.getMoreDataForTable({
          size: size.size,
          loadDeleted: false,
          filters: [],
          tags: null,
          lastValue:this.rows && this.rows[this.rows.length-1]||null,
          metaObject: null,
          order: this.formatOrder(order),
        })
      },
      formatOrder(order){
        let formattedOrder={}
        if (order){
          if (order.order=="asc"){
            formattedOrder.ascendingOrder=true;
          }else if (order.order=="desc") {
            formattedOrder.ascendingOrder=false;
          }
          formattedOrder.field={id:this.getIdByFieldName(order.field)}
          return formattedOrder
        }
      },
      getIdByFieldName(fieldName){
        return (_.find(app.fields.models,(r)=>{
          return r.fieldName()==fieldName
        })).id
      },
      getMoreDataForTable(moreStruct) {
        var that = this
          return new Promise(function (resolve, reject) {
            var onSuccess = function (data) {
              resolve({
                items: data.one,
                totalSize: data.two
              })
            }
              app.utils.ajaxRequest(moreStruct, app.urls.getJournalRecordsPage(that.referrerDocumentId, that.journalTypeId), 'post', onSuccess, reject, true)
          }).catch(function (response) {
            app.utils.error(response);
            return Promise.reject(response);
          });
        },
      onClickCell(row, e) {
        let target = $(e.target)
        if (!((target.parents('a')[0]) || e.target.nodeName.toLowerCase()=='a')) {
          let cell
          if (target.hasClass('table-cell')) {
            cell = target
          } else{
            cell = target.parents('div.table-cell')
          }
          if (target.is('.ref')) {
            openLink(target, e);
          }
        }
      },
      rowClasses(item, index) {
  			return {
  				clickable: 'clickable'
  			}
  		}
    },
    async mounted () {
        this.columns = rowItemsToTableColumns(await getRowViewItems(null, this.journalTypeId))
    },
    components: {
        Table
    }
}
</script>
