/* @flow */

import entityManager from '../../common/components/entityManager';
import Constants from '../../common/models/constants';

export { onPageLoad, getPageLoadFunction, onChange, onAdd, onRemove, onFilter, getFilters, addCustomEventFunction,
	getCustomEventFunction, getFormLoadFunction, addFormLoadFunction, addRowLoadFunction, getRowLoadFunction,
	addFunction, getFunction, onTypeFilter, getTypeFilters, addRowClick, getRowClickFunction, initializeSubscriptions,
	onSuggest, getSuggestFunction, onOpenClicked, getOnOpenClicked, onCreateClicked, getOnCreateClicked }

const onTypeFilterFuncs = {};

const onFilterFuncs = {};

const customEventFuncs = {};

const formLoadFuncs = {};

const rowLoadFuncs = {};

const functions = {};

const pageLoads = {};

const rowClickedFuncs = {}

const subscriptions = {}

type Consumer = (model: Object, _model?: Object) => Promise<*>;

function onPageLoad(typeId: string, func: Consumer): void {
	pageLoads[typeId] = func;
}

function getPageLoadFunction(typeId: string): ?Consumer {
	return pageLoads[typeId];
}

function subscribe(context: Object, event: string, func: Consumer): void {
	context.model.on(event, (_model) => {
		app.tasksQueue.add(async () => {
			await func.call(context, context.model, _model);
			entityManager.fetchStringViews();
		});
	});
}

function initializeSubscriptions(context: Object) {
	let events = subscriptions[context.type.id];
	if (events) {
		events.forEach(e => subscribe(context, e[0], e[1]));
	}
}

function addSubscribtion(type:string, key:string, func: Consumer): void {
	subscriptions[type] = subscriptions[type] || []
	subscriptions[type].push([key, func]);
}

function onChange(type:string, key: string, func: Consumer): void {
	addSubscribtion(type, 'manualChange:' + key, func);
}

function onAdd(type:string, field: string, func: Consumer): void {
	addSubscribtion(type, 'add:' + field, func);
}

function onRemove(type:string, field: string, func: Consumer): void {
	addSubscribtion(type, 'remove:' + field, func);
}

function addRowClick(field: string, func: Consumer): void {
	rowClickedFuncs[field] = func
}

function onFilter(key: string, func: Consumer): void {
	onFilterFuncs[key] = func;
}

function onTypeFilter(key: string, func: Consumer): void {
	onTypeFilterFuncs[key] = func;
}

function getFilters(key: string): Consumer {
	return onFilterFuncs[key] || async function() {
			return [];
		};
}

function getTypeFilters(key: string): Consumer {
	return onTypeFilterFuncs[key] || async function() {
			return [];
		};
}

function addCustomEventFunction(blockId: string, func: Consumer): void {
	customEventFuncs[blockId] = func;
}

function getCustomEventFunction(blockId: string): Consumer {
	return customEventFuncs[blockId];
}

function addFormLoadFunction(viewId: string, func: Consumer): void {
	formLoadFuncs[viewId] = func;
}

function getFormLoadFunction(viewId: string): Consumer {
	return formLoadFuncs[viewId] || async function () { return null; };
}

function addRowLoadFunction(viewId: string, func: Consumer): void {
	rowLoadFuncs[viewId] = func;
}

function getRowClickFunction(fieldId: string) {
	return rowClickedFuncs[fieldId]
}

function getRowLoadFunction(viewId: string): Consumer {
	return rowLoadFuncs[viewId];
}

function addFunction(blockId: string, func: Consumer): void {
	functions[blockId] = func;
}

function getFunction(blockId: string): Consumer {
	return functions[blockId];
}

const suggestFuncs = {};

function onSuggest(type: string, key: string, func: Consumer): void {
	suggestFuncs[type] = suggestFuncs[type] || {};
	suggestFuncs[type][key] = func;
}

function getSuggestFunction(type: string, key: string) {
	return suggestFuncs[type] && suggestFuncs[type][key] || (async () => []);
}

const onOpenClickedFuncs = {}

function onOpenClicked(field: string, func: Consumer): void {
	onOpenClickedFuncs[field] = func
}

function getOnOpenClicked(fieldId: string) {
	return onOpenClickedFuncs[fieldId]
}

const onCreateClickedFuncs = {}

function onCreateClicked(field: string, func: Consumer): void {
	onCreateClickedFuncs[field] = func
}

function getOnCreateClicked(fieldId: string) {
	return onCreateClickedFuncs[fieldId]
}

// default observers
//WARNING :: context dependent yet

const ownerFieldFilter = (model) => {
	return Promise.resolve([{field: {id: Constants.ID_FIELD_OWNER}, kind: 'EQUAL', value: JSON.stringify({id: app.types.get(app.typeId).reportDataType().id})}]);
};

onFilter(`${app.typeId},rowGroups.field`, ownerFieldFilter);
onFilter(`${app.typeId},columnGroups.field`, ownerFieldFilter);
onFilter(`${app.typeId},values.field`, ownerFieldFilter);
