/* @flow */

import Constants from './constants';
import Month from '../../time/models/month';
import DayOfWeek from '../../time/models/dayOfWeek';
import ChronoField from '../../time/enums/chronoField';
import TextStyle from '../../time/enums/textStyle';
import utils from '../components/utils';
import entityManager from '../components/entityManager';
import EmbeddedEntity from './embeddedEntity';
import Formatter from '../components/formatter';
import ModelFactory from '../../common/models/modelFactory';

export default class ReportGroup extends EmbeddedEntity {

	defaults() {
		return _.extend(super.defaults(), {
			includeTotal: true
		});
	}

	get entityTypeId(): number {
		return Constants.ID_TYPE_REPORT_GROUP;
	}

	field() {
		return app.fields.get(this.get('field').id);
	}

	numberUnit() {
		return this.get('numberUnit');
	}

	includeTotal() {
		return this.get('includeTotal');
	}

	includeFolders() {
		return this.get('includeFolders');
	}

	temporalField() {
		if (this.get('temporalField') == null) {
			return null;
		}
		return ChronoField.valueOf(this.get('temporalField'));
	}

	stringViewId() {
		const stringView = this.get('stringView');
		return stringView && stringView.id;
	}

	valueHasStringView() {
		return this.field().isDynamic() || this.field().type().hasMetaObject();
	}

	groupValueToComparable(groupKey): any {
		if (groupKey == null) {
			return '';
		}
		if (this.valueHasStringView()) {
			// string views are expected to be prefetched by reportTable
			return entityManager.getStringView(this.stringViewId(), groupKey.id) || "";
		}
		const valueType = this.field().type();
		if (valueType && valueType.isPrimitive() && this.temporalField() == null) {
			const model = ModelFactory.getPrimitiveType(valueType.primitive());
			if (model) {
				return model.fromJSON(groupKey);
			}
		}
		return groupKey;
	}

	groupValueToString(groupKey): string {
		const type = this.field().type();

		if (groupKey == null) {
			return '-';
		}

		const temporalField = this.temporalField();
		if (temporalField != null) {
			if (temporalField == ChronoField.MONTH_OF_YEAR) {
				const month = Month.of(parseInt(groupKey, 10));
				return month.getDisplayName(TextStyle.FULL_STANDALONE);
			}
			if (temporalField == ChronoField.DAY_OF_WEEK) {
				const dayOfWeek = DayOfWeek.of(parseInt(groupKey, 10));
				return dayOfWeek.getDisplayName(TextStyle.FULL_STANDALONE);
			}
			return groupKey;
		}

		if (this.numberUnit() != null) {
			const number = parseInt(groupKey, 10);
			const unit = parseInt(this.numberUnit(), 10);
			return `${groupKey} .. ${number + unit}`;
		}

		if (this.valueHasStringView()) {
			// string views are expected to be prefetched by reportTable
			return entityManager.getStringView(this.stringViewId(), groupKey.id);
		}

		if (type.isPrimitive()) {
			return Formatter.format(groupKey, {
				type: type
			});
		}
		return groupKey;
	}
}
