import utils from '../../common/components/utils.js';
import {translate} from '../../common/service/stringResourceService'
import MultilingualString from '../../common/models/multilingualString.js';
import FieldInput from './fieldInput.js';
import CheckboxInput from './checkboxInput.js'
import multilingual from './multilingual.js';
import { getScript, getStyle } from '../../common/components/utils.js'
const summernoteOptions = {
	height: 300,
	followingToolbar: false,
	toolbar: [
			['style', ['style']],
			['font', ['bold', 'underline', 'clear']],
			['fontname', ['fontname']],
			['color', ['color']],
			['para', ['ul', 'ol', 'paragraph']],
			['table', ['table']],
			['insert', ['link', 'picture', 'video']],
			['misc', ['undo', 'redo']],
			['view', ['fullscreen', 'codeview', 'help']]
	]
};

let waitForSummernoteLoad
class MultilingualHtmlInputField extends Backbone.View {

	constructor (options) {
		super({
			el: options.el,
			events: {
				keyup: '_onKeyUp',
				change: '_onChange'
			}
		})
		this.model = options.model
		this.modelAttr = options.modelAttr
		this.context = options.context;

		this.msModel = options.model.get(options.modelAttr);
		if (!(this.msModel instanceof MultilingualString)) {
			this.msModel = new MultilingualString(this.msModel.toJSON());
		}

		if (this.$el.attr('data-is-string-multilingual') === 'short'
				|| this.$el.attr('data-is-string-multilingual') === 'long') {
			this.msType = 'long';
		}

		this.disabled = this.$el.attr('disabled') == 'disabled'
		this.popoverPlacement = options.popoverPlacement || 'auto';
		this.popoverContainer = options.popoverContainer;

		this.startCollapsed = options.startCollapsed;
    if (!$.fn.summernote && !waitForSummernoteLoad) {
      waitForSummernoteLoad = Promise.all([
        getScript(app.urls.summernote),
        getStyle(app.urls.summernoteCss)
      ])
    }
		this._initializeOptions();
		this._buildMSDOM();
		this._bindingOn();
		this._initializeWidgetEvents();

		this.listenTo(this.msModel, 'change', () => this._triggerEvents());
		this.listenTo(this.model, 'change:' + this.modelAttr, () => {
			this.stopListening(this.msModel)
			this.msModel = this.model.get(this.modelAttr)
			this.listenTo(this.msModel, 'change', () => {
				this.setValue()
				this._updatePlaceholder()
			});
			this._bindingOff();
			this._bindingOn();
			this.render();
		});
	}

	render () {
		_.each(this.translationInputs, (input) => input.render());
		this.setValue();
		this._updatePlaceholder();
		this.isRendered = true;
	}

	setValue () {
		let html = this.msModel.toHTML(true, true);
		if (this.msModel.isBlank()) {
			html = '-';
		}
		this.$textEl.html(html);
		this.updateSummernote();
	}

	updateSummernote () {
		if (this.msModel.get('value')) {
			this.widget.find('.note-editable').html(this.msModel.get('value'))
		} else {
			let langAreas = this.widget.find('.tab-pane').find('[data-locale]')
			let that = this
			langAreas.each((i) => {
				let $elem = $(langAreas[i])
				$elem.parent().find('.note-editable').html(that.msModel.getTranslations().get($elem.attr('data-locale')))
			})
		}
		this.widget.find('.rtf-container textarea.form-control').trigger('update.chars.quantity')
	}

	getValue() {
		return this.msModel.toJSON();
	}

	changed () {
		this.model.trigger('change',this.model,{});
	}

	enable () {
		if (!this.isRendered) {
			return;
		}
		this.$el.removeAttr('disabled');
		this.$el.parent().removeClass('hidden');
		if (this.msType) {
			this.$el.hide();
			var that = this;
			this.widget.find('.box-body textarea[data-locale]')
					.each((i, el) => { that._initRtfEditor($(el)); });
			this.widget.find(`.lang-tab a[data-locale=${this._getCurrentLanguage()}]`)
					.first().tab('show');
		} else {
			this._initRtfEditor(this.widget.find('.rtf-container textarea').first());
		}
		this.$textEl.addClass('hidden');
		if (this.startCollapsed) {
				this.popoverButton.removeAttr('disabled');
				this.popoverButton.removeClass('hidden');
		}
		this.isEnabled = true;
	}

	disable () {
		this.$el.attr('disabled', 'disabled');
		this.$el.parent().addClass('hidden');
		if (this.msType) {
			var that = this;
			this.widget.find('.box-body textarea[data-locale]')
					.each((i, el) => { that._destroyRtfEditor($(el)); });
		} else {
			this._destroyRtfEditor(this.widget.find('.rtf-container textarea').first());
		}
		this.$textEl.removeClass('hidden');
		if (this.startCollapsed) {
				this.popoverButton.attr('disabled', 'disabled');
				this.popoverButton.addClass('hidden');
		}
		this.isEnabled = false;
	}

	destroy () {
		this.undelegateEvents();
		if (this.msType) {
			var that = this;
			this.widget.find('.box-body textarea[data-locale]')
					.each((i, el) => { that._destroyRtfEditor($(el)); });
		} else {
			this._destroyRtfEditor(this.widget.find('.rtf-container textarea').first());
		}
		this.widget.remove();
	}

	remove() {
		this.destroy();
		super.remove();
	}

	reset () {
		if (this.startCollapsed) {
			this._hideAllPopovers();
		}
	}

	isDisabledInFormBuilder () {
		return this.disabled
	}

	/* Events */

	_onChange(e) {
		this.changed();
	}

	_onKeyUp(e) {
		this._updatePlaceholder();
	}

	/* Initialize */

	_bindingOn() {
		var that = this;

		if (!this.msType) {
			this.$input = new FieldInput({
				el: this.el,
				model: this.model.get(this.modelAttr),
				modelAttr: 'value',
				isHTML: true
			})
		}

		this.translationInputs = {};
		this.widget
			.find('.rtf-container textarea')
			.each(function (i, el) {
				if (that.msType) {
					var modelAttr = $(el).attr('data-locale');
					this.m = that.model.get(that.modelAttr).get('translations')
					that.translationInputs[modelAttr] = new FieldInput({
						el: el,
						model: this.m,
						modelAttr: modelAttr,
						isHTML: true
					});
					that.listenTo(this.m, 'change', () => {
						that.setValue()
						that._updatePlaceholder()
					});
				} else {
					that.translationInputs['value'] = new FieldInput({
						el: el,
						model: that.model.get(that.modelAttr),
						modelAttr: 'value',
						isHTML: true
					});
				}
			});
	}

	_bindingOff () {
		_.each(this.translationInputs, v => {
			v.undelegateEvents();
		})
	}

	_initializeOptions () {
		this.$textEl = this.$el.closest('.form-group').find('.read-only-text');
		this.placeholder = this.$el.attr('placeholder')
	}

	_triggerEvents () {
		this._updatePlaceholder();
		this.setValue()
		this.model.trigger('manualChange:' +  this.modelAttr, this.model);
	}

	_buildMSDOM () {

		var that = this;

		this.$el.hide();

		this.widget = $(`<div class="html-field-widget ${this.msModel.cid}"></div>`);
		this.widget.insertAfter(this.$el);
		this.widget.append(this.$el);

		if (this.startCollapsed) {
			this.popoverButton =
				$(`<button class="btn btn-default" data-target=".${this.msModel.cid}"
							tabindex="-1" data-toggle="popover" data-placement="${this.popoverPlacement}" data-content="Stub text" aria-expanded="true">
						<span id="button-text">${app.getResource('open.editor')}</span>
					</button>`);
			if (this.popoverContainer) {
				this.popoverButton.attr('data-container', this.popoverContainer);
			}
			this.widget.append(this.popoverButton);
			this.widget.append(`<div class='rtf-container rtf-container-collapsed' style='display:none;'></div>`);
			this.widget.find('.rtf-container').append(
					$(`<div class="box box-widget box-nomargin">
							<div class="btn-group multilingual-btn-group">
								<button type="button" class="ms-collapse soft-button btn btn-link" tabindex="-1">
									<span class="fa fa-minus"></span>
								</button>
							</div>
							<div class="box-body"></div>
							<div class="btn-group pull-right">
								<button type="button" class="ms-collapse btn btn-primary" tabindex="-1">
									<span>${app.getResource('apply')}</span>
								</button>
							</div>
						</div>`));
		} else {
			this.widget.append(`<div class='rtf-container'></div>`);
			this.widget.find('.rtf-container').append(
					$(`<div class="box box-widget box-nomargin">
							<div class="box-body"></div>
						</div>`));
		}

		if (this.msType) {
			this.widget.find('.box-body').append($(multilingual('long')));

			this.widget.find('a[data-locale]').each(function (i, a) {
				$(a).attr('href', $(a).attr('href') + that.msModel.cid);
			});
			this.widget.find('.tab-pane').each(function (i, tab) {
				$(tab).attr('id', $(tab).attr('id') + that.msModel.cid);
			});
		} else {
			this.widget.find('.box-body').append($(`<textarea></textarea>`));
		}
	}

	/* Popover window */

	_initializeWidgetEvents () {
		var that = this;

		if (this.startCollapsed) {
			this.popoverButton.popover({
				template: this.widget.find('.rtf-container'),
				trigger: 'manual'
			});
			this.popoverButton.click(() => this.popoverButton.popover('toggle'));
			this.popoverButton.on('show.bs.popover', function () {
				that._hideAllPopovers();
				if (!that.isEnabled) {
					that.enable();
				}
			});
			this.popoverButton.on('shown.bs.popover', function () {
				that.popoverButton.addClass('popover-shown');
				that.popoverButton.find('#button-text').html(app.getResource('close.editor'));
			});
			this.popoverButton.on('hidden.bs.popover', function () {
				that.popoverButton.removeClass('popover-shown');
				that.popoverButton.find('#button-text').html(app.getResource('open.editor'));
			});
			this.widget.find('button.ms-collapse').on('click', function(e) {
				that._hideRtfEditorPopups(that.$el);
				that.popoverButton.popover('hide');
			});
			this.widget.find('a[data-toggle="tab"]').on('hide.bs.tab', function (e) {
				that._hideRtfEditorPopups(that.$el);
			});
		}
	}

	_hideAllPopovers () {
		$('button.popover-shown').trigger('click');
	}

	/* Misc */

	_getCurrentLanguage () {
		return app.currentLanguage;
	}

	_getPlaceholder () {
		const that = this;
		if (this.msType) {
			let result = '';
			_.some(app.enabledLanguages, function (lang) {
				if (that._getCurrentLanguage() != lang && that.msModel.getTranslation(lang)) {
					result = that.msModel.getTranslation(lang);
					return true;
				}
			});
			return result;
		}
	}

	_updatePlaceholder () {
		if (!this.placeholder){
			this.$el.attr('placeholder', this._getPlaceholder() || '');
		}
	}

	/* WYSYWYG Editor  */

	_initRtfEditor($el) {
		var that = this;
		waitForSummernoteLoad.then(() => {
		let quantityBadge = null
		const _setCharsQuantity = function (quantity) {
			let q = 16384 - quantity
			if (q >= 0){
				quantityBadge.find('.chars-quantity').text(q)
				quantityBadge.find('.normalquantity').show()
				quantityBadge.find('.extraquantity').hide()
			} else {
				quantityBadge.find('.chars-quantity').text(q * (-1))
				quantityBadge.find('.normalquantity').hide()
				quantityBadge.find('.extraquantity').show()
			}
		}
		$el.summernote(_.extend(summernoteOptions, {
			lang: that._getRtfEditorLangTag(),
			onCreateLink : function(originalLink) {
					// Return the original link without auto correction
					return originalLink;
	 		},
			callbacks: {
				onChange: (contents, $editable) => {
					_setCharsQuantity(contents.length)
				},
				onInit: (instance) => {
					quantityBadge = instance.editor.siblings('.quantity-badge')
					if (quantityBadge.length == 0){
						quantityBadge = $(`
							<div class="quantity-badge" style="background-color:white">
								<div class="normalquantity">
									${translate("you.have.n.more.characters")}
								</div>
								<div class="extraquantity" style="color:red">
									${translate("you.have.extra.n.characters")}
								</div>
							</div>`)
						instance.editor.after(quantityBadge)
					}
					_setCharsQuantity(instance.editable.html().length)
					instance.codable.on('input', function () {
						_setCharsQuantity(this.value.length)
					})
				}
			}
		}));
		$el.on('update.chars.quantity', function(e) {
			_setCharsQuantity($(e.currentTarget).val().length);
		});
		$el.parent().find('.note-editable, .note-codable')
			.off('focus').on('focus', (e) => {
				// Highlight on focus
				$el.parent().find('.note-editor').addClass('note-editor-focused');
		});
		$el.parent().find('.note-editable, .note-codable')
			.off('blur').on('blur', (e) => {
				// Remove highlighting on leave
				$el.parent().find('.note-editor').removeClass('note-editor-focused');

				const contents = $el.summernote('code');
				const newValue = (contents == '<p><br></p>' ? '' : contents);

				that._saveRtfEditorContent($el, newValue);
		});

		// Fix issues appearing when the editor is open as dropdown
		$el.parent().find('.note-editable, .note-codable')
			.off('keydown keypress keyup')
		  .on('keydown keypress keyup', (e) => {
		    // Keep default behaviour for DELETE and BACKSPACE
		    e.stopPropagation();
		});
    //add iCheck checkbox input to summernote
    new CheckboxInput({
      el: $el.parent().find('input[type=checkbox]')[0],//same selector is used in summernote.js
      model: new Backbone.Model({value:true}),//that checkbox is predefined as true by summernote
      modelAttr: 'value'
    })
	})
	}

	_destroyRtfEditor($el) {
		waitForSummernoteLoad.then(() => {
			$el.find('.note-editable, .note-codable').off('keydown keypress keyup focus blur');
			$el.summernote('destroy');
			$('.note-popover').remove();
		})
	}

	_focusRtfEditor($el) {
		waitForSummernoteLoad.then(() => {
			$el.summernote('focus');
		})
	}

	_hideRtfEditorPopups($el) {
		$('.note-popover').hide();
	}

	_saveRtfEditorContent($el, newValue) {
		if ($el.attr('data-locale')) {
			var modelAttr = $el.attr('data-locale');
			this.translationInputs[modelAttr].setValue(newValue);
			this.translationInputs[modelAttr].changed();
		} else {
			this.translationInputs['value'].setValue(newValue);
			this.translationInputs['value'].changed();
		}
		this.$el.trigger('change');
	}

	_getRtfEditorLangTag() {
		return app.currentLanguage + '-' + app.currentLanguageCountry.toUpperCase();
	}
}

export default MultilingualHtmlInputField;
