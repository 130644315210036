import TaskState from "../enums/taskState";
import TaskKind from "../enums/taskKind";
import '../../../css/publishProgressBar.css';
import TaskResult from "../enums/taskResult";


class PublishProgressBar extends Backbone.View {
	initialize(options) {
		super.initialize(options);
		this.$el = $('.publish-progress-bar');
		this.$cssStyle = $('#publish-progress-bar');
		this.$realProgressBar = $('.progress-bar');
		this.checkAnimation();
	}
	
	checkAnimation() {
		let lastBuildTask = this.getLastBuildTask(),
			lastDeployTask = this.getLastDeployTask(),
			firstDeployTask = this.getFirstDeployTask();
		if (lastBuildTask && lastBuildTask.timeStarted && !this.animationStarted) {
			localStorage.setItem('buildTimeStarted_' + app.configuration.id, lastBuildTask.timeStarted.seconds);
		}
		if ((lastBuildTask && (lastBuildTask.result !== TaskResult.SUCCESS && lastBuildTask.result !== TaskResult.FAIL)) ||
			(lastDeployTask && (lastDeployTask.result !== TaskResult.SUCCESS && lastDeployTask.result !== TaskResult.FAIL))) {
			if (!this.animationStarted) {
				this.animationStarted = true;
				this.showAnimation();
			}
		} else {
			if (!lastBuildTask && !lastDeployTask && localStorage.getItem('buildTimeStarted_' + app.configuration.id)) {
				this.showAnimation();
				return;
			}
			if (firstDeployTask && firstDeployTask.state !== TaskState.DONE) {
				return;
			}
			if ((lastDeployTask && lastDeployTask.state === TaskState.DONE) ||
				(lastBuildTask && lastBuildTask.state === TaskState.DONE && !lastDeployTask)) {
				this.animationStarted = false;
				localStorage.setItem('buildTimeStarted_' + app.configuration.id, '');
				this.finishAnimation();
			} else {
				this.hideAnimation();
			}
		}
	}
	
	showAnimation() {
		this.$realProgressBar.addClass('hide');
		let time = this.calcTime();
		let width = Math.min(time * 100 / 60, 80) + '%';
		this.addCSS(width, time);
		this.$el.addClass('load');
		this.$el.removeClass('loaded');
		this.$el.show();
	}
	
	hideAnimation() {
		this.$el.hide();
		this.$realProgressBar.removeClass('hide');
	}
	
	finishAnimation() {
		this.$el.removeClass('load');
		this.$el.addClass('loaded');
		setTimeout(() => {
			this.$el.removeClass('loaded');
			this.hideAnimation();
		}, 2000);
	}
	
	calcTime() {
		let lastBuildTask = this.getLastBuildTask(), lastDeployTask = this.getLastDeployTask();
		let buildTimeStarted = lastBuildTask && lastBuildTask.timeStarted && lastBuildTask.timeStarted.seconds;
		let buildTimeFinished = lastBuildTask && lastBuildTask.timeDone && lastBuildTask.timeDone.seconds || new Date().getTime() / 1000;
		let time = 0;
		if (buildTimeStarted && buildTimeFinished) {
			time += buildTimeFinished - buildTimeStarted;
		} else {
			let buildTime = localStorage.getItem('buildTimeStarted_' + app.configuration.id);
			buildTime = buildTime && parseInt(buildTime) || buildTimeFinished;
			time += buildTimeFinished - buildTime;
		}
		let deployTimeStarted = lastDeployTask && lastDeployTask.timeStarted && lastDeployTask.timeStarted.seconds;
		let deployTimeFinished = lastDeployTask && lastDeployTask.timeDone && lastDeployTask.timeDone.seconds || new Date().getTime() / 1000;
		if (deployTimeStarted && deployTimeFinished && lastDeployTask.state !== TaskState.DONE) {
			time += deployTimeFinished - deployTimeStarted;
		}
		return time;
	}
	
	addCSS(width, time) {
		this.$cssStyle.html(
			`
				@keyframes publish-progress-bar-load {
					0% { width: ${width}; }
					100% { width: 80%; }
				}
				@keyframes publish-progress-bar-loaded {
					0% { width: 80%; }
					100% { width: 100%; }
				}
				.publish-progress-bar.load {
					animation: publish-progress-bar-load ${60 - Math.min(60, time)}s linear forwards
				}
				.publish-progress-bar.loaded {
					animation: publish-progress-bar-loaded 2s linear forwards
				}
			`
		);
	}
	
	getLastBuildTask() {
		let buildTasks = _.values(app.taskManager.tasks)
			.filter(task => task.kind === TaskKind.SYSTEM_BUILD)
			.sort(this.timeComparator);
		return buildTasks.length && buildTasks[0];
	}
	
	getLastDeployTask() {
		let deployTasks = _.values(app.taskManager.tasks)
			.filter(task => task.kind === TaskKind.DEPLOY || task.kind === TaskKind.DEV_DEPLOY)
			.sort(this.timeComparator);
		return deployTasks.length && deployTasks[0];
	}
	
	getFirstDeployTask() {
		let deployTasks = _.values(app.taskManager.tasks)
			.filter(task => task.kind === TaskKind.DEPLOY || task.kind === TaskKind.DEV_DEPLOY)
			.sort(this.timeComparator);
		return deployTasks.length && deployTasks[deployTasks.length - 1];
	}
	
	timeComparator(task1, task2) {
		if (!task2.timeStarted || !task1.timeStarted) {
			return true;
		}
		if (task2.timeStarted.seconds === task1.timeStarted.seconds) {
			return task2.timeStarted.nanos - task1.timeStarted.nanos;
		}
		return task2.timeStarted.seconds - task1.timeStarted.seconds
	}
	
}

export default PublishProgressBar;