import EntityMenuItemKind from '../enums/entityMenuItemKind';
import PermissionFilterKind from '../enums/permissionFilterKind';
import UnavailableModuleModeKind from '../enums/menuUnavailableModuleModeKind';
import {translate} from '../service/stringResourceService'
import { buildDefaultPopover } from './utils'
import utils from '../../common/components/utils.js';

export default class Menu extends Backbone.View {
	initialize (o) {
		this.maxShortLevelLength = 15;
		this.setElement($('#main-menu'));
		if (app.modulesMask != 0) {
			this.$el
			.find('li[data-modules-mask]')
			.each((index, elem) => {
				const $elem = $(elem)
				const elemMask = Number.parseInt($elem.attr('data-modules-mask'), 10)
				if (elemMask != 0 && (elemMask & app.modulesMask) == 0) {
					const mode = $elem.attr('data-unavailable-module-mode')
					if (mode ==	UnavailableModuleModeKind.HIDDEN) {
						$elem.remove()
					} else if (mode == UnavailableModuleModeKind.DISABLED) {
						$elem.find('a').removeAttr('href')
						$elem.addClass('disabled')
						buildDefaultPopover($elem,
						{
						 toggle: 'popover',
						 content: translate('module.not.available'),
						 html: true,
						 delay: 100,
						 container: 'body',
						 placement: 'right'
					 })
					}
				}
			})
		}
		if (!app.currentUser) {
			this.$el
				.find(`li[data-permission-kind="${PermissionFilterKind.AUTHORIZED}"]`)
				.remove();
		} else {
			this.$el
				.find(`li[data-permission-kind="${PermissionFilterKind.ANONYMOUS}"]`)
				.remove();
		}
		let toRemove = this.$el
			.find(`li[data-permission-kind="${PermissionFilterKind.IN_ANY_OF_ROLES}"]`)
			.add(`li[data-permission-kind="${PermissionFilterKind.NOBODY}"]`);
		app.currentUser && app.currentUser.userRoles.forEach(role => {
			toRemove = toRemove.not(`li[data-roles~="${role.id}"]`);
		});
		toRemove.remove();

		this.$el.find('.menu-level').perfectScrollbar({
			suppressScrollX: true
		});
		this.$el.find('li')
			.click(e => {
				e.stopPropagation();
				const $el = $(e.currentTarget);
				if (!$el.hasClass('disabled') && $el.attr('data-kind') == EntityMenuItemKind.FOLDER) {
					const level = +$el.closest('.menu-level').attr('data-level');
					const $level = this.$el.find(`.menu-level[data-level="${level}"]`);
					const $nextLevel = $level.next('.menu-level');
					const $submenu = $nextLevel
					.children(`ul[data-parent="${$el.attr('id')}"]`);
					$nextLevel.children('ul').hide();
					$submenu.show();
					$nextLevel.removeClass('menu-level-closed').removeAttr('style');
					$nextLevel.find('.back-text')
					.html($el.find('> a > .translatable').clone());
					$nextLevel.find('.back-down').show();
					if ($submenu.children('li.site-menu-item').length <
					this.maxShortLevelLength) {
						$nextLevel.find('.back-down').hide();
					}
					$level.toggleClass('menu-level-closed', true, 170);
				}
			});
		this.$el.find('.back').click(e => {
			const $level = $(e.currentTarget).parent();
			$level.prev('.menu-level').toggleClass('menu-level-closed', false, 90);
		});
		this.$el.find('.back-to-start').click(e => {
			const $level = this.$el.find('.menu-level[data-level="1"]');
			$level.toggleClass('menu-level-closed', false, 170);
		});
		this.$el.find('.back-to-start-text').html(app.getResource('back.to.start'));
	}

}
