/* @flow */

import type Block from '../models/block';
import View from '../models/view';
import BlockTrigger from '../enums/blockTrigger';
import BlockType from '../enums/blockType';
import MultilingualString from '../models/multilingualString';
import TypeKind from '../enums/typeKind';
import { icons } from './icons';
import Constants from '../models/constants'

function getDivider(): string {
	return '<li class="divider"></li>';
}

function getDropdown(dropdownMenu: string): string {
	return `<li class="type-edit-controll dropdown body-container">
			<a href="#" class="dropdown-toggle" data-toggle="dropdown">
				<span class="glyphicon glyphicon-option-horizontal expand">
			</a>
			<ul class="dropdown-menu eventsDropdown">
				${dropdownMenu}
			</ul>`;
}

export function getViewEditEvents(view: any): string {
	let result = '';
	_.each([BlockTrigger.FORM_LOAD, BlockTrigger.FORM_LOADED, BlockTrigger.VALIDATE_FORM],
		event => {
			if (view.viewEvents[event]) {
				let options = _.clone(icons[event]) || {};
				options.classes = 'openEventBlock';
				options.href = app.urls.blockConstruct(view.viewEvents[event]);
				options.text = `Edit on ${event.toLowerCase()} event`;
				result += getAction(options);
			}
		});
	return result;
};

export function getCreateEvents(view: any): string {
	let result = '';
	_.each([BlockTrigger.FORM_LOAD, BlockTrigger.FORM_LOADED, BlockTrigger.VALIDATE_FORM],
		event => {
			if (!view.viewEvents[event]) {
				let options = _.clone(icons[event]) || {};
				options.classes = 'createViewEventBlock';
				options.href = '#/';
				options.text = app.getResource(`create.on.${event.toLowerCase().replace('_', '.')}.event`);
				options.data = {
					viewId: view.id,
					trigger: event,
					type: BlockTrigger.getBlockType(event)
				};
				result += getAction(options);
			}
		});
	return result;
};

export function getEventList(view: any, parent: boolean): string {
	const editEvents = getViewEditEvents(view);
	const createEvents = getCreateEvents(view);
	let removeAction = '';
	if (parent) {
		removeAction = getDivider() + getRemoveAction(view, { classes: 'remove-view' });
	}
	return `${editEvents}
					${editEvents && createEvents && getDivider()}
					${createEvents}
					${removeAction}`;
};

function getWizardLink(view: any): string {
	let options = _.clone(icons['wizard']) || {};
	options.href = `${app.urls.wizard}/${view.wizardId}`;
	options.text = app.getResource('open.structure');
	return getAction(options);
}

function getMakeDefaultAction(view: Object|View): string {
	let isDefault = false;
	if (!(view instanceof View)) {
		isDefault = view.viewModifier == 'DEFAULT' || view.parentViewId;
	} else {
		isDefault = view.isDefault() || view.parent();
	}
	if (isDefault) {
		return '';
	}
	let options: any = _.clone(icons['makeDefault']) || {};
	options.text = app.getResource('make.default');
	options.classes = 'makeDefaultView';
	options.data = {
		id: view.id
	};
	return getAction(options);
}

export function getDropdownWithCreateViewEvents(view: any): string {
	const makeDefault = getMakeDefaultAction(view);
	const dropdownMenu = `${makeDefault ? makeDefault + getDivider() : ''}
												${getCreateEvents(view) ? getCreateEvents(view) + getDivider() : ''}
												${getRemoveAction(view, { classes: 'removeView' })}`;
	return getDropdown(dropdownMenu);
};

export function getDropdownWithViewEvents(view: any, parent: boolean): string {
	return getDropdown(getEventList(view, parent));
};

function getCopyAction(copyOptions: Object): string {
	let options = _.clone(icons['copy']) || {};
	_.extend(options, copyOptions);
	options.classes = (options.classes || '') + ' copy-to-clipboard';
	return getAction(options);
}

export function getFieldTooltip(field: Object): string {
	let availableToCreateBlocks = '';
	let events = BlockTrigger.getTriggersForBlock(app.fields.get(field.id));
	_.each(events, event => {
			if (!field.fieldEvents[event]) {
				let options = _.clone(icons[event]) || {};
				options.classes = 'createFieldEventBlock';
				options.href = '#/';
				options.text = app.getResource(`create.on.${event.toLowerCase().replace(/_/g, '.')}.event`);
				options.data = {
					fieldId: field.realId,
					parentFieldId: (field.parent && field.parent.id) || '',
					trigger: event,
					type: BlockTrigger.getBlockType(event)
				};
				availableToCreateBlocks += getAction(options);
			} else {
				let options = _.clone(icons[event]) || {};
				options.classes = 'openEventBlock';
				options.href = app.urls.blockConstruct(field.fieldEvents[event]);
				options.text = app.getResource(`edit.on.${event.toLowerCase().replace(/_/g, '.')}.event`);
				options.data = {
					fieldId: field.realId,
					parentFieldId: (field.parent && field.parent.id) || '',
					trigger: event,
					type: BlockTrigger.getBlockType(event)
				};
				availableToCreateBlocks += getAction(options);
			}
		});
	if (availableToCreateBlocks) {
		availableToCreateBlocks = getDivider() + availableToCreateBlocks;
	}
	let dropdownMenu = '';
	if(!field.parent && !field.isShared) {
		dropdownMenu += getEditAction(field, { classes: 'editField' });
		dropdownMenu += getEditAction(field,
			{ classes: 'editFieldSystemName', text: app.getResource('edit.system.name') });
		dropdownMenu += getDivider();
	}
	dropdownMenu += getCopyAction({
		text: `${app.getResource('copy.id')}: ${field.realId}`,
		data: {copy: field.realId}
	});
	if (field.systemName) {
		dropdownMenu += getCopyAction({
			text: `${app.getResource('copy.system.name')}: ${field.systemName}`,
			data: {copy: field.systemName}
		});
	}
	dropdownMenu += getDivider();
	dropdownMenu += getUsagesAction(field, 'field');
	if (!field.parent && (field.fieldKind === 'DYNAMIC' || !(field.fieldType.typeKind === 'EMBEDDED'))) {
		dropdownMenu += getReplaceAction(field, { classes: 'replaceField' });
	}
	dropdownMenu += getChangeFieldType(field, { classes: 'changeFieldType' });
	dropdownMenu += availableToCreateBlocks;
	if(!field.parent && !field.isSystem) {
		dropdownMenu += getDivider();
		dropdownMenu += getRemoveAction(field, { classes: 'removeField' });
	}
	return dropdownMenu;
};

export function getSystemInstanceTooltip(systemInstance: Object): string {
	let dropdownMenu = '';
	dropdownMenu += getUsagesAction(systemInstance, 'systemInstance');
	return dropdownMenu;
};

export function showFieldTooltip(field: Object): string {
	return getDropdown(getFieldTooltip(field));
};

export function showSystemInstanceTooltip(systemInstance: Object): string {
	return getDropdown(getSystemInstanceTooltip(systemInstance));
};

export function showEventBlock(field: Object, prior: number): string {
	let result = [];
	BlockTrigger.getTriggersForBlock(app.fields.get(field.id)).forEach(event => {
			if (field.fieldEvents[event]) {
				let options = _.clone(icons[event]) || {};
				options.classes = 'openEventBlock';
				options.href = app.urls.blockConstruct(field.fieldEvents[event]);
				result.push(getIcon(options));
			}
		});
	if (result.length > prior) {
		return result[prior];
	} else {
		return '';
	}
};

export function showViewEventBlock(view: Object, prior: number): string {
	let result = [];
	_.each([BlockTrigger.FORM_LOADED, BlockTrigger.FORM_LOAD, BlockTrigger.VALIDATE_FORM],
		event => {
			if (view.viewEvents[event]) {
				let options = _.clone(icons[event]) || {};
				options.classes = 'openEventBlock';
				options.href = app.urls.blockConstruct(view.viewEvents[event]);
				result.push(getIcon(options));
			}
		});
	if (result.length > prior) {
		return result[prior];
	} else {
		return '';
	}
};

function getIcon(options): string {
	let classes = (options.classes && `class="${options.classes}"`) || '';
	let data = (options.data &&
		_.map(Object.keys(options.data), key => {
				return `data-${key}="${options.data[key]}"`;
			}).join(' ')) || '';
	let iconClasses = options.iconClasses || '';
	let icon = options.icon || '';
	let text = options.text || '';
	let href = options.href ? `href="${options.href}"` : 'href="#/"';
	let iconData = ((iconClasses || icon) && `<i class="${iconClasses}">${icon}</i>`) || '';
	return `<a ${href} ${classes} ${data}>${iconData}<span>${text}</span></a>`;
}

function getAction(options: Object): string {
	return `<li>${getIcon(options)}</li>`
}

function getEditAction(block: Block, editOptions: Object): string {
	let options: any = _.clone(icons['edit']) || {};
	_.extend(options, editOptions || {});
	options.href = '#/';
	options.data = {
		id: block.id
	};
	options.text = options.text || app.getResource('edit');
	return getAction(options);
}

function getEditSystemNameAction(obj: any, editOptions: Object): string {
	let options: any = _.clone(icons['edit']) || {};
	_.extend(options, editOptions || {});
	options.href = '#/';
	options.data = {
		id: obj.id
	};
	options.text = app.getResource('edit');
	return getAction(options);
}

function getReplaceAction(field: any, replaceOptions: Object): string {
	let options: any = _.clone(icons['replace']) || {};
	_.extend(options, replaceOptions || {});
	options.href = '#/';
	options.data = {
		id: field.id
	};
	options.text = app.getResource('replace.field');
	return getAction(options);
}

export function getUsagesAction(object: Block|Object, usage: string, usageText: string): string {
	let usageTarget;
	switch (usage) {
		case 'block':
			usageTarget = '#BlockUsageModal';
			break;
		case 'systemInstance':
			usageTarget = '#SystemInstanceUsageModal';
			break;
		case 'modules':
			usageTarget = '#ModulesUsageModal'
			break
		default:
			usageTarget = '#FieldUsageModal';
	}
	let options: any = _.clone(icons['usages']) || {};

	options.text = usageText || app.getResource('get.usages');

	options.href = '#/';
	options.data = {
		id: object.realId || object.id,
		target: usageTarget
	};
	return getAction(options);
}

function getChangeFieldType(object: Block|Object, changeTypeOptions: Object): string {

	let options: any = _.clone(icons['edit']) || {};
	_.extend(options, changeTypeOptions);
	options.href = '#/';
	options.text = app.getResource("change.field.type");
	options.data = {
		id: object.id
	};
	return getAction(options);
}

function getRemoveAction(object: Block|Object, removeOptions: Object): string {
	let options: any = _.clone(icons['remove']) || {};
	_.extend(options, removeOptions);
	options.href = '#/';
	options.text = app.getResource('delete');
	options.data = {
		id: object.id
	};
	return getAction(options);
}

function getOpenViewAction(block: Block): string {
	let options: any = _.clone(icons['openView']) || {};
	options.text = app.getResource('open.view');
	options.href = `${app.urls.view + '/' + block.view().id}`;
	return getAction(options);
}

function getOpenTypeAction(block: Block): string {
	let options: any = _.clone(icons['openType']) || {};
	options.text = app.getResource('open.type');
	let type = block.fillResultType();
	if (type.id == app.model.id) {
		type = block.owner();
	}
	options.href = app.urls.open(Constants.ID_TYPE_TYPE, type.id);
	return getAction(options);
}

function getAddToSchedulerAction(block: Block, blockOptions: Object): string {
	let options: any = _.clone(icons['add_to_scheduler']) || {};
	_.extend(options, blockOptions || {});
	options.href = '#/';
	options.data = {
		id: block.id
	};
	options.text = app.getResource('add.to.scheduler');
	return getAction(options);
}

function getEditSchedulerJobAction(block: Block, blockOptions: Object): string {
	let options: any = _.clone(icons['edit']) || {};
	_.extend(options, blockOptions || {});
	options.href = '#/';
	options.data = {
		id: block.id
	};
	options.text = app.getResource('edit.scheduler.job');
	return getAction(options);
}

function getRemoveFromSchedulerAction(block: Block, blockOptions: Object): string {
	let options: any = _.clone(icons['remove_from_scheduler']) || {};
	_.extend(options, blockOptions || {});
	options.href = '#/';
	options.data = {
		id: block.id
	};
	options.text = app.getResource('remove.from.scheduler');
	return getAction(options);
}


function blockTooltip(block: Block): string {
	let result = `<li class="dropdown body-container">
			${getBlockToolTip(block)}
		</li>`;
	return result;
}

function getBlockToolTip(block: Block): string {
	return `<ul class="dropdown-menu eventsDropdown">
		${getCopyAction(({
			text: `${app.getResource('copy.id')}: ${block.id}`,
			data: {copy: block.id}
		}))}
		${block.isViewAction() ? getOpenViewAction(block) + getDivider() : ''}
		${block.isFillFunction() ? getOpenTypeAction(block) + getDivider() : ''}
		${block.isFunction() || block.isCustomAction() || block.isFillFunction() ?
			getEditAction(block, { classes: 'editBlock' }) : ''}
		${!block.isFillFunction() ? getUsagesAction(block, 'block') : ''}
		${block.isCustomAction() && block.isServerBlock() && !block.usedInScheduler()
			? getAddToSchedulerAction(block, { classes: 'addToScheduler' }) : ''}
		${block.usedInScheduler()
				? getEditSchedulerJobAction(block, { classes: 'editSchedulerJob' }) : ''}
		${block.usedInScheduler()
			? getRemoveFromSchedulerAction(block, { classes: 'removeFromScheduler' }) : ''}
		${getRemoveAction(block, { classes: 'removeBlock' })}
	</ul>`
}

function getBlockName(block: Block): string {
	if (block.isFieldAction()) {
		let field = block.eventTriggerField();
		let subField = block.eventTriggerSubField() || '';
		let result = (new MultilingualString(field.name())).getCurrentValue();
		if (subField) {
			result = `${(new MultilingualString(subField.name())).getCurrentValue()}<span class="parent-name">${result}</span>`;
		}
		return result;
	} else if (block.isViewAction()) {
		let view = block.view();
		let root = app.views.getWizardRoot(view.id);
		let result = (new MultilingualString(view.name())).getCurrentValue();
		if (root.id != view.id) {
			result += `<span class="parent-name">${(new MultilingualString(root.name())).getCurrentValue()}</span>`;
		}
		return result;
	} else if (block.isFillFunction()) {
		let resultType = block.fillResultType();
		let typeName = (new MultilingualString(resultType.name())).getCurrentValue();
		if (resultType.id == app.model.id) {
			typeName = (new MultilingualString(block.owner().name())).getCurrentValue();
		}
		return `${block.name()}<span class="parent-name">${typeName}</span>`;
	} else {
		return block.name();
	}
}

function getBlockIcon(block: Block): string {
	if (block.isFieldAction() || block.isViewAction()) {
		let options = _.clone(icons[block.blockTrigger()]) || {};
		options.href = app.urls.blockConstruct(block.id);
		return getIcon(options);
	} else if (block.isFillFunction()) {
		let key = block.fillResultType().id == app.model.id ? 'result' : 'source';
		let options = _.clone(icons[block.blockTrigger()][key]);
		options.text = key[0].toUpperCase();
		return getIcon(options);
	} else {
		return getActionIcon(block);
	}
}

// TODO: add classes into icons object and remove this function
function getActionIcon(block: Block): string {
	let classes = '';
	switch (block.type()) {
		case BlockType.CLIENT:
			classes = 'label-client';
			break;
		case BlockType.SERVER:
			classes = 'label-server';
			break;
		case BlockType.HYBRID:
			classes = 'label-hybrid';
			break;
	}
	let $a = $(`<a class="table-label label block-label"/>`).addClass(classes)
		.attr('title', block.type())
		.text(block.type().charAt(0));
	return $a.prop('outerHTML');
}

export function contentBody(block: Block): string {
	let icon = getBlockIcon(block);
	let name = getBlockName(block);
	return icon + `<a class="icon-padding">${name}</a>` +
			`<li style="padding-left: 7px;" class="dropdown body-container put-down">
				<span  class="dropdown-toggle" data-toggle="dropdown">
			<span class="fa fa-caret-down" style="vertical-align: middle;"></span>
			</span>
			${getBlockToolTip(block)}</li>` +
			`<div class="pull-right">
				${block.usedInScheduler() ? '<i class="material-icons notranslate fa defaultAction">restore</i>' : ''}
				<span class="padding-box">${blockTooltip(block)}
				</span>
			</div>`;
}

export function formatBlock(block: Block): string {
	return `<div class="box-body row-with-border data-row data-hover"
		data-url=${app.urls.blockConstruct(block.id)} data-blockId=${block.id}>
						${contentBody(block)}
					</div>`;
}
