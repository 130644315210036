import MetaObjectState from '../../common/enums/metaObjectState';
import ReportRowGroups from '../components/reportRowGroups';
import ReportColumnGroups from '../components/reportColumnGroups';
import ReportValues from '../components/reportValues';
import ReportFilters from '../components/reportFilters';
import ReportView from './reportView';
import NotificationManager from '../../common/service/notificationManager';
import FormView from './formView';
import Toolbar from '../components/toolbar';
import MultilingualString from '../../common/models/multilingualString';
import utils, { removeNotActiveLanguages} from '../../common/components/utils'
import journalPresenter from './journalPresenter';
import { multilingualStringService } from '../../common/service/multilingualStringService';
import {translate} from '../../common/service/stringResourceService'
import urlService from '../../common/components/urlService'
import { updateCjTitle } from '../../common/components/cjTabs'

/**
 * @namespace wizardView
 */
var WizardView = Backbone.View.extend({

	initialize(o) {
		WizardView.__super__.initialize.apply(this, arguments);
		this.context = o.context;
		this.type = this.context.type;
		this.model = this.context.model;
		this.context.wizardView = this;

		this.$toolbar = o.context.toolbar && $(o.context.toolbar);
		if (!this.$toolbar || !this.$toolbar.length) {
			this.$toolbar = this.$('.fixed-toolbar').prependTo(this.$el);
		}
		if (this.$toolbar.length) {
			this.toolbar = new Toolbar({
				el: this.$toolbar,
				context: this.context
			});
			app.notificationManager = new NotificationManager();
		}

		if (!this.context.showToolbar) {
			this.$toolbar.remove();
		}

		this._findGlobal('button.build').click(() => this.buildReport(false));
		this._findGlobal('button.customize').click(() => this.toggleReportCustomize());
		// $('#hide-main-menu').change(() => this.hideMainMenu());

		this.forms = [];
		this.validationContext = o.context.validationContext;
		var that = this;
		this.$('.forms-container > div').each(function (i, el) {
			that.forms.push(new FormView({
				el: el,
				context: that.context,
			}))
			that.addUpdateButton()
		})
		if (this.forms.length == 0) {
			this.forms.push( new FormView({
				el: this.el,
				context: this.context
			}))
		}
		if (this.type.isReport()) {
			this.waitForJqueryUiLoad = import(/*webpackChunkName:'jqueryUi'*/'../../extensions/jquery-ui.js')
			this.reportInit()
		}
		this.showUpdateSaveButtons();
		if (this.type.isDocument()) {
			this.togglePostedState()
		}
		if (this.model.get('metaObjectState') == MetaObjectState.DELETED) {
			this._findGlobal('.delete-entity').remove();
		}
		if (app.builderMode) {
			this.hideFillFunctions();
		}
		this._disableAllForms();
		this._enableLastForm();
		if (this.context.updating) {
			this.$el.addClass('updating');
		}
	},

	reportInit() {
		let $formContainer = this.$('.forms-container')
		if ($formContainer.find('.customizeReportForm').length) {
			$formContainer.find('.customizeReportForm').replaceWith(this.getReportHTML())
		} else {
			$formContainer.append(this.getReportHTML())
		}
		this.reportView = new ReportView({
			model: this.context.model,
			el: this.context.$el.find('.reportForm').first(),
			context: this.context
		});
		this.reportRowGroups = new ReportRowGroups({
			el: this.context.$el.find('.report-row-groups'),
			model: this.context.model,
			waitForJqueryUiLoad: this.waitForJqueryUiLoad,
			modelAttr: 'rowGroups',
			context: this.context
		});
		this.reportColumnGroups = new ReportColumnGroups({
			el: this.context.$el.find('.report-column-groups'),
			model: this.context.model,
			waitForJqueryUiLoad: this.waitForJqueryUiLoad,
			modelAttr: 'columnGroups',
			context: this.context
		});
		this.reportValues = new ReportValues({
			el: this.context.$el.find('.report-values'),
			model: this.context.model,
			waitForJqueryUiLoad: this.waitForJqueryUiLoad,
			modelAttr: 'values',
			context: this.context
		});
		this.reportFilters = new ReportFilters({
			el: this.context.$el.find('.report-filters'),
			model: this.context.model,
			modelAttr: 'filters',
			context: this.context
		});
	},

	async initializeAsyncComponents() {
		let promises = this.forms.map(formView => formView.initializeAsyncComponents())
		return Promise.all(promises)
	},

	_findGlobal(selector, exclude) {
		const all = this.$(selector).add(this.$toolbar.find(selector));
		return exclude ? all.not(exclude) : all;
	},

	togglePostedState() {

		if (this.context.updating) {
			this._findGlobal('.apply', '.collection *').hide();
			this._findGlobal('.relieve', '.collection *').hide();
			this._findGlobal('.show-registers', '.collection *').hide();
			return ;
		}

		if (this.model.get('metaObjectState') == 'DELETED') {
			this._findGlobal('.apply', '.collection *').hide();
			this._findGlobal('.relieve', '.collection *').hide();
			this._findGlobal('.show-registers', '.collection *').hide();
		} else
		if (this.model.get('documentRegister') && (this.model.get('documentRegister').state == 'POSTED')) {
			this._findGlobal('.apply', '.collection *').hide();
			this._findGlobal('.relieve', '.collection *').show();
			this._findGlobal('.show-registers', '.collection *').show();
		} else {
			this._findGlobal('.apply', '.collection *').show();
			this._findGlobal('.relieve', '.collection *').hide();
			this._findGlobal('.show-registers', '.collection *').hide();
		}
	},

	showUpdateSaveButtons() {
		if (this.context.updating) {
			this._findGlobal('.save, .save-close, .cancel, .cancel-close').show();
			this._findGlobal('.save, .save-close, .cancel, .cancel-close').next().show();
			this._findGlobal('.edit').hide();
			this._findGlobal('.edit').next().show();
			this._findGlobal('.edit').next().css('display', 'block')
			this.togglePostedState();
		} else {
			this._findGlobal('.save, .cancel, .cancel-close, .save-close').hide();
			this._findGlobal('.save, .save-close, .cancel, .cancel-close').next().hide();
			this._findGlobal('.edit').next().show();
			this._findGlobal('.edit').show();
			this.showFillFunctions()
			this.togglePostedState();
			if (!this._findGlobal('button.update-save').parent().children('ul.dropdown-menu').children().children(':not(.save-close, .save, .cancel, .cancel-close)').length){
				this._findGlobal('.edit').next().css('display','none')
				this._findGlobal('.edit').css('border-radius', '3px')
				this._findGlobal('.edit').parent().removeClass('open')
			}
		}
	},

	hideUpdateSaveButtons() {
		this._findGlobal('.save, .save-close').hide();
		this._findGlobal('.save').next().hide();
		this._findGlobal('.edit').hide();
	},

	showFillFunctions() {
		this._findGlobal('.clone').show();
		this._findGlobal('.fill-functions').show();
	},
	hideFillFunctions() {
		this._findGlobal('.clone').hide();
		this._findGlobal('.fill-functions').hide();
	},

	_disableAllForms() {
		_.each(this.forms, function (form) {
			form.disable();
		});
	},

	_enableLastForm() {
		_.last(this.forms).enable();
	},

	_hideAllForms() {
		_.each(this.forms, form => {
			form.hide();
		});
	},

	_showAllForms() {
		_.each(this.forms, form => {
			form.show();
		});
	},

	_startUnsavedChangesTracking() {
		_.last(this.forms).startUnsavedChangesTracking();
	},

	_suppressUnsavedChangesTracking() {
		_.last(this.forms).suppressUnsavedChangesTracking();
	},

	_resumeUnsavedChangesTracking() {
		_.last(this.forms).resumeUnsavedChangesTracking();
	},

	_setFocusInFirstForm(readOnlyMode) {
		if (!this.context.isWidget) {
			_.first(this.forms).setDefaultFocus(readOnlyMode);
		}
	},

	_scrollToFirstError() {
		this.$('.has-error').length && $('html, body').animate({
			scrollTop: $('.has-error').first().offset().top - 50
		}, 100);
	},

	delete() {
		return utils.postRequest([this.model.id], app.urls.hierarhySize(this.type.id)).then(response => {
			let resource = '';
			if (response.size > [this.model.id].length) {
				resource = response.fullSize ? 'action.delete.items.question.with.hierarchy.precise' :
					'action.delete.items.question.with.hierarchy';
				resource = multilingualStringService.format(app.getResource(resource), [response.size]);
			}
			utils.confirmDelete([this.model.id], app.urls.dependencies(this.type.id), app.urls.delete(this.type.id),
			{beforeDelete: resource},
			() => this.context.onClose(true))
		});
	},

	unpost() {
		return utils.postRequest([this.model.id], app.urls.relieveSelected(this.type.id))
			.then(() => {
				this.getDataAfterSave().then(() => {
					this.togglePostedState();
				});
				app.notificationManager.addSuccess(translate('unposted'))
			})
			.catch(response => {
				utils.error(response);
			});
	},

	post() {
		return utils.postRequest([this.model.id], app.urls.applySelected(this.type.id))
			.then(() => {
				this.getDataAfterSave().then(() => {
					this.togglePostedState();
				});
				app.notificationManager.addSuccess(translate('posted'))
			})
			.catch(response => {
				utils.error(response);
			});
	},

	showRegisters(e) {
		let title;
		app.entityManager.fetchStringView(null, this.model.id).then((data) => {
			title = data;
			app.cjTabs.createTab({
				documentId: this.model.id,
				title: title + ' ' + translate('postings'),
				onRender: (options) => {
					journalPresenter.present({
						$el: options.$el,
						documentId: this.model.id
					})
				}});
		});
	},

	listLink() {
		this.prepareToSave();
		this.context.onClose();
	},

	saveAndClose() {
		return this.save(true);
	},

	close() {
		this.prepareToSave();
		this._suppressUnsavedChangesTracking();
		this.context.onClose();
	},

	cancel() {
		this.getDataAfterSave()
		this.exitEditMode()
	},

	prepareToSave() {
		this.forms.forEach((form) => {
			form.inputs.forEach((input) => {
				input.prepareToSave && input.prepareToSave();
			});
		});
	},

	save(close, temporary) {
		this.prepareToSave();
	 	return new Promise((resolve, reject) => {
			this._suppressUnsavedChangesTracking();
			if (this.validationContext.validate()) {
				let url = this.model.id && !temporary
					? app.urls.update(this.type.id, this.model.id)
					: app.urls.create(this.type.id);
				let parameters = urlService.url.search;
				if (this.context.viewId) {
					parameters += parameters ? '&' : '?';
					parameters += 'viewId=' + this.context.viewId;
				}
				if (this.context.parentId) {
					parameters += parameters ? '&' : '?';
					parameters += 'parentId=' + this.context.parentId;
				}
				if (temporary) {
					parameters += parameters ? '&' : '?';
					parameters += 'temporary=true';
				}
				url += parameters;
				utils.postRequest(this.context.getServerData(),	url)
						.then((answer) => {
							if (temporary) {
								return answer;
							} else {
								if (!this.model.id) {
									this.model.id = answer.id
									this.context.objectId = answer.id;
									if (this.context.afterCreated) {
										this.context.afterCreated(this.context);
									}
								}
								return (!close || this.context.afterSaved) && this.getDataAfterSave().then(data => {
									if (this.toolbar) {
										this.toolbar.toggleButtons()
									}
									this.togglePostedState();
									return data;
								});
							}
						})
						.then(data => {
							if(!temporary) {
								app.notificationManager.addSuccess(translate('saved'))

								if (this.context.afterSaved) {
									this.context.afterSaved(data)
								}
								if (this.type.isReport()) {
									this.reportInit()
									this.render()
								}
								if (close) {
									this.context.onClose();
								} else {
									utils.getRequest(app.urls.getTitle(this.context.viewId, this.model.id, this.model.entityTypeId)).then((title) => {
										this.context.title = title
										updateCjTitle(this.context.title, this.context.viewControl.id)
									})
									this.exitEditMode();
								}
							} else {
								return data;
							}
						})
						.then(resolve)
						.catch(response => {
							this._resumeUnsavedChangesTracking();
							let context = response;
							if (context && context.messages) {
								context.messages.forEach(message => {
									if (message.message && message.path) {
										this.validationContext
											.addNewMessage(message.message, message.path)
										}
									}
								);
							}
							if (context && context.responseObject && context.responseObject.messages) {
								context.responseObject.messages.forEach(message =>
										this.validationContext
											.addNewMessage(message.message, message.path)
										);
							}
							utils.error(response);
							reject();
						});
			} else {
				this._resumeUnsavedChangesTracking();
				this._scrollToFirstError();
				reject();
			}
		});
	},

	/**
	 * Switch form to readonly mode
	 */
	exitEditMode() {
		this.context.updating = false;
		this.$el.removeClass('updating')
		this.showUpdateSaveButtons()
		this._disableAllForms()
	},

	getDataAfterSave() {
		let promise = Promise.resolve()
		return promise.then(() => {
			let url = app.urls.data(
				this.type.id,
				this.model.id,
				{viewId: app.builderMode || !this.model.id ? null : this.context.viewId});
			return utils.getRequest(url);
		})
		.then(data => {
			delete data.item.viewContext
			this.model.merge(data.item, {});
      if (data.modifiedItem){
        this.model.merge(data.modifiedItem, data.item)
      }
      let dataToSet=['metaObject', 'version']
      _.each(dataToSet,(key)=>{
        let d = data.item[key]
        if (d){
          this.model.set(key,d)
        }
      })
    	_.extend(this.context.data, data.item);
    	this.context.modifiedData = data.modifiedItem;
			this.context.canUpdate = data.canUpdate;
			this.context.canViewHistory = data.canViewHistory;
			return data;
		});
	},

	/**
	 * Enter edit mode
	 */
	edit() {
		this.context.updating = true;
		this.$el.addClass('updating');
		this._enableLastForm();
		this._findGlobal('.save').show();
		this._findGlobal('.save').next().show();
		this._findGlobal('.edit').next().css('display', 'block')
		this._findGlobal('.save-close, .cancel, .cancel-close').show();
		this._findGlobal('.edit').hide();
		this._findGlobal('.apply').hide();
		this._startUnsavedChangesTracking();
		this._setFocusInFirstForm(false);
	},

	render() {
		this.updateTheme();
		_.each(this.forms, function (form) {
			form.render();
		});
		if (this.type.isReport()) {
			this.reportView.render();
			this.reportRowGroups.render();
			this.reportColumnGroups.render();
			this.reportValues.render();
			this.reportFilters.render();
		}
		if (this.model.get('metaObjectState') != MetaObjectState.ACTIVE ||
			!this.context.updating) {
			this._disableAllForms();
			this._setFocusInFirstForm(true);
		} else {
			this._enableLastForm();
			this._startUnsavedChangesTracking();
			this._setFocusInFirstForm(false);
		}
		if (this.context.hideLoading) {
			this.context.hideLoading();
		}
		if (this.type.isReport() && this.model.id) {
			this.buildReport(true);
		}
		removeNotActiveLanguages(this.context.$el);
	},

	destroy() {
			_.each(this.forms, f => f.destroy());
		this.undelegateEvents();
		this.$el.html('');
	},

	async buildReport(afterRenderCall) {
		if (this.checkReportErrors()) {
			return;
		}
		const animationSelector = 'button.build > .animation';
		if (!$(animationSelector).size()) {
			this._findGlobal('button.build').prepend(
					`<span
							class="animation glyphicon glyphicon-refresh glyphicon-refresh-animate"
							style="display: none; margin-right: 4px;">
					</span>`);
		}
		$(animationSelector).show();
		try {
			await this.reportView.buildReport();
		} finally {
			$(animationSelector).hide();
		}
		if (this.openCustomizeAfterSave && afterRenderCall) {
			this.showReportCustomizeForm()
		} else {
			this.showReportForm()
		}
		this.openCustomizeAfterSave = false
	},

	exportReportToExcel() {
		if (this.checkReportErrors()) {
			return;
		}
		this.reportView.exportReport();
	},

	checkReportErrors() {
		const formHasErrors = !this.validationContext.validate();
		const customizeHasErrors = $(".customizeReportForm .has-error").length > 0;
		if (formHasErrors || customizeHasErrors) {
			if (customizeHasErrors) {
				this.showReportCustomizeForm();
			}
			this._scrollToFirstError();
			return true;
		}
		return false;
	},

	showReportForm() {
		this._findGlobal('button.customize').removeClass('active');
		this._showAllForms();
		this.$('.customizeReportForm').hide();
		this.openCustomizeAfterSave = false
	},

	showReportCustomizeForm() {
		this._findGlobal('button.customize').addClass('active');
		this._hideAllForms();
		this.$('.customizeReportForm').show();
		this.openCustomizeAfterSave = true
	},

	toggleReportCustomize() {
		if (this._findGlobal('button.customize').hasClass('active')) {
			this.showReportForm();
		} else {
			this.showReportCustomizeForm();
		}
	},

	downloadSpreadsheet(options) {
		options = _.extend({}, options);
		utils.downloadBlobByUrl(
			app.urls.openEntitySpreadsheet(this.type.id, options.viewId),
			this.context.getServerData(),
			options.filename);
	},

	downloadXml(options) {
		options = _.extend({}, options);
		utils.downloadBlobByUrl(
			app.urls.openEntityXml(this.type.id, options.viewId, options.skipValidation),
			this.context.getServerData(),
			options.filename);
	},

	downloadDoc(options) {
		options = _.extend({}, options);
		utils.downloadBlobByUrl(
			app.urls.openEntityDoc(this.type.id, options.viewId, options.skipValidation),
			this.context.getServerData(),
			options.filename);
	},

	downloadPdf(options) {
		options = _.extend({}, options);
		utils.downloadBlobByUrl(
			app.urls.openEntityPdf(this.type.id, options.viewId, options.skipValidation),
			this.context.getServerData(),
			options.filename);
	},

	addUpdateButton() {
		if (this.context.canUpdate !== null && this.context.canUpdate) {
			var edit = $(`<button class="toolbar-btn btn btn-danger edit update-save" style="display:none">${translate('update')}</button>`)
			edit.insertAfter(this._findGlobal('.save, .save-close').not('.dropdown-menu .save, .dropdown-menu .save-close')).click(() => this.edit())
		}
	},

	updateTheme() {
		let previousUrl = $('#cssTheme').attr('href')
		if (this.context.themeUrl !== previousUrl){
			if (this.context.themeUrl) {
				$('#cssTheme').attr('href', this.context.themeUrl);
			} else {
				$('#cssTheme').removeAttr('href');
			}
		}
	},

	getReportHTML() {
		return `  <div class="row customizeReportForm" style="display: none">
								<div class="col-md-3">
									<div class="box box-primary report-row-groups">
										<div class="box-header with-border">
											<h3 class="box-title"></h3>
											<div class="box-tools pull-right">
												<select class="form-control field-select"
													data-size="auto" data-create-new-action="dont.show.create.new">
												</select>
											</div>
										</div>
										<div class="items">
											<div class="box-header with-border placeholder" th:utext="#{list.empty}">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="box box-primary report-column-groups">
										<div class="box-header with-border">
											<h3 class="box-title"></h3>
											<div class="box-tools pull-right">
												<select class="form-control field-select"
													data-size="auto" data-create-new-action="dont.show.create.new">
												</select>
											</div>
										</div>
										<div class="items">
											<div class="box-header with-border placeholder" th:utext="#{list.empty}">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="box box-primary report-values">
										<div class="box-header with-border">
											<h3 class="box-title"></h3>
											<div class="box-tools pull-right">
												<select class="form-control field-select"
													data-size="auto" data-create-new-action="dont.show.create.new">
												</select>
											</div>

										</div>
										<div class="items">
											<div class="box-header with-border placeholder" th:utext="#{list.empty}">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="box box-primary report-filters">
										<div class="box-header with-border">
											<h3 class="box-title"></h3>
											<div class="box-tools pull-right">
											</div>
										</div>
										<div class="items filters-container">
											<div class="box-header with-border placeholder" th:utext="#{list.empty}">
											</div>
										</div>
									</div>
								</div>
							</div>`;
	}


});

export default WizardView;
