/* @flow */

const meta = {};

/* core */
_class('AbstractMetaEntity', 'AbstractEntity');
_class('AbstractDocument', 'AbstractMetaEntity');
_class('AbstractDictionary', 'AbstractMetaEntity');
_class('AbstractReport', 'AbstractMetaEntity');
_class('AbstractEmbedded', 'AbstractEntity');
_class('AbstractTransient', 'AbstractEntity');
_class('AbstractRegister', 'AbstractEntity');

_class('MetaObject', 'AbstractEntity', 'WithConfiguration', 'WithIsDisabled');

_interface('Collection', 'Iterable');
_interface('List', 'Collection');
_interface('Set', 'Collection');
_class('ArrayList', null, 'List');
_class('HashSet', null, 'Set');

/* java.time */
_interface('Temporal', 'TemporalAccessor');
_class('Instant', null, 'Comparable', 'Temporal', 'TemporalAdjuster');
_class('Duration', null, 'Comparable', 'TemporalAmount');
_class('LocalDate', null, 'Comparable', 'Temporal', 'TemporalAdjuster');
_class('LocalDateTime', null, 'Comparable', 'Temporal', 'TemporalAdjuster');
_class('LocalTime', null, 'Comparable', 'Temporal', 'TemporalAdjuster');
_class('MonthDay', null, 'Comparable', 'TemporalAccessor', 'TemporalAdjuster');
_class('Period', null, 'TemporalAmount');
_class('Year', null, 'Comparable', 'Temporal', 'TemporalAdjuster');
_class('YearMonth', null, 'Comparable', 'Temporal', 'TemporalAdjuster');
_class('ZonedDateTime', null, 'Comparable', 'Temporal');
_class('ZoneOffset', 'ZoneId', 'Comparable', 'TemporalAccessor', 'TemporalAdjuster');
_class('Month', null, 'TemporalAccessor', 'TemporalAdjuster');

/* criteria */
_interface('Path', 'Expression');
_interface('Root', 'Path');
_interface('Predicate', 'Expression');



function _class(name: string, superClassName: ?string, ...interfaces: Array<string>): void {
	interfaces = interfaces || [];
	if (superClassName) {
		interfaces.unshift(superClassName);
	}
	meta[name] = interfaces;
}

function _interface(name: string, ...interfaces: Array<string>): void {
	interfaces = interfaces || [];
	meta[name] = interfaces;
}

const kindToClass = {
	'EMBEDDED': 'AbstractEmbedded',
	'REGISTER': 'AbstractRegister',
	'DICTIONARY': 'AbstractDictionary',
	'REPORT': 'AbstractReport',
	'DOCUMENT': 'AbstractDocument',
	'TRANSIENT': 'AbstractTransient'
};

export function typeKindToClassName(kind: string): string {
	return kindToClass[kind];
}

export function isAssignableTo(className: string, superClassOrInterface: string): boolean {
	if (superClassOrInterface === 'Object') {
		return true;
	}
	if (className === superClassOrInterface) {
		return true;
	}
	const parents = meta[className];
	if (parents) {
		for (let i = 0; i < parents.length; ++i) {
			const parent = parents[i];
			if (isAssignableTo(parent, superClassOrInterface)) {
				return true;
			}
		}
	}
	return false;
}

export const getAllSuperTypes = _.memoize(function (typeName: string) {
	const directSuperTypes = meta[typeName] || [];
	const superTypes = _.map(directSuperTypes, superTypeName => {
		return getAllSuperTypes(superTypeName);
	});
	return [].concat.apply(directSuperTypes, superTypes);
});

export function firstCommonType(classNameA, classNameB) {
	const typesA = [classNameA, ...getAllSuperTypes(classNameA)];
	const typesB = [classNameB, ...getAllSuperTypes(classNameB)];
	const commonTypes = _.intersection(typesA, typesB);
	return commonTypes[0];
}