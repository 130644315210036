import PropertyKey from '../../../common/enums/propertyKey.js';
import ViewKind from '../../../common/enums/viewKind'

var rowDescriptions = {}
var descriptions = {}

function propertyDescription(key, renderType, viewKind) {
  let description = descriptions[key]
  if (viewKind && viewKind == ViewKind.ROW && rowDescriptions[key]) {
    description = rowDescriptions[key]
  }
  return description
}

 descriptions[PropertyKey.CSS] = 'property-css'
 descriptions[PropertyKey.SHARED_STYLES] = 'property-shared-styles'
 descriptions[PropertyKey.IDENTIFIER] = 'property-identifier'
 descriptions[PropertyKey.READ_ONLY_VIEW] = 'property-read-only-view'
 descriptions[PropertyKey.CLASSES] = 'property-classes'
 descriptions[PropertyKey.WIDTH] = 'property-width'
 descriptions[PropertyKey.DATA_SOURCE] = 'property-data-source'
 descriptions[PropertyKey.HAS_CREATE_NEW] = 'property-has-create-new'
 descriptions[PropertyKey.HAS_VIEW_LINK] = 'property-has-view-link'
 descriptions[PropertyKey.PLACEHOLDER] = 'property-placeholder'
 descriptions[PropertyKey.DONT_SHOW_BY_DEFAULT] = 'property-show-in-extended-mode-only'
 descriptions[PropertyKey.TYPE] = 'property-type'
 descriptions[PropertyKey.UPLOAD] = 'property-upload'
 descriptions[PropertyKey.PREVIEW] = 'property-preview'
 descriptions[PropertyKey.LINK_ACTION] = 'property-link-action'
 descriptions[PropertyKey.ZOOM] = 'property-zoom'
 descriptions[PropertyKey.NAME] = 'property-name'
// descriptions[PropertyKey.SHOW_HEADER] = 'property-show-header'
 descriptions[PropertyKey.SHOW_TOOLBAR] = 'property-show-toolbar'
 descriptions[PropertyKey.CLIENT_PAGE_TITLE] = 'property-client-page-title'
 descriptions[PropertyKey.CLIENT_PAGE_HEAD_ENTRIES] = 'property-client-page-head-entries'
 descriptions[PropertyKey.CUSTOM_RESOURCES] = 'property-custom-resources'
// descriptions[PropertyKey.READ_ONLY] = 'property-read-only'
 descriptions[PropertyKey.STATIC_RESPONSE] = 'property-static-response'
 descriptions[PropertyKey.USE_STATIC_RESPONSE_FOR_CRAWLER] = 'property-use-static-response-for-crawler'
 descriptions[PropertyKey.USE_STATIC_RESPONSE_FOR_BROWSER] = 'property-use-static-response-for-browser'
 descriptions[PropertyKey.STYLE] = 'property-style'
 descriptions[PropertyKey.BEFORE_CUSTOM_SERVER_EVENT] = 'property-before-custom-server-event'
 descriptions[PropertyKey.CUSTOM_SERVER_EVENT] = 'property-custom-server-event'
 descriptions[PropertyKey.AFTER_CUSTOM_SERVER_EVENT] = 'property-after-custom-server-event'
 descriptions[PropertyKey.LINK] = 'property-link'

 descriptions[PropertyKey.COMMENT] = 'property-comment'
 descriptions[PropertyKey.COMMENT_PLACEMENT] = 'property-comment-placement'
 descriptions[PropertyKey.DISABLED] = 'property-disabled'
 descriptions[PropertyKey.HIDDEN] = 'property-hidden'
 descriptions[PropertyKey.INITIAL_STATE] = 'property-initial-state'
 descriptions[PropertyKey.SOLID] = 'property-solid'
 descriptions[PropertyKey.COLLAPSE] = 'property-collapse'
 descriptions[PropertyKey.MAX_ROW_COUNT] = 'property-max-row-count'
 descriptions[PropertyKey.CAN_ADD] = 'property-can-add'
 descriptions[PropertyKey.CAN_DELETE] = 'property-can-delete'
 descriptions[PropertyKey.CAN_REORDER] = 'property-can-reorder'
 descriptions[PropertyKey.HIDE_CHECKBOX] = 'property-hide-checkbox'
 descriptions[PropertyKey.LINK_FORM_VIEW] = 'property-link-form-view'
 descriptions[PropertyKey.REQUIRED] = 'property-required'
 //descriptions[PropertyKey.HIDE_TITLE_XS] = 'property-hide-title-xs'
 descriptions[PropertyKey.ALIGN] = 'property-align'
 descriptions[PropertyKey.VALIGN] = 'property-valign'
 descriptions[PropertyKey.ICON] = 'property-icon'
 descriptions[PropertyKey.ICON_COLOR] = 'property-icon-color'
 descriptions[PropertyKey.MULTILINE] = 'property-multiline'
 descriptions[PropertyKey.HAS_POST_COLUMN] = 'property-has-post-column'
 descriptions[PropertyKey.HAS_FOLDER_TREE] = 'property-has-folder-tree'
 descriptions[PropertyKey.USE_TREE_ONLY] = 'property-use-tree-only'
 descriptions[PropertyKey.IS_TREE_SELECTABLE] = 'property-is-tree-selectable'
 descriptions[PropertyKey.SHOW_ALL_CHILDREN] = 'property-show-all-children'
 descriptions[PropertyKey.SELECT_WIDTH] = 'property-select-width'

 descriptions[PropertyKey.SEED_INSTANCES] = 'property-seed-instances'
 descriptions[PropertyKey.TRACK_CHANGES_ON_PAGE] = 'property-track-changes-on-page'
 descriptions[PropertyKey.CSS_THEME] = 'property-css-theme'
 descriptions[PropertyKey.CREATE_NEW_VIEW] = 'property-create-new-view'
 descriptions[PropertyKey.OPEN] = 'property-open'
 descriptions[PropertyKey.ROW_HEIGHT] = 'property-row-height'
 descriptions[PropertyKey.SELECTION_TYPE] = 'property-selection-type'
 descriptions[PropertyKey.WIDGET_HEIGHT] = 'property-widget-height'
 descriptions[PropertyKey.COLUMNS_QUANTITY] = 'property-columns-quantity'
 descriptions[PropertyKey.PAGE_SIZE] = 'property-page-size'
 descriptions[PropertyKey.WIDTH_CRITERIA] = 'property-width-criteria'
 descriptions[PropertyKey.DURATION_FORMATTER] = 'property-duration-formatter'
 descriptions[PropertyKey.FORMATTER] = 'property-formatter'
 descriptions[PropertyKey.CLIENT_VIEW] = 'property-client-view'
 descriptions[PropertyKey.LOAD_IN_EDIT_MODE] = 'property-load-in-edit-mode'
 descriptions[PropertyKey.FIELD_TYPE] = 'property-field-type'
 descriptions[PropertyKey.FIELD_NAME] = 'property-field-name'
 descriptions[PropertyKey.HIDE_FILLFUNCTIONS] = 'property-hide-fillfunctions'
 descriptions[PropertyKey.MIN_DATE] = 'property-min-date'
 descriptions[PropertyKey.MAX_DATE] = 'property-max-date'
 descriptions[PropertyKey.ADD_ACTION] = 'property-add-action'
 descriptions[PropertyKey.DROPDOWN_ALIGN] = 'property-dropdown-align'
 descriptions[PropertyKey.HEADER] = 'property-header'
 descriptions[PropertyKey.LABEL] = 'property-label'
 descriptions[PropertyKey.FIELD_LABEL] = 'property-field-label'
 descriptions[PropertyKey.MIN] = 'property-min'
 descriptions[PropertyKey.MAX] = 'property-max'
 descriptions[PropertyKey.PATTERN] = 'property-pattern'
 descriptions[PropertyKey.TEMPLATE] = 'property-template'
 descriptions[PropertyKey.VIEW] = 'property-view'
 descriptions[PropertyKey.TITLE] = 'property-title'
 // description[PropertyKey.TREE_VIEW_KIND] = 'property-tree-view-kind'

export default propertyDescription;
