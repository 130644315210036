import EntityMenu from '../models/entityMenu';
import utils from '../../common/components/utils.js';
import SaveForm from '../../common/views/saveForm.js';
import MultilingualString from '../../common/models/multilingualString.js';
import entityManager from '../../common/components/entityManager'
import Constants from '../../common/models/constants'
import CreateMenu from '../views/createView';
import BaseModel from '../../common/models/baseModel';

const MenuList = SaveForm.extend({

	el: '.menu-list',
	events: {
		'click .remove-menu': 'removeMenu',
		'click li': 'clickMenu'
	},

	initialize: function (o) {
		MenuList.__super__.initialize.apply(this, arguments);
		this.listenTo(this.model, 'change:id', this._idChanged);
		this.listenTo(this.model, 'change', this._nameChanged);
		this.listenTo(this.model, 'add', this._menuAdded);
		this.listenTo(this.model, 'remove', this._menuRemoved);
		this.activeMenu = this.model.first();

		this.modal = o && o.modal;
		this.menuEditor = o && o.menuEditor;
		this.propertiesBar = o && o.propertiesBar;
	},

  navigationUrl (id) {
    let newUrl = String(document.location).split('#')
    newUrl[1] = id
    document.location = newUrl.join('#')
  },

	selectMenu: function (menu) {
		if (!menu) {
			return ;
		}
		const $el = this.$el.find(`li[data-id="${menu.id || menu.cid}"]`);
		if (!$el.hasClass('active')) {
			this.modal.show({
				confirmRequired: () => {
					return this.isMenuChanged();
				},
				resource: 'unstaged.changes',
				buttons: {
					'save': () => this.save(this.menuEditor.getModel()),
					'continue': () => {
						const model = this.menuEditor.getModel();
						if (model.id) {
							model.initialize(app.menus[model.id]);
						} else {
							this.model.remove(model);
						}
					}
				},
				then: () => {
					$('.menu-label').html(menu.getName().toObject());
					this.$el.find('li.active').removeClass('active');
					$el.addClass('active');
					this.menuEditor.setModel(menu);
					this.propertiesBar.setMenu(menu);
				}
			});
		}
	},

	isMenuChanged: function() {
		let menu = this.menuEditor.getModel();
		if (!menu || !this.model.get(menu)) {
			return false;
		}
		var initialMenu = JSON.parse(JSON.stringify(app.menus[menu.id]))
		initialMenu.menuItems.forEach(item => item.modules = _.map(item.modules, (module) => {return {id: module.id};}))
		return !menu.id ||
			!utils.equals(menu.toServerJSON(), initialMenu);
	},

	updateParents: function (data) {
		if (data.menuItems) {
			data.menuItems.forEach(i =>
				i.parent = i.parent && {
					id: i.parent.id,
					clientId: app.clientIds[i.parent.id]
				});
		}
	},

	clickMenu: function (e) {
		e.stopPropagation();
    let id = e.currentTarget.dataset.id
    this.navigationUrl(id)
    this.selectMenu(this.model.get(id))
  },

	save: function (menu, makeDefault) {
		let url = menu.id ?
			app.urls.update(Constants.ID_TYPE_ENTITY_MENU, menu.id) :
			app.urls.create(Constants.ID_TYPE_ENTITY_MENU);
		if (makeDefault) {
			url += '?makeDefault=true'
		}
		return utils.postRequest(menu.toServerJSON(), url)
			.then(data => {
				this.saveSuccess();
				menu.update(data);
				this.updateParents(data);
				this.menuEditor.undoManager.clear();
				app.menus[data.id] = data;
				this.navigationUrl(data.id)
				if (makeDefault) {
					this.updateDefaltMenu(menu)
				}
				//this.menuEditor.render();
			})
			.catch(e => {
				this.saveError(e);
			});
	},

	updateDefaltMenu: function (model) {
		app.menuId = model.id;
		const $li = this.$el.find(`li[data-id="${model.id || model.cid}"]`);
		$li.addClass('default-menu-link');
		$li.find('.remove-menu').remove();
	},

	removeMenu: function (e) {
		e.stopPropagation();
		const $el = $(e.currentTarget).parent();
		let model = this.model.get($el.attr('data-id'));
		if (model.id) {
			utils.confirmDelete([model.id], app.urls.dependencies(Constants.ID_TYPE_ENTITY_MENU), app.urls.delete(Constants.ID_TYPE_ENTITY_MENU),
				{ beforeDelete: 'are.you.sure' },
				() => {
					this.menuEditor.setModel(this.model.models[0]);
					this.model.remove(model);
				});
			} else {
				this.modal.show({
					resource: 'are.you.sure',
					buttons: {
						'delete': () => {
							let model = this.model.get($el.attr('data-id'));
							this.menuEditor.setModel(this.model.models[0]);
							this.model.remove(model);
						},
						'cancel': () => {}
					}
				});
			}
    this.navigationUrl($el.prev().attr('data-id'))
	},

	createNewMenu: function () {
		const menu =  new EntityMenu();
		this.model.add(menu);
		entityManager.fetchResourceByKey(this.model.size() > 1 ? 'untitled' : 'default')
			.then(resource => this.model.get(menu.cid).set('name', resource.get('value').clone()))
			.then(() => this.selectMenu(menu));
	},

	_nameChanged: function (model) {
		const $li = this.$el.find(`li[data-id="${model.id || model.cid}"]`);
		if ($li.hasClass('active')) {
			$('.menu-label').html(model.getName().toObject());
		}
		$li.children('span.translatable').remove();
		$li.prepend(model.getName().toObject());
	},

	_idChanged: function (model) {
		this.$el.find(`li[data-id="${model.cid}"]`).attr('data-id', model.id);
	},

	_menuAdded: function (menu) {
		const $li = $('<li class="menu-link"/>');
		$li.attr('data-id', menu.id || menu.cid);
		if (menu.id == app.menuId) {
			$li.addClass('default-menu-link');
		}
		if (menu.getName()) {
			$li.append(menu.getName().toObject());
		}
		if (menu.id != app.menuId && this.model.size() > 1) {
			$li.append($(`
				<button class="btn btn-link btn-sm remove-menu" data-id="${menu.id || menu.cid}">
					<span class="glyphicon glyphicon-trash"></span>
				</button>
				`));
		}
		$li.appendTo(this.$el);
		$('.no-default-menu').remove();
	},

	_menuRemoved: function (model) {
		let $el = this.$el.find(`[data-id="${model.id || model.cid}"]`);
		if ($el.hasClass('active')) {
			this.selectMenu(this.model.get($el.prev().attr('data-id')));
		}
		$el.remove();
	},

	buildMenuList: function () {
		if (!this.model.size()) {
			$('.no-default-menu').removeClass('hidden');
		} else {
			this.model.models
				.sort((a,b) => {
					if (a.id && b.id) {
						return a.id - b.id;
					}
					if (a.id || b.id) {
						return a.id ? -1 : 1;
					}
					return a.cid == b.cid ? 0 : a.cid < b.cid ? -1 : 1;
				})
				.forEach(m => this._menuAdded(m));
		}
	},

	render: function () {
		this.buildMenuList();

		let link = window.location.href
		let id = link.split("#")[1]
		if (!this.model.get(id)) {
			window.history.replaceState({}, document.title, link.replace(`#${id}`, ''))
			id = app.menuId
		}
		let model = this.model.get(id) || this.model.first();
		this.selectMenu(model);
	}
});

export function createNewMenu() {
	const newModel = new BaseModel();
	newModel.set('name', new MultilingualString());
	newModel.set('menuItems', [])
	app.createModal.show({
		url: app.urls.create(Constants.ID_TYPE_ENTITY_MENU),
		headerResource: 'add.menu',
		model: newModel,
		afterCreateOnSuccess: (data) => {
			console.log(data);
			utils.redirectTo(app.urls.open(Constants.ID_TYPE_ENTITY_MENU, data.id));
		},
		createView: CreateMenu
	});
}

export default MenuList;
