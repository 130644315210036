import FieldViewOptions from './fieldViewOptions';

export default class ViewContext extends Backbone.Model {

	initialize(options) {
		super.initialize(options);
		if (!this.get('fieldsOptions')) {
			this.set('fieldsOptions', new Backbone.Model);
		}
		if (!(this.get('fieldsOptions') instanceof Backbone.Model)) {
			this.set('fieldsOptions', new Backbone.Model(this.get('fieldsOptions')));
		}
	}

	getOptionsForField(field) {
		var fid = _.isNumber(field) || _.isString(field) ? field : field.id;
		if (!this.get('fieldsOptions').get(fid)) {
			this.get('fieldsOptions').set(fid, new FieldViewOptions());
		}
		if (!(this.get('fieldsOptions').get(fid) instanceof FieldViewOptions)) {
			this.get('fieldsOptions').set(fid,
				new FieldViewOptions(this.get('fieldsOptions').get(fid)));
		}
		return this.get('fieldsOptions').get(fid);
	}

	merge(json) {
		for (let prop in json.fieldsOptions) {
			const option = this.getOptionsForField(prop);
			const newValue = json.fieldsOptions[prop];
			if (!_.isEqual(option.getOrder(), newValue.order)) {
				option.setOrder(newValue.order);
			}
			if (!_.isEqual(option.getFilters(), newValue.filters)) {
				option.setFilters(newValue.filters);
			}
			if (!_.isEqual(option.getIsExtendMode(), newValue.isExtendMode)) {
				option.setIsExtendMode(newValue.isExtendMode);
			}
		}
		for (let prop in this.get('fieldsOptions').attributes) {
			if (!json.fieldsOptions.hasOwnProperty(prop)) {
				this.get('fieldsOptions').get(prop).setFilters([]);
				this.get('fieldsOptions').get(prop).setOrder(null);
				// this.get('fieldsOptions').get(prop).setIsExtendMode(false);
			}
		}
	}

	toJSON() {
		return {
			fieldsOptions: _.pick(
				_.mapObject(
					this.get('fieldsOptions').attributes,
					v => v.toServerJSON ? v.toServerJSON() : v.toJSON()
				),
				v => v.order || (v.filters && v.filters.length))
		};
	}

	setFilters(field, filters) {
		this.getOptionsForField(field).setFilters(filters);
	}

	getFilters(field) {
		return this.getOptionsForField(field).getFilters();
	}

	setOrder(field, order) {
		this.getOptionsForField(field).setOrder(order);
	}

	getOrder(field) {
		return this.getOptionsForField(field).getOrder();
	}

	setIsExtendMode(field, isExtendMode) {
		this.getOptionsForField(field).setIsExtendMode(isExtendMode);
	}

	getIsExtendMode(field) {
		return this.getOptionsForField(field).getIsExtendMode();
	}

}