/* @flow */

import type MetaObject from '../models/metaObject';
import Block from '../models/block'
import { multilingualStringService } from '../service/multilingualStringService';
import MultilingualString from '../models/multilingualString';
import BlockKind from '../enums/blockKind';
import BlockType from '../enums/blockType';
import BlockTrigger from '../enums/blockTrigger';
import entityManager from './entityManager';

export function scale(value: ?number, scale: number): ?number {
	var result = null;
	if (value != null) {
		result = value * Math.pow(10, scale);
	}
	return result;
}

export function unscale(value: ?number, scale: number): ?number {
	if (value != null) {
		return value / Math.pow(10, scale);
	}

	return null;
}

export function round(value: number): number {
	var res = Math.round(value);
	if (value < 0 && res - value === 0.5) {
		--res;
	}
	return res;
}

//TODO: improve type annotations
export function getId(entity: ?Object): ?string {
	if (entity) {
		if (entity.id) {
			return entity.id;
		}
	}
	return null;
}

export function getIdOrCid(entity: ?Object): ?string {
	if (entity) {
		if (entity.id) {
			return entity.id;
		}
		return entity.cid;
	}
	return null;
}

export function getObjectType(metaObject: ?MetaObject): ?Object {
	if (metaObject) {
		return metaObject.get('objectType');
	}
	return null;
}

export function clone(item: ?Object): ?Object {
	if (!item) {
		return null;
	}
	return item.clone();
}

export function format(inputString: MultilingualString, ...args): MultilingualString {
	return multilingualStringService.format(inputString, args);
}

export function getViewEventBlock(viewId: string, trigger: string, type: string): Block {
	let block = new Block();
	let name = 'on' + trigger[0] + trigger.substring(1).toLowerCase();
	block.set('name', name);
	block.set('blockKind', BlockKind.ACTION);
	block.set('blockType', type);
	block.set('viewId', viewId);
	block.set('trigger', trigger);
	block.set('className', 'AbstractEntity');
	return block;
}

export function getFieldEventBlock(eventTriggerFieldId: string, eventTriggerSubFieldId: string, trigger: string, type: string): Block {
	let block = new Block();
	let name = 'on' + trigger[0] + trigger.substring(1).toLowerCase();
	block.set('name', name);
	block.set('blockKind', BlockKind.ACTION);
	block.set('blockType', type);
	block.set('eventTriggerFieldId', eventTriggerFieldId);
	block.set('eventTriggerSubFieldId', eventTriggerSubFieldId);
	block.set('trigger', trigger);
	block.set('className', 'AbstractEntity');
	return block;
}

export function equals(object1, object2) {
	if (object1 === object2) {
		return true;
	}
	if (object1 == null || object2 == null) {
		return false;
	}
	return object1.equals(object2);
}

export function toReference(item) {
	if (item == null) {
		return null;
	}
	return item.toReference();
}

export function getServerCustomEventFunction(blockId) {
	return function (item) {
		if (this.model != item) {
			app.notificationManager.addErrorAndThrow(
				'Server custom action can only be called with ' +
				'the currently displayed instance as an argument.');
		}
		return entityManager.invokeServerCustomEventFunction(blockId, this);
	};
}
