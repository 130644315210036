import IndexTable from '../components/indexTable';
import FoldersTree from '../components/foldersTree';
import utils, { getListPropValue, removeNotActiveLanguages } from '../../common/components/utils'
import StateRecovery from '../../common/components/stateRecovery';
import LocalStorageKeysService from '../../common/service/localStorageKeysService'
import PropertyKey from '../../common/enums/propertyKey'
import PropertiesCollection from '../../EntityView/models/properties/propertiesCollection'

var IndexView = Backbone.View.extend({

	events: {
		'click .folders-switcher': 'doToggleFolders'
	},

	initialize: function (options) {
		this.setElement(options.el)
		var that = this;
		this.total_width = this.$el.width();
		this.prevRatio = 2;
		this.listProperties = options.listProperties
		options.treeViewKind = getListPropValue(this.listProperties, PropertyKey.TREE_VIEW_KIND)
		this.promiseOnTable = new Promise((resolve, reject) => {
			options.resolveFunc = () => resolve()
			that.table = new IndexTable({
				el: that.$('.table-index-table')[0],
				isDocuments: options.isDocuments,
				hasMetaObject: options.hasMetaObject,
				treeViewKind: options.treeViewKind,
				hideCheckbox: options.hideCheckbox || getListPropValue(that.listProperties, PropertyKey.HIDE_CHECKBOX),
				hideFillFunctions: options.hideFillFunctions || getListPropValue(that.listProperties, PropertyKey.HIDE_FILLFUNCTIONS),
				typeId: options.typeId,
				viewId: options.viewId || getListPropValue(that.listProperties, PropertyKey.VIEW),
				context: options.context,
				onConfirmSelect: options.onConfirmSelect,
				listProperties: that.listProperties,
				resolveFunc: options.resolveFunc
			})
		})

		this.promiseOnTable.then(() => {
			this.initialFilters = options.filters;
			this.selected = options.selected;
			this.typeId = options.typeId
			if (this.$('.folders-tree').length) {
				this.folders = new FoldersTree({
					el: this.$('.folders-tree'),
					captionEl: this.$('.caption'),
					table: this.table.foldersTreeStruct,
					addInstance: () => this.table.addInstance(),
					showInstance: (typeId, rowId) => this.table.showInstance(typeId, rowId),
					treeViewKind: options.treeViewKind,
					typeId: options.typeId,
					addNewButton: $('table_index_table_add'),
					addNewInContextMenu: true,
					dndEnabled: true,
					statefull: true,
					showRoot: true,
					loadAllInstances: getListPropValue(this.listProperties, PropertyKey.SHOW_ALL_CHILDREN) ? (reloadTable) => { this.table.loadAllInstances(reloadTable) } : null,
					isTreeSelectable: this.listProperties ? getListPropValue(this.listProperties, PropertyKey.IS_TREE_SELECTABLE) : false,
					updateTableSelection: getListPropValue(this.listProperties, PropertyKey.IS_TREE_SELECTABLE) ? () => this.table.updateTableSelection() : null
				});
			}
			this.table.setTree(this.folders);
			this.foldersClosed = StateRecovery.get(LocalStorageKeysService.buildKeyForFoldersTree(this.typeId));
			if (this.foldersClosed) { // opened by default, must close
				this.toggleFoldersNoAnimation();
			}
			this.$('.folders-col').resizable({
				helper: 'js-tree-resizable-helper',
				animate: false,
				handles: 'e',
				stop: function( event, ui ) {
					let total = $('.content-wrapper').width();
					let w = ui.size.width + 50;
					if (w - 0.5 > total){
						w = total - 0.5;
					}
					if (w < total / 12 + 0.5){
						w = total / 12 + 0.5;
					}
					let ratio = Math.floor(12*w/total);
					that.applyRatio(ratio);
				},
				resize: function(e, ui){
					ui.size.height = Math.round( ui.size.height / 30 ) * 30;
				}
			});
			this.themeUrl = options.context && options.context.themeUrl;
			this.updateTheme();
			options.context.$el.find('[data-server_event="LIST_LINK"]').click(function () {
				const tab = app.cjTabs.activeTab
				if (tab.parent) {
					tab.close()
				} else {
					if (app.builderMode){
						utils.redirectTo(app.urls.entityTypeDetails(app.typeId, app.configuration.id, 'ENTITY_TYPE'))
					} else {
						utils.redirectTo('/')
					}
				}
			});
			this.folders && this.folders.render()
		})
	},

	async initializeAsyncComponents () {
		await this.table.initializeTableAsync()
		if (this.initialFilters) {
			this.table.filter(this.initialFilters, true);
		}
		if (this.selected) {
			this.selected.forEach(x => {
				this.table.newTable.checkOne({item: x, value: true});
			});
		}
	},

	applyRatio: function(ratio){
		this.$('.folders-col').css('width', '');
		this.$('.folders-col').removeClass('col-md-' + this.prevRatio).addClass('col-md-' + ratio);
		this.$('.table-col').removeClass('col-md-' + (12-this.prevRatio)).addClass('col-md-' + (12-ratio));
		this.prevRatio = ratio;
	},

	doToggleFolders: function () {
		this.toggleFolders();
	},

	updateTheme: function () {
		let previousUrl = $('#cssTheme').attr('href')
		if (this.themeUrl !== previousUrl) {
			if (this.themeUrl) {
				$('#cssTheme').attr('href', this.themeUrl);
			} else {
				$('#cssTheme').removeAttr('href');
			}
		}
	},

	toggleFolders: function (invisible) {
		this.$('.folders-switcher .folder-icon').toggleClass('fa-folder fa-folder-o');
		this.$('.folders-col').toggleClass('col-0 col-md-' + this.prevRatio);
		this.$('.table-col').toggleClass('col-md-12 col-md-' + (12 - this.prevRatio));
		if (!invisible) {
			this.foldersClosed = !this.foldersClosed;
			StateRecovery.put(LocalStorageKeysService.buildKeyForFoldersTree(this.typeId), this.foldersClosed);
		}
	},

	toggleFoldersNoAnimation: function () {
		this.$('.folders-col').toggleClass('slide-content no-slide-content');
		this.$('.table-col').toggleClass('slide-content no-slide-content');
		this.toggleFolders(true);
		this.$('.folders-col').toggleClass('slide-content no-slide-content');
		this.$('.table-col').toggleClass('slide-content no-slide-content');

	},

	render () {
		this.table.render();
		this.folders && this.folders.render();
		removeNotActiveLanguages(this.$el);
	},
});

export default IndexView;
