/* @flow */

import utils from '../../common/components/utils.js';
import BaseModel from '../models/baseModel';
import MultilingualInputField from './multilingualInputField.js';
import MultilingualString from '../models/multilingualString';

export default class EditModal extends Backbone.View {

	initialize () {
		this.setElement($('#edit-modal'));
		$('#edit-modal').on('shown.bs.modal', () => {
			$('#edit-modal').find('input[data-field]').first().focus();
		});
	}

	show (options: Object) {
		if (options.confirmRequired === undefined ||
			options.confirmRequired === true ||
				(_.isFunction(options.confirmRequired) && options.confirmRequired())) {

			this.model = options.model || new BaseModel({ name: { translations: {} } });

			this.$el.find('.modal-body').empty();
			this.viewModel = new options.view({
					model: this.model,
					$el: this.$el.find('.modal-body'),
					html: options.html
				});
			this.url = options.url;
			this.afterUpdateOnSuccess = options.afterUpdateOnSuccess;
			const resource = app.getResource(options.headerResource);
			if (!options.preventEditOnEnter) {
				this.initializeEnterPressingEvent();
			}
			this.$el.find('.modal-header h4').html(resource);
			this.$el.find('.modal-header h4')
				.append(`<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button>`);
			this.$el.find('.updateButton')
				.off('click')
				.on('click', e => {
					this.onUpdateClick();
					e.stopPropagation();
				});
				this.$el.modal('show');
		} else {
			if (options.then) {
				options.then();
			}
		}
	}

	onUpdateClick() {
		_.each(this.viewModel.inputs, (input) => {
			input.prepareToSave && input.prepareToSave();
		});
		let data = this.prepareData();
		utils.postRequest(data, this.url)
			.then((response) => {
				if (this.afterUpdateOnSuccess) {
					this.afterUpdateOnSuccess(data, response);
				}
				this.$el.find('select').select2('close');
				this.$el.modal('hide');
				this.removeHandler();
			}).catch(e => {
				if (e.status === 403) {
					this.$el.modal('hide');
				}
				throw e;
			});
	}

	prepareData(): Object {
		let data = this.viewModel.getCurrentConfiguration()
		if (data.name && data.name.toJSON) {
			data.name = data.name.toJSON()
		}
		return data;
	}

	createFunc(event) {
		var keycode = (event.keyCode ? event.keyCode : event.which);
		if(keycode == '13' && !event.data.$el.find('.btn[data-action="cancel"]').is(':focus')) {
			event.data.onUpdateClick();
		}
	}

	removeHandler() {
		$(document).off("keyup", this.createFunc);
	}

	initializeEnterPressingEvent() {
		$(document).on("keyup", this, this.createFunc);
	}
}
