/* @flow */
import utils from '../../common/components/utils.js';
import BaseModel from '../../common/models/baseModel';
import Duration from '../../time/models/duration';
import Instant from '../../time/models/instant';
import Formatter from '../components/formatter';
import DeploymentOptionsModal from '../../deploy/views/deploymentOptionsModal';
import MultilingualString from '../../common/models/multilingualString.js';
import AppUpdateMode from '../../common/enums/appUpdateMode';
import { multilingualStringService } from '../../common/service/multilingualStringService';
import { timeSynchronizer } from '../../time/utils';
import FormatterInfo from '../service/formatterInfo'
import DeploymentForbiddanceReason from '../../common/enums/deploymentForbiddanceReason'
import { buildDefaultPopover } from '../components/utils'
import { translate } from '../service/stringResourceService.js';
import ConfirmModal from '../components/confirmModal';
import { showConfirmModal } from '../../common/components/confirmModalVue'
import ApplicationType from '../../common/enums/applicationType';
import DefaultApplicationModal from "../../deploy/views/defaultApplicationModal";
import {getBodyInfo} from "../../deploy/utils";
import ProposeToDeployModal from '../../deploy/views/proposeToDeployModal'
import EmptyProjectModal from "./emptyProjectModal";
import EventKey from "../enums/EventKey";
import {userPilotByDocsResource} from "../../help/docsResource";

export default class BuildView extends Backbone.View {

		constructor() {
				super({
						el: '#buildView',
						events: {
								'click #nopublish': '_noPublish',
								'click #build': '_build',
								'click #publish': '_publish',
								'show.bs.popover #build-container': '_onShowBuildPopover',
								'click.bs.dropdown #deploy': '_onGetDeployLinks',
								'click.bs.dropdown #open': '_onGetOpenItemLinks',
								'click #deploy': '_onDeployPopup',
								'click #open': '_onOpenPopup',
								'click .open-item-link': '_onOpen',
								'click .get-open-item-info': '_onGetOpenItemInfo',
								'click #rebuild': 'rebuild',
								'click .defaultApp': 'chooseDefaultAppToPublish',
						}
				});
		}

		proposeToOpenModal() {
			var url = window.location.protocol + '//'
			utils.getRequest(app.urls.getApps)
				.then((deployList) => {
					url = url + deployList.filter(app => app.deployedAt).sort((a, b) => b.deployedAt.seconds - a.deployedAt.seconds)[0].domains[0] + "/" + app.currentLanguage
					showConfirmModal({
						title: translate('app') + ' ' + ' ' + translate('has.been.published'),
						text: translate('proposal.to.open.message'),
						confirmButtonText: translate('open'),
						onConfirm: () => {
							window.open(url)
						}
					})
					$('.main-modal .cancel').addClass('pull-left').css('margin-left', '0')
				})
		}

		_onPublish (e, ignore) {
			if ($(e.target).hasClass('caret')) {
				return;
			}
			if (!ignore && app.emptyProject) {
				new EmptyProjectModal().show();
				return;
			}
			this.showAnimation()
			new Promise((resolve, reject) => {
				let defaultAppToPublish = JSON.parse(localStorage.getItem('defaultAppToPublish_' + app.configuration.id))
				if (defaultAppToPublish) {
					resolve(defaultAppToPublish);
				} else {
					defaultAppToPublish = this._getPublishDeployInstance();
					if (!defaultAppToPublish) {
						reject();
					} else {
						resolve(defaultAppToPublish);
					}
				}
			}).then((defaultAppToPublish) => {
				this.buildBtn.attr('disabled', true);
				this.publishBtn.attr('disabled', true);
				this.publishBtn.addClass('animated');
				this.publishBtn.attr('disabled', 'disabled');
				this.showProgressBarTooltip();

				utils.postRequest({}, app.urls.publish(defaultAppToPublish.instanceId, defaultAppToPublish.applicationType == ApplicationType.DEVELOPMENT))
					.then((appId) => {
						this.hideAnimation()
					})
					.catch(e => {
						console.log(e);
						this.hideAnimation()
					});
				e.preventDefault && e.preventDefault()
			}).catch(() => {
				app.notificationManager.addError(translate('deploy.forbiddance.reason.application.is.not.active'));
				this.hideAnimation()
			});
		}

		_noPublish(){
			$(".publish-mode-dependent ").removeClass('publish-mode')
			utils.postRequest({},app.urls.usePublish(false)).catch(e => console.log(e))
		}

		_build(e, devMode) {
			this._checkBeforePublishOrBuild(e, e => this._onBuild(e, devMode))
		}

		_publish(e) {
			if (!this._getPublishDeployInstance()) return
			this._checkBeforePublishOrBuild(e, this._onPublish)
		}

		runDeploy(resultModel) {
			const updateMode = resultModel.get('applicationUpdateMode')
			const instancesToMerge = resultModel.get('instancesToMerge')
			const deployData = (instancesToMerge ? instancesToMerge : [])

			const jarVersion = resultModel.get('jarVersion')

			const appId = resultModel.get('appId')
			const isRemote = resultModel.get('isRemote')
			const overrideConfiguration = resultModel.get('overrideConfiguration')
			const urlProvider = app.urls[isRemote ? 'downloadApp' : 'deploy']
			const url = urlProvider(appId, updateMode, (jarVersion != null && jarVersion != -1) ? jarVersion : null, overrideConfiguration)

			const method = isRemote ? this.downloadApp : this.deploy
			return method(deployData, url)
		}

		_onBuild(e, devMode) {
			if (app.emptyProject) {
				new EmptyProjectModal().show();
				return;
			}
			this.buildBtn.attr('disabled', true);
			this.showProgressBarTooltip();
			utils.postRequest({}, app.urls.build(devMode)).then(() => {
				if (!localStorage.getItem('dontShowProposeDeployModal')) {
					let proposeModal = new ProposeToDeployModal()
					proposeModal.show({

						deployModal: this.deployList.length != 1 ? () => { this._onDeploy.call(this) } : null,
						deploy: this.deployList.length == 1 ? () => {
							let optModel = new BaseModel({
								applicationUpdateMode: AppUpdateMode.ALL,
								jarVersion: -1,
								appId: this.deployList[0].instanceId,
								isRemote: ApplicationType.isRemote(this.deployList[0].applicationType),
								overrideConfiguration: false
							})
							this.runDeploy(optModel)
						} : null

					})
				}
			}).catch(e => console.log(e));
		}

		_checkBeforePublishOrBuild(e, action){
			if (app.utils.preventPageLeave.predicate && app.utils.preventPageLeave.predicate()) {
				var modal = new ConfirmModal()
				modal.show({
					resource: 'unstaged.changes',
					hideHeader: true,
					buttons: {
						'save': (e) => {
							app.utils.preventPageLeave.save(e)
						},
						'continue': () => {}
					},
					then: () => {
						app.tasksQueue.add(() => action.call(this, e));
				}})
			}
			else {
				action.call(this, e)
			}
		}

		_onShowBuildPopover(el) {
			// Refresh time info
			this._renderBuild();
		}

		_onGetDeployLinks(el) {
			this._hideAllInfoBlocks('deploy-info');
		}

		_onGetOpenItemLinks(el) {
			this._hideAllInfoBlocks('open-item-info');
		}

		_hideAllInfoBlocks(cssClass) {
			$('.' + cssClass).parent().find('.get-' + cssClass).each( (index, el) => {
				this._hideInfoBlock($(el), cssClass);
			});
		}

		_onDeployPopup(el) {
			this._onDeploy();
		}

		_onOpenPopup(el) {
			this._updateDeployList();
		}

		_onDeploy(el) {
				// Define app update mode
				const deploymentOptions = new DeploymentOptionsModal();
				const model = new BaseModel({
					applicationUpdateMode: AppUpdateMode.READ_ONLY
				});
				const headerResource = app.getResource('deploy');
				this.showProgressBarTooltip();
				deploymentOptions.show({
						 headerResource: headerResource,
						 okResource: 'deploy',
						 model: model,
						 onSubmit: (resultModel) => {
							 // Post deploy request
							 this.runDeploy(resultModel)
						 }
				 });
		}

		deploy(deployData, deployUrl) {
			return utils.postRequest(deployData, deployUrl);
		}

		downloadApp(data, url) {
			utils.downloadBlobByUrl(url, data);
		}

		_onOpen(el) {
				const link = el.currentTarget.attributes.link;
				if (link) {
					window.open(link.value);
				}
				el.preventDefault();
		}

		_onGetDeployInfo(ev) {
			this._toggleInfoBlock(ev, 'deploy-info');
		}

		_onGetOpenItemInfo(ev) {
			this._toggleInfoBlock(ev, 'open-item-info');
		}

		_toggleInfoBlock(ev, cssClass) {
			// Hide the shown item and show the clicked one if it is initially hidden
			const $target = $(ev.currentTarget);
			const isVisible = $target.parent().parent().find('.' + cssClass).is(':visible');
			$('.' + cssClass + ':visible').parent().find('.get-' + cssClass).each( (index, el) => {
				this._hideInfoBlock($(el), cssClass);
			});
			if (!isVisible) {
				this._showInfoBlock($target, cssClass);
			}
			ev.preventDefault();
			ev.stopPropagation();
		}

		_showInfoBlock($containerEl, cssClass) {
			const class1 = 'fa-chevron-down';
			const class2 = 'fa-chevron-up';
			$containerEl.find('.' + class1).removeClass(class1).addClass(class2);
			$containerEl.parent().parent().find('.' + cssClass).show();
		}

		_hideInfoBlock($containerEl, cssClass) {
			const class1 = 'fa-chevron-down';
			const class2 = 'fa-chevron-up';
			$containerEl.find('.' + class2).removeClass(class2).addClass(class1);
			$containerEl.parent().parent().find('.' + cssClass).hide();
		}

		initialize() {
				this.buildBtn = this.$el.find('#build');
				this.deployBtn = this.$el.find('#deploy');
				this.publishBtn = this.$el.find('#publish');
				this.noPublishBtn = this.$el.find('#nopublish');
				this.openBtn = this.$el.find('#open')

				// this._addBuildTooltip();
				this._addPublishTooltip()
				this._addNoPublishTooltip()
				this.listReady = utils.deferedPromise();
				window.addEventListener('click', (e) => {
					if (e.target.classList){
						if (e.target.classList.contains('no-publish')) {
							this.publishBtn.popover('hide')
							this._noPublish()
						}
						if (e.target.classList.contains('defaultApp')) {
							this.publishBtn.parent().popover('hide')
							this.chooseDefaultAppToPublish();
						}

					}
				})
				this.publishBtn.attr('disabled', 'disabled');
				this.$el.find('.use-publish-button .dropdown').on('show.bs.dropdown', (e => {
					$('body').addClass('hide-tooltip');
					})
				);
				this.$el.find('.use-publish-button .dropdown').on('hide.bs.dropdown', (e => {
						$('body').removeClass('hide-tooltip');
					})
				);
		}

		_addBuildTooltip() {
				buildDefaultPopover(
					this.buildBtn.parent(),
					{ container: 'body',
					content: app.getResource('no.active.build'),
					placement: this.getTooltipPlacement,
					html: true }
				);
		}

		_addPublishTooltip() {
				buildDefaultPopover(
					this.publishBtn,
					{ container: 'body',
					content: translate('publish.explanation'),
					placement: this.getTooltipPlacement,
					html: true }
				)
			// $(this.publishBtn).popover('update');
		}

		_addNoPublishTooltip() {
				buildDefaultPopover(
					this.noPublishBtn,
					{ container: 'body',
					content: translate('press.to.use.build.deploy'),
					placement: this.getTooltipPlacement,
					html: true }
				)
		}

		getTooltipPlacement() {
			return $('body').hasClass('tutorial-opened') ? 'right' : 'bottom';
		}

		message(status){
			this.render(status)
		}

		hideNeedToRebuildNotification() {
			$('#publish').removeClass('need-to-rebuild');
			app.needToRebuild = false;
			this.checkIfShowOpenBtn()
		}

		showNeedToRebuildNotification() {
			$('#publish').addClass('need-to-rebuild');
			app.needToRebuild = true;
		}

		checkIfShowOpenBtn() {
			if (this.deployList.length == 1
				&& this.deployList[0].applicationType == ApplicationType.DEVELOPMENT
				&& !this.deployList[0].deploymentState) {
				this.openBtn.hide()
			} else {
				this.openBtn.show()
			}
		}

		render(status) {
				if (!app.builderMode){
					return;
				}
				const renderBuild = (status.last !== undefined);
				this._updateDeployList().then(() => {
					let instance = this._getPublishDeployInstance();
					if (renderBuild) {
							this._renderBuild(status)
							if (instance){
								this.publishBtn.removeClass('disabled')
							} else {
								this.publishBtn.addClass('disabled')
						}
					} else {
							this._renderDeploy(status)
					}
					if (instance.deploymentForbiddanceReason !== DeploymentForbiddanceReason.NONE || (
						instance.deploymentForbiddanceReason === DeploymentForbiddanceReason.APPLICATION_IS_NOT_ACTIVE &&
						instance.applicationType !== ApplicationType.DEVELOPMENT)) {
						this.publishBtn.addClass('disabled')
					}
				})
		}

		_renderDeploy(status) {
				if (status.isRunning) {
						this._runningDeploy();
				} else {
						this._stoppedDeploy();
						this.checkIfShowOpenBtn()
				}
		}

		_updateDeployList () {
			return utils.getRequest(app.urls.getApps)
			.then((data) => {
				this.deployList = data;
				this._renderOpenServers();
				this.listReady.resolve()
			});
		}

		_renderDeploymentForbiddanceReason(appInstance) {
			if (appInstance.deploymentForbiddanceReason  != DeploymentForbiddanceReason.NONE) {
				return `<br><span class='deploy-forbiddance-status'>
						${app.getResource('is.deployment.enabled')}: <strong style="color: red">${app.getResource('no')}</strong>
					</span><br/>
					<span class='deploy-forbiddance-reason'>
						${app.getResource('deploy.forbiddance.reason.' + appInstance.deploymentForbiddanceReason.toLowerCase().replace(/_/g,'.'))}
						${appInstance.deploymentForbiddanceReason == DeploymentForbiddanceReason.CONTAINER_IS_NOT_ASSIGNED
							? multilingualStringService.formatSystemString(app.getResource('deploy.forbiddance.solution.text.assign.container', true), [app.urls.dashboardContainerManagement])
							: multilingualStringService.formatSystemString(app.getResource('deploy.forbiddance.solution.text', true), [app.urls.dashboard]) }
						<br/>
					</span>`;
			} else {
				return '';
			}
		}
		_renderOpenServers() {
				let servers = this.$el.find('.servers-open-list')
				servers[0].innerHTML=''
				_.each(this.deployList, (instance, index) => {
					if (!ApplicationType.isRemote(instance.applicationType)) {
						let time = Instant.fromJSON(instance.deployedAt);
						if (time){
							time = this._formatDuration(time);
						}
						let openItem = $(`
						<li>
								<div class="open-item">
									<a class="open-item-link" href="#"
										${instance.isAppResponding ? 'link="' + window.location.protocol + '//' + instance.domains[0] + '"': ''}>
											${_.escape(instance.title)}
									</a>
									<a class="get-open-item-info" href="#">
										<span class="fa fa-chevron-down"></span>
									</a>
								</div>
								<span class="open-item-info" style="display:none;">
									${getBodyInfo(instance)}
								</span>
								${this._renderServerAliases(instance)}
						</li>`)
						if (instance.deploymentState == null){
						buildDefaultPopover(
							openItem,
							{
								container: 'body',
								content: app.getResource('never.deployed'),
								placement: 'right',
								html: true
							})
						}
						servers.append(openItem)
					}
				});
		}

		showProgressBarTooltip() {
			if (app.currentUser && !app.events.filter(event => event.eventKey === EventKey.USER_PILOT_SHOW_PROGRESS_BAR).length) {
				utils.postRequest({
					eventKey: EventKey.USER_PILOT_SHOW_PROGRESS_BAR
				}, app.urls.addEvent).then(() => {
					app.events.push({
						eventKey: EventKey.USER_PILOT_SHOW_PROGRESS_BAR
					});
				});
				userpilot && userpilot.trigger(userPilotByDocsResource("progress-bar"));
			}
		}

		_renderServerAliases(instance) {
			if (instance.domains.length <= 1) {
				return '';
			}
			let aliases = `${app.getResource('aliases')}:<br/>`;
			_.each(instance.domains, (domain, index) => {
				if (index > 0) {
					aliases += `<a href='${window.location.protocol + '//' + domain}'>${_.escape(domain)}</a><br/>`;
				}
			});
			aliases = `<span class="open-item-info open-item-info-aliases" style="display:none;">${aliases}</span>`
			return aliases;
		}

		_stoppedDeploy() {
			if (!this.isBuilding) {
				this.deployBtn.removeClass('animated');
				this.publishBtn.removeClass('animated')
				this.deployBtn.removeClass('disabled');
				this.buildBtn.removeAttr('disabled');
				this.publishBtn.removeAttr('disabled');
				this.hideAnimation()
				this.isDeploying = false;
			}
		}

		_runningDeploy() {
				this.deployBtn.addClass('animated');
				this.publishBtn.addClass('animated');
				this.publishBtn.attr('disabled', 'disabled');
				this.deployBtn.toggleClass('disabled', true);
				this.buildBtn.attr('disabled', true);
				this.showAnimation()
				this.isDeploying = true;
		}

		_renderBuild(status) {
				if (status) {
					this.build = status;
				}
				if (!this.build) {
					return;
				}
				status = this.build;
				if (status.last && status.last.isRunning) {
						this._runningBuild();
				} else {
						this._stoppedBuild();
						if (!status.last) {
								// this._noPreparedBuild();
								this._buildSuccess();
						} else if (status.last.isSuccessful) {
								this._buildSuccess();
						} else {
								this._buildError(status);
						}
				}
		}

		_stoppedBuild() {
			if (!this.isDeploying) {
				this.buildBtn.removeClass('animated');
				this.buildBtn.removeAttr('disabled');
				this.publishBtn.removeClass('animated');
				this.publishBtn.removeAttr('disabled');
				this.hideAnimation()
				this.isBuilding = false;
			}
		}

		_runningBuild() {
				this._changeTooltipText(this._getBuildTooltipText());
				this.buildBtn.removeClass('btn-red-border');
				this.buildBtn.removeClass('btn-white-border');
				this.buildBtn.addClass('animated');
				this.publishBtn.addClass('animated');
				this.publishBtn.attr("disabled", "disabled");
				this.buildBtn.attr("disabled", true);
				this.deployBtn.addClass("disabled");
				this.showAnimation()
				this.isBuilding = true;
		}

		_buildSuccess() {
			if (!this.isDeploying) {
				this._changeTooltipText(this._getBuildTooltipText());
				this.buildBtn.removeClass('btn-red-border');
				this.buildBtn.addClass('btn-white-border');
				this.publishBtn.removeClass('btn-red-border');
				this.deployBtn.removeClass('disabled');
				this.hideAnimation()
				this.deployBtn.popover('destroy');
				this.isBuilding = false;
			}
		}

		_buildError() {
			if (!this.isDeploying) {
				this._changeTooltipText(this._getBuildTooltipText());
				this.buildBtn.removeClass('btn-white-border');
				this.publishBtn.removeClass('animated')
				this.buildBtn.addClass('btn-red-border');
				this.publishBtn.addClass('btn-red-border');
				this.publishBtn.removeAttr('disabled');
				this.hideAnimation()
				this.deployBtn.toggleClass('disabled', !this.build.lastSuccessful);
				this.isBuilding = false;
			}
		}

		_getBuildTooltipText() {
				let text = '';
				if (this.build.last) {
					let status = this.build.last;
					if (status.isRunning || status.isSuccessful === false) {
						let time = Instant.fromJSON(status.buildTimestamp);
						if (time) {
							time = this._formatDuration(time);
						}
						let username = _.escape(status.userName) || app.getResource('anonymous');
						text = `<b>${app.getResource(status.isRunning ? 'current.build' : 'last.build')}</b><br/>`;
						text += `<span class="sub-item">${app.getResource('last.started.at')}:</span> <span class="sub-item-value">${time}</span><br/>`;
						text += `<span class="sub-item">${app.getResource('last.started.by')}:</span> <span class="sub-item-value">${username}</span>`;
						if (status.isSuccessful === false) {
							text += `<br/><a class="sub-item" style="color: #fb3737" href="${app.urls.home}lastBuildError"> ${app.getResource('view.errors')} </a>`;
						}
					}
				}
				const text2 = this._getLastSuccessfulBuildTooltipText();
				text += (text.length > 0 && text2.length > 0 ? '<br/><br/>' : '') + text2;

				if (!text) {
					text = app.getResource('no.active.build');
				}
				return text;
		}

		_getLastSuccessfulBuildTooltipText() {
				if (!this.build.lastSuccessful) {
					return '';
				}
				let status = this.build.lastSuccessful;
				let text = `<b>${app.getResource('last.successful')}</b><br/>`;
				let time = Instant.fromJSON(status.buildTimestamp);
				if (!time){
					text += `${app.getResource('no.successful.build').toLowerCase()}`;
				} else {
					time = this._formatDuration(time);
					const username = _.escape(status.userName) || app.getResource('anonymous');
					text += `<span class="sub-item">${app.getResource('started.at')}:</span> <span class="sub-item-value">${time}</span><br/>`;
					text += `<span class="sub-item">${app.getResource('started.by')}:</span> <span class="sub-item-value">${username}</span><br/>`;
					text += `<span class="sub-item">${app.getResource('version')}:</span> <span class="sub-item-value">${FormatterInfo.formatVersion(status.applicationVersion, status.buildNumber, status.isDevMode)}</span>`;
				}
				return text;
		}
		_getPublishDeployInstance() {
			if (localStorage.getItem('defaultAppToPublish_' + app.configuration.id)) {
				return JSON.parse(localStorage.getItem('defaultAppToPublish_' + app.configuration.id));
			} else {
				let activeApps = this.deployList.filter(app => app.isAppResponding && app.deploymentForbiddanceReason === DeploymentForbiddanceReason.NONE)
				if (activeApps.length)
					return activeApps[0];
				let devApps = this.deployList.filter(app => app.applicationType === ApplicationType.DEVELOPMENT);
				if (devApps.length) {
					return devApps[0];
				}
				return this.deployList.length && this.deployList[0];
			}
		}
		_changeTooltipText(text: string) {
				let infoText = app.needToRebuild ?
					`<b style="color: #F67B02">${translate('dev.container.out.of.sync')}<br> ${translate('publish.explanation')}</b>` :
					translate('publish.explanation');

				this.publishBtn.attr('data-content', infoText +
					"</br>" + (this._getPublishDeployInstance() ? this._renderDeploymentForbiddanceReason(this._getPublishDeployInstance()) : '') + "</br>" + text +
					`<br><br>
					<span>${translate('deploy.to.app')}:</span> <span class="sub-item-value">${this.getDefaultAppName()}</span>
					<a style='padding-left: 15px;' class='defaultApp'>${translate('edit')}</a>`);
		}

		_formatDuration(time) {
			let eventAge = timeSynchronizer.getServerEventAge(time);
			if (eventAge.toMillis() < 0)  {
				// Fix the case when response from server is slightly delayed
				eventAge = Duration.ofNanos(0);
			}
			return Formatter.formatDuration(eventAge);
		}

		rebuild() {
			utils.postRequest({}, app.urls.forceFullCompile);
		}

		chooseDefaultAppToPublish() {
			const defaultApplicationModal = new DefaultApplicationModal();
			const model = new BaseModel({
				applicationUpdateMode: AppUpdateMode.READ_ONLY
			});
			const headerResource = app.getResource('publish');

			defaultApplicationModal.show({
				headerResource: headerResource,
				okResource: 'publish',
				model: model,
				onSubmit: (resultModel) => {
					localStorage.setItem('defaultAppToPublish_' + app.configuration.id, JSON.stringify(resultModel.get('app')));

					// Post deploy request
					this.buildBtn.attr('disabled', true);
					this.publishBtn.attr('disabled', true);
					this.publishBtn.addClass('animated');
					this.publishBtn.attr('disabled', 'disabled');
					utils.postRequest({}, app.urls.publish(resultModel.get('app').instanceId, resultModel.get('app').applicationType == ApplicationType.DEVELOPMENT))
						.catch(e => console.log(e));
				}
			});
		}

		getDefaultAppName() {
			let publishDeployInstance = this._getPublishDeployInstance()
			return publishDeployInstance ? publishDeployInstance.title : null
		}

		showAnimation() {
			this.publishBtn.addClass('isPublishing')
			this.publishBtn.find('.animation').show()
		}

		hideAnimation() {
			this.publishBtn.removeClass('isPublishing')
			this.publishBtn.find('.animation').hide()
		}
}
