/* @flow */

import xTable from './xTable';
import TypeKind from '../../common/enums/typeKind';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import AggregateKind from '../../common/enums/aggregateKind';
import Constants from '../../common/models/constants';
import Formatter from '../../common/components/formatter';
import entityManager from '../../common/components/entityManager.js';
import utils from '../../common/components/utils.js';
import formattersAndStyles from '../../common/components/formattersAndStyles';
import Entities from '../../common/collections/entities';
import {translate} from '../../common/service/stringResourceService'

export default /*ReportTable*/ xTable.extend({

	resizable: false,

	initialize(options) {
		this.totalLabel = _.escape(translate('total'))
		xTable.prototype.initialize.apply(this, arguments);
	},

	async updateTreeTable(data) {
		this.data = data;
		data.rowGroups.forEach(g => g.id = g.clientId);
		this.data.rowGroups = Entities.fromJSON(data.rowGroups, Constants.ID_TYPE_REPORT_GROUP);
		data.columnGroups.forEach(g => g.id = g.clientId);
		this.data.columnGroups = Entities.fromJSON(data.columnGroups, Constants.ID_TYPE_REPORT_GROUP);
		this.data.valuesForTotal = Entities.fromJSON(
			data.values.filter(v => v.includeTotal), Constants.ID_TYPE_REPORT_VALUE);
		this.data.values = Entities.fromJSON(data.values, Constants.ID_TYPE_REPORT_VALUE);

		this.setGroupToGroupValues();

		this.columnsWithStringView = [];

		await this.constructHeader();
		await this.processData();
	},

	setGroupToGroupValues() {
		this.traverseTree(this.data.columnGroupRootValue, (value, key) => {
			value.group = this.data.columnGroups.get(value.groupClientId);
		});
		this.traverseTree(this.data.rowGroupRootValue, (value, key) => {
			value.group = this.data.rowGroups.get(value.groupClientId);
		});
	},

	traverseTree(tree, func) {
		_.each(tree.children, ([key, value]) => {
			func(value, key);
			this.traverseTree(value, func);
		});
	},

	async constructHeader() {
		await this.fetchStringViews(this.data.columnGroupRootValue);

		const header = this.constructHeaderNode('', this.data.columnGroupRootValue);
		const firstCol = this.constructFirstColumn(header.height);
		header.th.children.unshift(firstCol);
		this.setHeader(header.th.children);
	},

	async fetchStringViews(groupValue) {
		const requests = [];
		this.traverseTree(groupValue, (value, key) => {
			if (value.group && value.group.valueHasStringView()) {
				if (key && key !== '*') {
					requests.push({id: key.id, viewId: value.group.stringViewId()});
				}
			}
		});
		entityManager.fetchManyStringViews(requests);
		await entityManager.fetchStringViews();
	},

	constructFirstColumn(rows) {
		if (this.data.values.length > 1) {
			++rows;
		}
		return {
			'rowspan': rows - 1,
			'formatter': this.groupTreeFormatter
		};
	},

	constructHeaderNode(groupKey, groupValue): {th: any, width: number, height: number} {
		const children = [];
		let childrenWidth = 0;
		let childrenHeight = 0;
		let childrenGroup;

		_.each(this._sortChildren(groupValue.children), ([key, value]) => {
			const x = this.constructHeaderNode(key, value);
			childrenWidth += x.width;
			childrenHeight = x.height;
			children.push(x.th);

			childrenGroup = value.group;
		});

		const th = {
			html: _.escape(groupValue.group && groupValue.group.groupValueToString(groupKey, groupValue))
		};

		const childCount = _.size(groupValue.children);
		if (childCount == 0) { // add values
			childrenWidth = this.data.values.length;
			this.addValuesToHeader(th, groupValue.index, this.data.values);
		} else {
			th.children = children;
		}
		if (childCount > 1
				&& childrenGroup.includeTotal()
				&& this.data.valuesForTotal.length > 0) { // add total
			const totalTh = {
				'colspan': this.data.valuesForTotal.length,
				'rowspan': childrenHeight || 1,
				'html': this.totalLabel
			};
			this.addValuesToHeader(totalTh, groupValue.index, this.data.valuesForTotal);

			childrenWidth += this.data.valuesForTotal.length;
			children.push(totalTh);
		}

		th.colspan = childrenWidth || 1;

		return {
			th: th,
			width: childrenWidth || 1,
			height: childrenHeight + 1
		};
	},

	_sortChildren(children) {
		if (!children || _.isEmpty(children)) {
			return [];
		}

		const result = children;
		result.sort((a, b) => {
			if (a[0] === '*') {
				return -1;
			}
			if (b[0] === '*') {
				return 1;
			}
			if (a[0] === null) {
				return -1;
			}
			if (b[0] === null) {
				return 1;
			}
			const aValue = a[1].group.groupValueToComparable(a[0]);
			const bValue = b[1].group.groupValueToComparable(b[0]);
			if (_.isFunction(aValue.compareTo)) {
				return aValue.compareTo(bValue);
			}
			return aValue.toString().localeCompare(bValue.toString(), undefined, {numeric: true});
		});
		return result;
	},

	addValuesToHeader(parentTh, groupValueIndex, values) {
		if (this.data.values.length == 1
				&& this.data.columnGroups.size() != 0) {
			this.addValueAttrs(parentTh, groupValueIndex, 0);
		} else {
			parentTh.children = [];
			values.each((value, index) => {
				const fieldId = value.get('field').id;

				const th = {};
				this.addValueAttrs(th, groupValueIndex, index);
				th.html = utils.stringView(entityManager.fetchStringView(null, fieldId), null, fieldId);
				parentTh.children.push(th);
			});
		}
	},

	addValueAttrs(th, groupValueIndex, valueIndex) {
		const matrixColumnIndex = groupValueIndex * this.data.values.length + valueIndex;

		const value = this.data.values.at(valueIndex);
		let fieldType = value.field().type();
		if (value.get('aggregateKind') == AggregateKind.CUSTOM) {
			if (value.customReturnType().hasMetaObject()) {
				this.columnsWithStringView.push({
					index: matrixColumnIndex,
					stringViewId: value.stringViewId()
				});
				_.extend(th, {
					'formatter': this.metaCellFormatter,
					'matrixColumnIndex': matrixColumnIndex,
					'stringViewId': value.stringViewId()
				});
				return;
			}

			fieldType = value.customReturnType();
		}

		let resultType = fieldType;
		if (value.get('aggregateKind') == AggregateKind.COUNT) {
			resultType = app.types.get(Constants.ID_TYPE_INTEGER);
		}
		if (value.get('aggregateKind') == AggregateKind.AVERAGE &&
				fieldType.primitive() != 'DURATION' &&
				fieldType.primitive() != 'DECIMAL')
		{
			resultType = app.types.get(Constants.ID_TYPE_DOUBLE);
		}

		_.extend(th, {
			'formatter': this.cellFormatter,
			'matrixColumnIndex': matrixColumnIndex,
			// 'format': 'dd/MM/yyyy HH:mm:ss',
			'formatterOptions': {
				'type': resultType,
			}
		});
	},

	async processData() {
		await this.fetchStringViews(this.data.rowGroupRootValue);
		await this.fetchStringViewsForMatrix();

		const data = [];
		if (this.data && this.data.rowGroupRootValue) {
			this.prepareViewModel(this.data.rowGroupRootValue, data);
		}
		this.addData(data);
	},

	async fetchStringViewsForMatrix() {
		const requests = [];
		_.each(this.columnsWithStringView, column => {
			_.each(this.data.matrix, row => {
				const value = row[column.index];
				if (value) {
					requests.push({id: value, viewId: column.stringViewId})
				}
			});
		});

		entityManager.fetchManyStringViews(requests);
		await entityManager.fetchStringViews();
	},

	prepareViewModel(parent, array, parentKey) {
		let emptyOrOnlyHasSelfRow = true;
		let group;
		_.each(this._sortChildren(parent.children), ([key, child]) => {
			group = child.group;
			const selfRow = key === '*';
			if (selfRow) {
				key = parentKey;
			} else {
				emptyOrOnlyHasSelfRow = false;
			}
			const result: any = {
				key: group.groupValueToString(key),
				showLink: key && !key.folder && (group.field().isDynamic() || group.field().type().hasMetaObject()),
				typeId: !group.field().isDynamic() && group.field().type().id,
				instanceId: key && key.id,
				row: this.data.matrix[child.index],
				children: []
			};

			if (selfRow && _.size(parent.children) === 1) {
				this.prepareViewModel(child, array, key);
			} else {
				array.push(result);
				this.prepareViewModel(child, result.children, key);
			}
		});
		if (!emptyOrOnlyHasSelfRow && group.includeTotal()) {
			const totalRow = {
				key: this.totalLabel,
				row: this.data.matrix[parent.index],
				shiftLeft: true,
				isTotal: true
			};
			array.push(totalRow);
		}
	},

	cellFormatter(v, row) {
		const value = row.row[this.matrixColumnIndex];
		return _.escape(Formatter.format(value, this.formatterOptions));
	},

	metaCellFormatter(v, row) {
		const instanceId = row.row[this.matrixColumnIndex];
		const text = entityManager.getStringView(this.stringViewId, instanceId);
		return `<span class="ref" data-object-id="${instanceId}">${text}</span>`
	},

	rowFormatter(v, row) {
		if (row.isTotal) {
			return {
				classes: ['total']
			}
		}
	},

	groupTreeFormatter(v, row) {
		const value = _.escape(row.key);
		if (row.showLink) {
			if (row.typeId) {
				return `<span class="ref" data-object-id="${row.instanceId}" data-type-id="${row.typeId}">${value}</span>`;
			} else {
				return `<span class="ref" data-object-id="${row.instanceId}">${value}</span>`;
			}
		}
		return value;
	},
});
