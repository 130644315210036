import NumberFormat from '../../common/components/numberFormat';
import Constants from '../../common/models/constants';
import utils from '../../common/components/utils.js';
import Formatter from '../../common/components/formatter';
import Suggestions from './suggestions';

var FieldInput = Backbone.View.extend({

	events: {
		'focus': 'focusGot',
		'blur': 'focusLost',
		'input': 'keyTyped'
	},

	initialize: function (options) {
		this.keyTypedBool = false
		this.changedFromLastModelUpdate = false
		this.manualChangeTrigger = options.manualChangeTrigger
		if (options.model) {
			this.model = options.model;
		}
		this.modelAttr = options.modelAttr;
		this.parentField = options.parentField;
		_.extend(this.events, FieldInput.prototype.events);
		this.delegateEvents();
		if (this.model) {
			this.listenTo(this.model, 'change:' + this.modelAttr, this.render);
		}
		let justNodeText = function(el){
			return el.clone().children().remove().end().text()
		}
		let $placeholder = $(this.$el.attr('data-placeholder'))
		let placeholder = justNodeText($placeholder.find(`[data-language=${app.currentLanguage}]`))
		if (!placeholder) {
			 placeholder = ""
		}
		placeholder = placeholder.trim()
		placeholder && this.$el.attr('placeholder', placeholder)
		this.readOnlyText = this.$el.attr('data-read-only-text') == 'true';
		this.disabled = this.$el.attr('disabled') == 'disabled';
		this.$textEl = this.$el.closest('.form-group').find('.read-only-text');
		this.isHTML = !!options.isHTML;
		this.context = options.context;
		if (utils.getPrimitiveType(this.$el) == 'BOOLEAN') {
			// Make checkbox label support keypress event - Enter and Spacebar
			this.$el.closest('.form-group').find('label').on('keypress', function (e) {
				if ( e.which == 13 || e.which == 32 ) {
					$(e.target).parent().find("input[type=checkbox]").click();
	 				e.preventDefault();
				}
			});
		}
		this.initSuggestions();
	},

	initSuggestions() {
		new Suggestions(
			this.$el,
			this.setDataToModel.bind(this),
			this.modelAttr,
			this.model,
			this.parentField,
			this.context);
	},

	render: function () {
		this.setValue(this.getDataFromModel(), false);
	},

	focus: function () {
		this.$el.focus();
	},
	setValue: function (value, isSwitchToEditMode) {
		const primitiveType = utils.getPrimitiveType(this.$el);
		const formatterId = this.$el.attr('data-formatter-id');
		const typeId = this.$el.attr('data-entity-type-id');

		var scale = NaN;
		switch (primitiveType) {
			case 'DECIMAL':
			case 'INTEGER':
			case 'DOUBLE':
				if (this.$el.attr('data-nullable') == 'false') {
					value = value || 0;
				}
				// Set and normalize field value according to current mode and culture format
				// 1. If value is new then apply regardless the focus mode
				// 2. On focus (switching to edit mode), change to the simplified format
				// 3. On focus lost, change to the decorated format
				// 4. Otherwise do not change the value
				if (isSwitchToEditMode || !this.$el.is(":focus") || value != this.getValue()) {
					if (value || value == 0) {
						value = Formatter.format(value, {
							primitiveType: primitiveType,
							formatterId: formatterId,
							typeId: typeId,
							scale: scale,
							isEditMode: isSwitchToEditMode
						});
					}
					this.$el.val(value);
				}
				break;
			case 'STRING':
			case 'SYSTEM_STRING':
				this.$el.val(value);
				break;
			case 'BOOLEAN':
					this.$el.prop('checked', value);
				break;
			case 'BINARY':
				this.$el.parent().find('.binary-link').attr('data-file', value);
				this.$el.parent().find('.binary-link').attr('data-filename', 'file.txt');
				break;
			default:
				if (value !== this.$el.val()) {
					this.$el.val(value);
				}
				break;
		}
		if (this.readOnlyText) {
			if (value === null || value === undefined || value === '') {
				value = '-';
			}
			if (this.isHTML) {
				this.$textEl.html(value);
			} else {
				this.$textEl.text(value);
			}
		}
	},

	getValue: function () {
		var result = null;
		var primitiveType = utils.getPrimitiveType(this.$el);
		switch (primitiveType) {
			case 'DECIMAL':
			case 'INTEGER':
			case 'DOUBLE':
				if (primitiveType == 'DECIMAL') {
					const scale = this.$el.attr('data-scale');
					result = NumberFormat.parseNumber(this.$el.val(), primitiveType, scale);
					if (result) {
						result = result * Math.pow(10, scale);
						result = Math.round(result);
					}
				}
				else {
					result = NumberFormat.parseNumber(this.$el.val(), primitiveType);
				}
				if (isNaN(result)) {
					result = null;
				}
				if (result == null && this.$el.attr('data-nullable') == 'false') {
					result = 0;
				}
				break;
			case 'STRING':
			case 'SYSTEM_STRING':
				result = this.$el.val();
				if (result.trim()) {
					result = result.trim()
				}
				break;
			case 'BOOLEAN':
					result = this.$el.prop('checked');
				break;
			case 'BINARY':
				// result = this.$el.val();
				break;
			default:
				result = this.$el.val();
				break;
		}

		return result;
	},

	getDataFromModel: function () {
		return this.model.get(this.modelAttr);
	},

	setDataToModel: function (data) {
		this.changedFromLastModelUpdate = false
		if (this.model && this.model.get(this.modelAttr) !== data) {
			//special check for input where we haven`t typed anything
			//and it is empty
			//to not trigger required validation error
			if (!this.model.get(this.modelAttr) && !data && !this.keyTypedBool) {
				return
			}
			this.model.set(this.modelAttr, data);
			if (this.manualChangeTrigger){
				this.manualChangeTrigger()
			} else {
				this.model.trigger('manualChange:' +  this.modelAttr, this.model);
			}
		}
	},

	changed: function () {
		// Perform the logic if not a number type
		if (!this.isNumericType(this.$el)) {
			this.setDataToModel(this.getValue());
		}
	},

	keyTyped: function () {
		this.keyTypedBool = true
		this.changedFromLastModelUpdate = true
		// Perform the logic only for numbers
		if (!this.isNumericType(this.$el)) {
			return;
		}

		if (!this.isValidValue(this.$el, true)) {
			// Value is valid or can be auto-fixed
			this.setErrorView();
		}
		else if (!this.isValidValue(this.$el, false)) {
			// Value is not valid and cannot be auto-fixed
			this.setWarningView();
		} else {
			// Value is valid
			this.setNormalView();
		}
	},

	focusGot: function () {
		// Refresh numeric values to apply the edit format
		if (this.isNumericType(this.$el)) {
			this.setValue(this.getDataFromModel(), true);
		}
		if (this.isValueSelectionRequired(this.$el)) {
			this.$el.select();
		}
	},

	isValueSelectionRequired: function($el) {
		if (this.isTouchScreenDevice()) {
			// If we select value on touch screens
			// then a context popup menu appears,
			// which is not convenient
			return false;
		}
		var primitiveType = utils.getPrimitiveType($el);
		switch (primitiveType) {
			case 'TIMESTAMP':
			case 'LOCAL_DATE':
			case 'LOCAL_TIME':
			case 'LOCAL_DATE_TIME':
			case 'MONTH':
			case 'MONTH_DAY':
			case 'YEAR':
			case 'YEAR_MONTH':
				return false;
			default:
				return true;
		}
	},

	isTouchScreenDevice: function() {
		return 'ontouchstart' in document.documentElement;
	},

	focusLost: function () {

		// Perform the logic only for numbers
		if (!this.isNumericType(this.$el)) {
			this.setDataToModel(this.getValue());
			return;
		}

		if (!this.isValidValue(this.$el, true)) {
			// Revert to the committed value
			this.setValue(this.getDataFromModel(), false);
		} else {
			// Commit new value
				this.setDataToModel(this.getValue());
			// Refresh numeric value to correspond the view mode
			this.setValue(this.getDataFromModel(), false);
		}

		// We are sure that value is valid at this point
		this.setNormalView();
	},

	setNormalView: function() {
		let elementBox = this.getBorderElement(this.$el);
		if (!elementBox.hasClass('has-validation-error')) {
			elementBox.removeClass('has-error');
		}
		elementBox.removeClass('has-warning');
	},

	setErrorView: function() {
		let elementBox = this.getBorderElement(this.$el);
		elementBox.removeClass('has-warning');
		elementBox.addClass('has-error');
	},

	setWarningView: function() {
		let elementBox = this.getBorderElement(this.$el);
		if (!elementBox.hasClass('has-validation-error')) {
			elementBox.removeClass('has-error');
		}
		elementBox.addClass('has-warning');
	},

	getBorderElement: function ($el) {
		return $el.closest('.form-group');
	},

	isDisabledInFormBuilder: function () {
		return this.disabled
	},

	enable: function () {
		this.$el.removeAttr('disabled');
		if (this.readOnlyText) {
			this.$textEl.addClass('hidden');
			this.$el.removeClass('hidden');
		}
		this.$el.parent().find('[tabindex-orig]').each(( index, elem ) => {
			$(elem).attr('tabindex', $(elem).attr('tabindex-orig'));
		});
	},

	disable: function () {
		this.$el.attr('disabled', 'disabled');
		if (this.readOnlyText) {
			this.$textEl.removeClass('hidden');
			this.$el.addClass('hidden');
		}
		this.$el.parent().find('[tabindex]').each(( index, elem ) => {
			if (!$(elem).attr('tabindex-orig')) {
				$(elem).attr('tabindex-orig', $(elem).attr('tabindex'));
			}
			$(elem).attr('tabindex', '-1');
		});
	},

	isValidValue: function($el, isAutoCorrectionEnabled) {
		var isValid = true;
		if (this.isNumericType($el)) {
			isValid = this.isValidNumber($el, isAutoCorrectionEnabled);
		}
		return isValid;
	},

	isNumericType: function($el) {
		var primitiveType = utils.getPrimitiveType($el);
		switch (primitiveType) {
			case 'DECIMAL':
			case 'INTEGER':
			case 'DOUBLE':
				return true;
			default:
				return false;
		}
	},

	isValidNumber: function($el, isAutoCorrectionEnabled) {

		var formValue = $el.val();

		if (formValue == "") {
			return true;
		}

		var primitiveType = utils.getPrimitiveType($el);
		var scale = $el.attr('data-scale');

		var modelValue = NumberFormat.parseNumber(formValue, primitiveType, scale);
		if (isNaN(modelValue)) {
			return false;
		}

		if (primitiveType == 'INTEGER') {
			if (modelValue != Math.round(modelValue)) {
				return false;
			}
		}

		if (primitiveType == 'DECIMAL' && !isAutoCorrectionEnabled) {
			if (scale != null && !isNaN(scale)) {
				// We allow only limited number of decimal digits

				var trimmedFormValue = formValue;
				trimmedFormValue = trimmedFormValue.replace('+', '');
				trimmedFormValue = trimmedFormValue.replace('-', '');
				trimmedFormValue = trimmedFormValue.replace(/\s/g, '');
				trimmedFormValue = trimmedFormValue.replace(/\b0+/g, '');
				var separators = trimmedFormValue.replace(/\d/g,'');
				//console.log("trimmedFormValue: " + trimmedFormValue);
				//console.log("separators: " + separators);

				var totalLength = trimmedFormValue.length - separators.length;
				//console.log("trimmed length: " + trimmedFormValue.length);
				//console.log("sep length: " + separators.length);
				//console.log("total length: " + totalLength);
				if (totalLength > 15) {
					return false;
				}

				var decimalSeparatorIndex = -1;
				if (separators.length > 0) {
					decimalSeparatorIndex = trimmedFormValue.indexOf(separators[0]);
				}

				var decimalLength = 0;
				if (decimalSeparatorIndex > -1) {
					decimalLength = totalLength - decimalSeparatorIndex;
				}
				//console.log("dot index: " + decimalSeparatorIndex);
				//console.log("decimals: " + decimalLength);
				if (decimalLength > scale) {
					return false;
				}
			}
		}

		return true;
	},

	reset: function () {
		this.changed();
	},

	prepareToSave: function () {
		if (this.changedFromLastModelUpdate) {
			this.changedFromLastModelUpdate = false
			if (!this.isNumericType(this.$el)) {
				this.model.set(this.modelAttr, this.getValue());
				return;
			}

			if (!this.isValidValue(this.$el, true)) {
				this.model.set(this.modelAttr, this.getValue());
			}
		}
	}
});

export default FieldInput;
