/* @flow */

import NumberFormat from '../../common/components/numberFormat';
import TypeKind from '../enums/typeKind';
import PrimitiveEntityType from '../enums/primitiveEntityType';
import BinaryKind from '../enums/binaryKind'
import ModelFactory from '../../common/models/modelFactory';
import DateTimeFormatter from '../../time/formatters/dateTimeFormatter';
import DateTimeAmountFormatter from '../../time/formatters/dateTimeAmountFormatter';
import Duration from '../../time/models/duration';
import Instant from '../../time/models/instant';
import PrimitiveFormatters from '../../common/collections/primitiveFormatters';
import PrimitiveFormatter from '../../common/models/primitiveFormatter'
import * as time from '../../time/models/index';
import CodejigStringFormatter from '../../utils/codejigStringFormatter';
import utils from '../../common/components/utils'
import {getView} from '../../common/components/utils'
import PropertyKey from '../../common/enums/propertyKey.js'
import LocalDateTime from '../../time/models/localDateTime'
import {translate} from '../../common/service/stringResourceService'

export default class Formatter {

	static clienViews

	/*
	* Main formatting function to formta user data
	*/
	static format(object: ? any, options: ? any): ? string {

		if (object === null || object === undefined) {
			return '';
		}

		let {type, primitiveType, formatter} = Formatter._parseOptions(options);

		const scale = type && type.decimalScale()
				|| options.scale
				|| 0;

		if (object && object.toJSON) {
			object = object.toJSON();
		}
		let model;
		switch (primitiveType) {
			case PrimitiveEntityType.DECIMAL:
				object = object / Math.pow(10, scale);
			case PrimitiveEntityType.INTEGER:
			case PrimitiveEntityType.DOUBLE:
				return Formatter.formatNumber(object, formatter, primitiveType, scale, options.isEditMode);
			case PrimitiveEntityType.LOCAL_DATE_RANGE:
				model = ModelFactory.getPrimitiveType(primitiveType);
				object = model.fromJSON(object);
				return Formatter.formatDateRange(model.fromJSON(object), formatter);
			case PrimitiveEntityType.TIMESTAMP:
			case PrimitiveEntityType.LOCAL_DATE:
			case PrimitiveEntityType.LOCAL_TIME:
			case PrimitiveEntityType.LOCAL_DATE_TIME:
			case PrimitiveEntityType.DAY_OF_WEEK:
			case PrimitiveEntityType.MONTH:
			case PrimitiveEntityType.MONTH_DAY:
			case PrimitiveEntityType.YEAR:
			case PrimitiveEntityType.YEAR_MONTH:
				model = ModelFactory.getPrimitiveType(primitiveType);
				object = model.fromJSON(object);
				return Formatter.formatTemporalAccessor(object, formatter);
			case PrimitiveEntityType.PERIOD:
			case PrimitiveEntityType.DURATION:
				model = ModelFactory.getPrimitiveType(primitiveType);
				object = model.fromJSON(object);
				return Formatter.formatTemporalAmount(model.fromJSON(object), formatter);
			case PrimitiveEntityType.ZONE_OFFSET:
				object = object && time.Duration.ofSeconds(object.seconds);
				return Formatter.formatTemporalAmount(object, formatter);
			case PrimitiveEntityType.STRING:
				model = ModelFactory.getPrimitiveType(primitiveType);
				if (_.isString(object)) {
					object = model.fromString(object);
				} else {
					object = model.fromJSON(object);
				}
				return Formatter.formatString(object, formatter);
			case PrimitiveEntityType.BOOLEAN:
				return Formatter.formatBoolean(object, formatter);
			default:
				return object;
		}
	}

	static _parseOptions(options) {
		const type = options.type
				|| options.typeId && app.types.get(options.typeId);

		const primitiveType = (type && type.primitive())
				|| options.primitiveType;
		const formatter = options.formatter
				|| options.formatterId && app.primitiveFormatters.get(options.formatterId)
				|| type && type.formatter()
				|| Formatter.getDefaultFormatter(primitiveType);

		return {type, primitiveType, formatter};
	}

	/*
	* Used to format for web only
	*/
	static formatToHTML(object: ? any, options: ?any) {

		let {type, primitiveType, formatter} = Formatter._parseOptions(options);
		let model;
		switch (primitiveType) {
			case PrimitiveEntityType.STRING:
				model = ModelFactory.getPrimitiveType(primitiveType);
				object = model.fromJSON(object);
				if (options.isHTMLString){
					return object.getCurrentValue()
				}
				return Formatter.formatStringToHTML(object, formatter);
			case PrimitiveEntityType.BOOLEAN:
				return Formatter.formatBooleanToHTML(object);
			case PrimitiveEntityType.BINARY:
				return Formatter.formatBinaryToHTML(object);
			default:
				return Formatter.format(object, options);
		}
	}


	static formatToHTMLWithClientView(object, options){
		const type = options.type
				|| options.typeId && app.types.get(options.typeId)
		const primitiveType = type && type.primitive()
				|| options.primitiveType
		if (!object) {
			options.el.text('-')
			return
		}
		if (!options.clientViewId){
			options.el.html(Formatter.formatToHTML(object, options))
			return
		}
		this.getClientView(options.clientViewId).then((clientView) => {
			const properties = {}
			clientView.properties.forEach((p) => {
				properties[p.key] = p.value && p.value.value || null
			})
			var objectSecond = null
			switch (primitiveType) {
				case PrimitiveEntityType.TIMESTAMP:
					objectSecond = object.seconds
				case PrimitiveEntityType.MONTH:
				case PrimitiveEntityType.LOCAL_DATE:
				case PrimitiveEntityType.LOCAL_TIME:
				case PrimitiveEntityType.LOCAL_DATE_TIME:
				case PrimitiveEntityType.MONTH_DAY:
				case PrimitiveEntityType.YEAR:
				case PrimitiveEntityType.YEAR_MONTH:
					if (properties){
						if (properties[PropertyKey.SHOW_DATE_TIME_IN_POPOVER] == "true"){
								options.el.attr('title', Formatter.format(object, options))
						}

						objectSecond = objectSecond || this.epochSecondOf(object)
						const formatter = app.primitiveFormatters.get(properties[PropertyKey.DURATION_FORMATTER])
						var diff = this.getEpochSecond() - objectSecond
						var durationFormatted = Formatter.format({seconds:Math.abs(diff)},{primitiveType: PrimitiveEntityType.DURATION, formatter: formatter})
						durationFormatted = (diff < 0 ? translate("after") + " " : "") + durationFormatted + (diff > 0 ? " " + translate("ago") : "")
						options.el.text(durationFormatted)
						return
					}
				default:
					options.el.text(Formatter.format(object, options))
			}
		})
	}

	static epochSecondOf(value) {
		const date = new Date()
		value.year && date.setFullYear(value.year)
		value.month && date.setMonth(value.month-1)
		value.day && date.setDate(value.day)
		value.hour && date.setHours(value.hour)
		value.minute && date.setMinutes(value.minute)
		value.second && date.setSeconds(value.second)
		return Math.floor(date.getTime()/1000)
	}

	static getEpochSecond() {
		return Math.floor(Date.now()/1000)
	}

	static getClientView(clientViewId){
		if (!this.clientViews){
			this.clientViews = new Map()
		}
		var clientView = this.clientViews.get(clientViewId)
		if (clientView) {
			return clientView
		} else {
			clientView = getView(clientViewId)
			this.clientViews.set(clientViewId, clientView)
			return clientView
		}
	}

	/*
	* Used to format system values in builder and app
	* (i.e., start time of tasks and so on)
	*/
	static PREDEFINED_FORMATS = {
		[PrimitiveEntityType.TIMESTAMP] : "dd/MM/yyyy HH:mm:ss",
		[PrimitiveEntityType.LOCAL_DATE]: 'dd/MM/yyyy',
		[PrimitiveEntityType.LOCAL_TIME]: 'HH:mm:ss',
		[PrimitiveEntityType.LOCAL_DATE_TIME]: 'dd/MM/yyyy HH:mm:ss',
		[PrimitiveEntityType.LOCAL_DATE_RANGE]: 'dd/MM/yyyy'
	}
	static formatWithPredefinedFormat(object: ?any, options: ?any) {
		const format = Formatter.PREDEFINED_FORMATS[options.primitiveType];
		const formatter = new PrimitiveFormatter({
			kind: 'PATTERN',
			pattern: format
		});
		return Formatter.format(object, {
			formatter: formatter,
			primitiveType: options.primitiveType
		});
	}

	static FULL_FORMATS = {
		[PrimitiveEntityType.TIMESTAMP] : "DD/MM/YYYY HH:mm:ss",
		[PrimitiveEntityType.LOCAL_DATE]: 'DD/MM/YYYY',
		[PrimitiveEntityType.LOCAL_TIME]: 'HH:mm:ss',
		[PrimitiveEntityType.LOCAL_DATE_TIME]: 'DD/MM/YYYY HH:mm:ss',
		[PrimitiveEntityType.LOCAL_DATE_RANGE]: 'DD/MM/YYYY'
	}

	static formatTemporalAccessor(object, formatter) {
		if (formatter.kind() == 'PATTERN') {
			const dateFormatter = DateTimeFormatter.ofPattern(formatter.pattern(), app.currentLanguage);
			return dateFormatter.format(object);
		} else {
			throw new Error("Not supported formatter kind");
		}
	}

	static formatDateRange(object, formatter) {
		if (formatter.kind() == "PATTERN") {
			const dateFormatter = DateTimeFormatter.ofPattern(formatter.pattern(), app.currentLanguage);
			return dateFormatter.format(object.from) + ' - ' + dateFormatter.format(object.to);
		} else {
			throw new Error("Not supported formatter kind");
		}
	}

	static formatTemporalAmount(object, formatter) {
		if (formatter.kind() == 'PATTERN') {
			const dateFormatter = DateTimeAmountFormatter.ofPattern(formatter.pattern(), app.currentLanguage);
			return dateFormatter.format(object);
		} else {
			throw new Error("Not supported formatter kind");
		}
	}

	static formatDuration(duration: Duration, text: boolean) {
		let past = Duration.fromJSON({
			seconds: -duration.getSeconds(),
			nanos: -duration.getNano()
		});
		return past.toMoment().humanize(!text);
	}

	static formatBoolean(object, format) {
		return object;
	}

	static formatBooleanToHTML(object, format) {
		if (object) {
			var span = $('<div>').addClass('icheckbox_minimal checked cursor_unset');
			return span.prop('outerHTML');
		} else {
			var span = $('<div>').addClass('icheckbox_minimal cursor_unset');
			return span.prop('outerHTML');
		}
	}

	static _formatFileSize = (() => {
		//TODO :: should we add i8n here ?
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const step = 1024;
		const step_lg = Math.log(step);
		return (bytes, scale) => {
			if (!bytes)return "0 " + sizes[0]
			const unit = Math.floor(Math.log(bytes) / step_lg);
			return parseFloat((bytes / Math.pow(step, unit)).toFixed(scale || 2)) + ' ' + sizes[unit];
		}
	})();

	static formatBinaryToHTML(object, options) {
		const fileSize = object && object.internalFileInfo && object.internalFileInfo.fileSize || 0;
		const showSize = options && options.showSize;
		return object ? `<a href="${app.urls.download(object)}" ${object.kind==BinaryKind.EXTERNAL ? 'target="_blank"' : ''}>
				${(object.fileName) || object.url}
			</a> ${showSize ? '(' + Formatter._formatFileSize(fileSize) + ')' : ''}`
			: "-"
	}

	static formatString(object, formatter) {
		if (formatter.kind() == 'PATTERN') {
			const stringFormatter = CodejigStringFormatter.ofPattern(formatter.pattern());
			return stringFormatter.format(object);
		} else {
			throw new Error("Not supported formatter kind");
		}
		return object;
	}

	static formatStringToHTML(object, formatter) {
		if (formatter.kind() == 'PATTERN') {
			const stringFormatter = CodejigStringFormatter.ofPattern(formatter.pattern());
			/*
			* We have fucntionality to add language tag, if required locale is missed,
			* However that doesn't make sense for custom formatters, as they could mix locales,
			* Nevertheless, we decided not to loose this feature for our predefined formats
			*/
			const addLanguageTag = utils.isIdInPredefinedRange(formatter.id);
			return object.toHTML(addLanguageTag, true, stringFormatter);
		} else {
			throw new Error("Not supported formatter kind");
		}
	}

	//Doesn't support formatters yet
	static formatNumber(number, formatter, primitiveType, scale, isEditMode) {
		return NumberFormat.formatNumber(
			number, formatter.pattern(), primitiveType, isEditMode, scale);
	}

	/*
		Is used to define which mask to use to edit primitives using inputs
		probably, sould be done in nicer way using `ClientFormats`
	*/
	static getEditingFormat(options) {
		const type = options.type
				|| options.typeId && app.types.get(options.typeId);
		const primitiveType = type && type.primitive()
				|| options.primitiveType;

		let formatter = options.formatter
				|| options.formatterId && app.primitiveFormatters.get(options.formatterId)

		if ((!formatter || formatter.kind() != 'PATTERN') && type) {
			formatter = type.formatter();
		}

		if (!formatter || formatter.kind() != 'PATTERN') {
			formatter = Formatter.getDefaultFormatter(primitiveType);
		}
		return formatter.pattern();
	}

	static getDefaultFormatter(primitiveType) {
		let formatter = app.defaultFormatters[primitiveType];
		return formatter && app.primitiveFormatters.get(formatter.id);
	}
}
