import { applicationState } from '../components/applicationState';

const StateRecovery = {

	put: function (key, value) {
		localStorage[key] = JSON.stringify(value);
	},

	get: function (key) {
		return localStorage[key] && JSON.parse(localStorage[key]);
	},

	change: function (key, func) {
		const state = this.get(key) || {};
		func(state);
		this.put(key, state);
	},

	delete: function(key, field) {
		const state = this.get(key) || {};
		delete state[field];
		this.put(key, state);
	}

};

export default StateRecovery;
