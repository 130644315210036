import ConfirmModal from './confirmModal';
import PrimitiveEntityType from '../enums/primitiveEntityType';
import ModelFactory from '../models/modelFactory';
import Constants from '../models/constants';
import BaseModel from '../models/baseModel';
import States from '../enums/states';
import OpenMode from '../enums/openMode';
import MultilingualString from '../../common/models/multilingualString'

/**
 * Available as `app.utils`
 * @namespace utils
 */
var utils = {

	/**
	 * Get current user object
	 * @returns {object}
	 */
	getCurrentUser() {
		return app.currentUser;
	},

	/**
	 * Get current language tag
	 * @returns {string} "en", "fi", "sv", etc.
	 */
	getCurrentLanguage() {
		return app.currentLanguage;
	},

	generateUUID: function () {
		var d = new Date().getTime();
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
		return uuid;
	},

	getCSRFHeaders: function() {
		const CSRFheader = $("meta[name='_csrf_header']").attr("content");
		const CSRFtoken = $("meta[name='_csrf']").attr("content");
		const headers = {};
		if (CSRFheader) {
			headers[CSRFheader] = CSRFtoken;
		}
		return headers;
	},

	postRequest: function (data, url) {
		return new Promise(function (resolve, reject) {
			var taskId = utils.generateUUID();
			top.app.taskManager.tasks[taskId] = {
				resolve: resolve,
				reject: reject
			};

			var onAjaxError = function (e) {
				reject(e);
			};

			var headers = utils.getCSRFHeaders();
			headers['taskId'] = taskId;
			$.ajax({
				type: 'post',
				url: url,
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json',
				mimeType: 'application/json',
				headers: headers,
				success: $.noop,
				error: onAjaxError
			});
		}).catch(error => {
			top.app.taskManager.showResult(error.responseJSON || error);
			throw error;
		})

	},

	getRequest: function (url) {
		return new Promise(function (resolve, reject) {
			const taskId = utils.generateUUID();
			const headers = {
				taskId: taskId
			}
			app.taskManager.tasks[taskId] = true
			$.ajax({
				type: 'get',
				url: url,
				headers: headers,
				success: resolve,
				error: reject
			});
		});
	},

	/**
	 * @param {string} data - request body object
	 * @param {string} url - URL to to make request to
	 * @param {string} type - request type ('post', 'get', 'put', 'delete', etc.)
	 * @returns {Promise}
 	 */
	request: function (data, url, type) {
		return new Promise(function (resolve, reject) {
			utils.ajaxRequest(data, url, type, resolve, reject, true, false);
		});
	},

	ajaxRequest: function (data, url, ajaxType, successFun, errorFun, isJson, funcContext, cache) {
		utils.ajaxRequestEx({
			data: data,
			url: url,
			ajaxType: ajaxType,
			isJsonPosted: isJson,
			enableCache: cache,
			successFunction: successFun,
			errorFunction: errorFun,
			functionContext: funcContext
		});
	},

	postPlainText: function (data, url, cache) {
		return new Promise((resolve, reject) => {
			$.ajax({
				type: 'POST',
				url: url,
				data: data,
				contentType: 'text/plain',
				cache: cache,
				headers: utils.getCSRFHeaders(),
				success: resolve,
				error: reject
			});
		});
	},

	ajaxRequestEx: function (options) {
		var headers = utils.getCSRFHeaders();
		options.cache = (options.cache === false ? false : true);
		if (options.isJsonPosted) {
			$.ajax({
				type: options.ajaxType,
				url: options.url,
				data: JSON.stringify(options.data),
				contentType: 'application/json',
				mimeType: 'application/json',
				dataType: 'json',
				success: options.successFunction,
				error: options.errorFunction,
				context: options.functionContext,
				headers: headers,
				cache: options.enableCache
			});
		} else {
			$.ajax({
				type: options.ajaxType,
				url: options.url,
				data: options.data,
				traditional: true,
				success: options.successFunction,
				error: options.errorFunction,
				context: options.functionContext,
				headers: headers,
				cache: options.enableCache
			});
		}
	},

	getDataFieldAttr: function (element) {
		var result = element.attr('data-field');
		result = result || (element.attr('data-field-id') &&
			'field' + element.attr('data-field-id'));
		return result;
	},

	getTypeId: function (element) {
		return element.attr('data-entity-type-id') ? element
				.attr('data-entity-type-id') : null;
	},

	getViewId: function (element) {
		return element.attr('data-view-id') ?
			element.attr('data-view-id') : null;
	},

	getPrimitiveType: function (element) {
		return element.attr('data-primitive-type') ?
			element.attr('data-primitive-type') : null;
	},

	logError: function (error) {
		console.error(error);
	},

	error: function (response) {
		app.taskManager.showResult(response.responseJSON || response);
		if (!response) {
			console.error('Whoops, looks like something went wrong :(');
		} else {
			console.error('Response text: ' + response.responseText);
			console.error('Message: ' + response.message);
			console.error('Stack: ' + response.stack);
			console.error(response);
		}
	},

	reload: function () {
		location.reload();
	},

	redirectToIndex: function () {
		$(location).attr('href', app.urls.index);
	},

	redirectTo: function (url, newTab) {
		newTab ?
			window.open(url, '_blank') : window.open(url, '_self');
	},

	getColSize: function (col) {
		var re = new RegExp('col-md-' + '(\\d+)', 'i');
		return parseInt(re.exec(col.attr('class'))[1]);
	},

	setColSize: function (col, size) {
		var re = new RegExp('(' + 'col-md-' + '(\\d+))', 'i');
		var reResult = re.exec(col.attr('class'));
		if (reResult && parseInt(reResult[2]) !== size) {
			col.switchClass(reResult[1], 'col-md-' + size, 50, function () {
				col.trigger('resized');
			});
		} else {
			col.addClass('col-md-' + size);
			col.trigger('resized');
		}
		col.width('');
	},

	getChildModel: function (model, element) {
		var fields = this.getDataFieldAttr($(element)).split('.');
		var result = {};
		var i = 0;
		for (; i < fields.length - 1; ++i) {
			if (!model.get(fields[i])) {
				model.set(fields[i], new BaseModel());
			}
			model = model.get(fields[i]);
		}
		result.attr = fields[i];
		result.model = model;
		return result;
	},

	getViewDocument: function (view) {
		return utils.getViewFolder() + '/' +
					utils.getViewDocumentFile(view, false);
	},

	getViewFolder: function () {
		return 'auto';
	},

	getMenuFolder: function () {
		return 'auto/menus';
	},

	getViewDocumentFile: function (view, extension) {
		return 'view' + view.id + (extension ? '.html' : '');
	},

	getMenuDocumentFile: function (menu, extension) {
		return 'menu' + menu.id + (extension ? '.html' : '');
	},

	getDefaultViewDocumentFile: function (type, viewKind, extension) {
		return 'view' + type.id + '-' + viewKind + (extension ? '.html' : '');
	},

	getDefaultViewDocument: function (type, viewKind) {
		return utils.getViewFolder() + '/' +
					utils.getDefaultViewDocumentFile(type, viewKind, false);
	},

	getSharedViewDocumentFile: function (view, extension) {
		return 'sharedView' + view.id + (extension ? '.html' : '');
	},

	getDefaultSharedViewDocumentFile: function (viewKind, extension) {
		return 'sharedView' + '-' + viewKind + (extension ? '.html' : '');
	},

	getSharedStylesFile: function (configurationId, extension) {
		return 'sharedStyles' + configurationId + (extension ? '.css' : '');
	},

	getSharedClassName: function (style) {
		return 'sharedStyle' + style.id;
	},

	equals: function (lhs, rhs, difference) {
		var that = this;
		if (lhs == null && rhs == null) { return true; }
		if (lhs == null || rhs == null) { return false; }
		if (_.isArray(lhs)) {
			if (difference) {
				return lhs.length === 0;
			} else {
				return that.arrayDeepEquals(lhs, rhs, difference);
			}
		}
		if (_.isObject(lhs)) {
			if (lhs.className && rhs.className &&
				lhs.className != rhs.className) { return false; }
			if (['AbstractDictionary', 'AbstractDocumnet', 'AbstractReport',
				'AbstractRegistrer'].indexOf(lhs.className) > -1) {
				return lhs.id == rhs.id;
			}
			var omit = ['metaObject', 'clientState', 'className', 'clientId'];

			return !_.chain(lhs).omit(omit).some(function (val, i) {
					return !that.equals(val, rhs[i], difference);
				}).value()
				&& !_.chain(rhs).omit(omit).some(function (val, i) {
					return val != undefined && lhs[i] === undefined;
				}).value();
		}
		return lhs == rhs;
	},

	arrayDeepEquals: function (lhs, rhs, difference) {
		if (lhs == null && rhs == null) { return true; }
		if (!_.isArray(lhs) || !_.isArray(rhs)) { return false; }
		if (lhs == null || rhs == null) { return false; }
		if (lhs.length != rhs.length) { return false; }
		if (lhs.filter(e => _.isObject(e) && !e.id).length || rhs.filter(e => _.isObject(e) && !e.id).length) {
			return false;
		}
		let lhs2 = _.sortBy(lhs, 'id');
		let rhs2 = _.sortBy(rhs, 'id');
		return _.chain(lhs2).map((v,i) => this.equals(v, rhs2[i], difference))
			.filter(i => !i).value().length == 0;
	},

	arrayEquals: function (lhs, rhs) {
		if (lhs == null && rhs == null) { return true; }
		if (!_.isArray(lhs) || !_.isArray(rhs)) { return false; }
		if (lhs == null || rhs == null) { return false; }
		if (lhs.length != rhs.length) { return false; }
		let lhs2 = _.sortBy(lhs, function (item) {
			return item && _.isObject(item) ? (item.id ? item.id : item.cid) : item;
		});
		let rhs2 = _.sortBy(rhs, function (item) {
			return item && _.isObject(item) ? (item.id ? item.id : item.cid) : item;
		});
		for (var i = 0; i < lhs.length; ++i) {
			if (_.isObject(lhs2[i]) && _.isObject(rhs2[i])) {
				if ((lhs2[i].id || lhs2[i].cid) != (rhs2[i].id || rhs2[i].cid)) {
					return false;
				}
			} else {
				if (lhs2[i] !== rhs2[i]) { return false; }
			}
		}
		return true;
	},

	randomPassword: function (length) {
		var chars = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
		var pass = '';
		for (var x = 0; x < length; x++) {
				var i = Math.floor(Math.random() * chars.length);
				pass += chars.charAt(i);
		}
		return pass;
	},

	buildModalForConfirmDelete: function (response, resources, onDeleteFunc, onSuccessFunc, onCancelFunc, onErrorFunc) {
    resources = resources || {}
    let resource = resources.beforeDelete || 'action.delete.items.question'
    let hasStrictDependenies = response != null && response.kind == 'STRICT' && response.usages.length > 0
    let hasOthersDetails = response != null && response.kind != 'STRICT' && response.usages.length > 0
    let headerResource
    if (hasStrictDependenies) {
      resource = resources.forbiddenDelete || 'action.forbidden.delete'
    } else {
      resource = resources.referencedItems || 'delete.referenced.items'
      headerResource = resources.headerResource || 'deletion.confirmation'
    }
    (new ConfirmModal()).show({
      resource: resource,
      headerResource: headerResource,
      buttons: hasStrictDependenies ?
        {
          'close': () => {
            if (onCancelFunc) onCancelFunc()
          }
        } :
        {
          'delete': () => {
            onDeleteFunc()
            .then(onSuccessFunc)
            .catch((e) => {
              if (onErrorFunc) {
                onErrorFunc()
              } else if (onCancelFunc) {
                onCancelFunc()
              }
            })
          },
          'cancel': () => {
            if (onCancelFunc) onCancelFunc()
          }
        },
      confirmDetails: hasOthersDetails,
      strictDependenies: hasStrictDependenies,
      dependencies: response
    })
  },

  confirmDelete: function (ids, dependenciesURL, deleteURL, resources, onSuccessFunc, onCancelFunc, onErrorFunc) {
    let that = this
    ;(dependenciesURL
      ? this.request(ids, dependenciesURL, 'post')
      : new Promise(function (resolve, reject) { resolve(); }))
      .then((response) => {
				onSuccessFunc = onSuccessFunc || (() => {
					that.reload()
				});
        let onDeleteFunc = () => that.postRequest(ids, deleteURL);
        this.buildModalForConfirmDelete(response, resources, onDeleteFunc, onSuccessFunc, onCancelFunc, onErrorFunc)
      })
  },

	changeFieldTypeMessage: function (onSuccessFunc, onCancelFunc, onErrorFunc) {
		let that = this
		;(new Promise(function (resolve, reject) { resolve(); }))
		.then((response) => {
			onSuccessFunc = onSuccessFunc || (() => {
				that.reload()})
				this.buildModalForChangeFieldTypeMessage(response, onCancelFunc)
			})
		},

	buildModalForChangeFieldTypeMessage: function (response, onCancelFunc) {
		let resource = 'change.field.type.message'
		;(new ConfirmModal()).show({
			resource: resource,
			buttons:
			{
				'close': () => {
					if (onCancelFunc) onCancelFunc()
				}
			},
			confirmDetails: false,
			strictDependenies: false,
			dependencies: response
		})
	},

	getUsages: function (options) {
		global.app.confirmModal = global.app.confirmModal || new ConfirmModal();
		this.getRequest(options.url)
			.then((response) => {
				app.confirmModal.show({
					strictDependenies: true,
					dependencies: response,
					nodeProperties: options.nodeProperties,
					buttons: {
						'close': () => {}
					}
				});
			});
	},

	/**
	 * @param {Function} predicate - Register a predicate function which will execute when the page leave triggered and determine whether prevent page leave (show special dialog).
	 * @param {Function} saveFunc
	 */
	preventPageLeave: function(predicate, saveFunc, owner) {
		owner = owner || app.utils;
		let predicateCheck = () => {
			if (predicate()) {
				return 'prevent';
			}
		};
		$(window).on('beforeunload', predicateCheck);
		if(owner != app.utils) {
			owner.preventPageLeave = {}
		}
		owner.preventPageLeave.predicate = predicate
		owner.preventPageLeave.save = saveFunc
		owner.preventPageLeave.removeEventHandler = () => {
			$(window).off('beforeunload' , predicateCheck);
		}
	},

	stringView: function (stringViewPromise: Promise<string>, viewId: ?string, metaObjectId: string): string {
		const spanClass = viewId + '_' + metaObjectId;
		stringViewPromise
			.then(stringView => {
				let spanStringView = document.getElementsByClassName(spanClass);
				for(let i = 0; i < spanStringView.length; i++) {
					spanStringView[i].textContent = stringView;
					spanStringView[i].setAttribute('title', stringView)
					spanStringView[i].classList.remove(spanClass);
				}
			}).catch(() => {
				const spanStringView = document.getElementsByClassName(spanClass);
				for(let i = 0; i < spanStringView.length; i++) {
					spanStringView[i].innerHTML = app.getResource('failed.to.load.string.view');
					spanStringView[i].classList.remove(spanClass);
				}
			});
		return `<span class="${spanClass}">${app.getResource('loading.string.view')}</span>`;
	},

	filterCollection: function(collection, filters) {
		const rpn = this._parseRPN(filters);
		return collection.filter((model) => {
			return this.filterModel(model, filters, rpn);
		});
	},

	filterModel: function(model, filters, rpn) {
		rpn = rpn || this._parseRPN(filters);
		var that = this;
		if (model.get('clientState') == States.DELETED) {
			return false;
		} else {
			if (rpn) {
				return rpn(model);
			} else {
				var filtersConjaction = true;
				_.each(filters, function (filter) {
					if (!that._calcFilter(model, filter)) {
						filtersConjaction = false;
					}
				});
				return filtersConjaction;
			}
		}
	},

	_parseRPN: function (filters) {
		var that = this;
		var stack = [];

		var isRPN = false;
		_.each(filters, function (filter) {
			if (filter.op) {
				isRPN = true;
			}
		});

		if(!isRPN) {
			return null;
		}

		_.each(filters, function (filter) {
			if (filter.op) {
				var second = stack.pop();
				var first = stack.pop();
				if (filter.op == 'AND') {
					stack.push(function (model) {
						return first(model) && second(model);
					});
				} else {
					stack.push(function (model) {
						return first(model) || second(model);
					});
				}
			} else {
				stack.push(function (model) {
					return that._calcFilter(model, filter);
				});
			}
		});

		return stack.pop();
	},

	_calcFilter: function (model, filter, typeId) {
		let value;
		let fieldType;
		let filterValue = filter.value;
		if (filter.field && filter.field.id) {
			const field = app.fields.get(filter.field.id);
			fieldType = field.type();
			const fieldName = field.fieldName();
			value = model.get(fieldName);
			if(field.type().get('primitiveEntityType') == PrimitiveEntityType.STRING) {
					value = value.getCurrentValue();
			}
		} else {
			value = model.id;
			fieldType = app.types.get(Constants.ID_TYPE_INTEGER_NULLABLE);
			filterValue = filterValue && filterValue.id;
		}
		return this._compare(filter.kind, value, filterValue, fieldType);
	},

	_compare: function (kind, first, second, type) {
		const diff = this.comperator(first, second, type);
		switch (kind) {
		case 'EQUAL':
			return diff === 0;
		case 'GREATER_EQUAL':
			return diff === 0 || diff > 0;
		case 'GREATER':
			return diff > 0;
		case 'LESS':
			return diff < 0;
		case 'LESS_EQUAL':
			return diff === 0 || diff < 0;
		case 'LIKE':
			if (!first && !second) {
				return true;
			}
			first = first + '';
			second = second + '';
			return (first && second) ?
				first.toLowerCase().indexOf(second.toLowerCase()) > -1 : false;
		case 'NOT_EQUAL':
			return diff !== 0;
		case 'NULL':
			return diff === 0;
		case 'NOT_NULL':
			return diff !== 0;
		default:
			break;
		}
	},

	comperator: function(first, second, type) {
		var diff = NaN;
		if ((first === null || first === undefined) &&
			(second === null || second === undefined)) {
			return 0;
		} else if (first === null || first === undefined) {
			return -1;
		} else if (second === null || second === undefined) {
			return 1;
		} else {
			switch(type.get('primitiveEntityType')) {
				case PrimitiveEntityType.INTEGER:
				case PrimitiveEntityType.DOUBLE:
				case PrimitiveEntityType.BOOLEAN:
				case PrimitiveEntityType.DECIMAL:
				case PrimitiveEntityType.SYSTEM_STRING:
				case PrimitiveEntityType.STRING:
					return (first > second) - (first < second);
				case PrimitiveEntityType.TIMESTAMP:
				case PrimitiveEntityType.DURATION:
				case PrimitiveEntityType.LOCAL_DATE:
				case PrimitiveEntityType.LOCAL_TIME:
				case PrimitiveEntityType.LOCAL_DATE_TIME:
				case PrimitiveEntityType.DAY_OF_WEEK:
				case PrimitiveEntityType.MONTH:
				case PrimitiveEntityType.MONTH_DAY:
				case PrimitiveEntityType.YEAR:
				case PrimitiveEntityType.YEAR_MONTH:
					return first.compareTo(ModelFactory.getPrimitiveType(type.get('primitiveEntityType')).fromJSON(second));
				default:
					return (first.id > second.id) - (first.id < second.id);
			}
		}
	},

	/**
	 * Copy your text to clipboard programmatically
	 * @param {string} value A text to be copied
	 */
	setClipboard: function (value) {
		var tempInput = document.createElement("input");
		tempInput.style = "position: absolute; left: -1000px; top: -1000px";
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);
	},

	deferedPromise: function() {
		let p = {
			resolve: null,
			promise: null,
			reject: null
		};
		p.promise = new Promise((res, rej) => {
			p.resolve = res;
			p.reject = rej;
		});
		return p;
	},

	/**
	 * @param {Binary} file - binary file to load
 	 */
	downloadFile: function(file) {
		const a = document.createElement("a");
		a.setAttribute('download', null);
		a.style.display = 'none';
		document.body.appendChild(a);
		let url = app.urls.download(file);
		a.setAttribute('href', url);
		a.click();
		document.body.removeChild(a);
	},

	downloadBlob (blob, fileName) {
		//IE11 & Edge
		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, fileName)
		} else {
			//In FF link must be added to DOM to be clicked
			var link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.setAttribute('download', fileName)
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	},

	/**
	 * Download Binary Large OBject from remote URL
	 * @param {string} url The URL to make request to
	 * @param {object} json POST request body
	 * @param {string} filename Preferred name to save the file with
	 */
	downloadBlobByUrl(url, json, filename) {

		fetch(url, {
			method: 'POST',
			body: JSON.stringify(json),
			headers: _.extend(utils.getCSRFHeaders(), {
				"Content-Type": "application/json"
			})
		})
		.then(async (response) => {
			if (response.ok) {
				if (!filename) {
					const header = response.headers.get('content-disposition');
		 			filename =  header.match(/filename="(.+)"/)[1];
				}
				const blob = await response.blob();
				utils.downloadBlob(blob, filename);
			} else {
				const data = await response.json();
				if (data.message) {
					app.notificationManager.showMessage(data.message, 'error');
				} else {
					utils.error(data);
				}
			}
		})
		.catch((error) => {
			utils.error(error);
		});
	},

	htmlToText (htmlString) {
		const doc = new DOMParser().parseFromString(htmlString, 'text/html');
		return doc.body.textContent || "";
	},

	getIdOrCid (row) {
		return row.id || ('c' + row.clientId)
	},

	isIdInPredefinedRange (id) {
		return Constants.ID_SEQUENCE_OFFSET.lte(id) && Constants.ID_SEQUENCE_OFFSET.mul(2).gte(id)
	}
};

export function buildDefaultPopover($popovers, options){
	$popovers.each((num, popover) => {
		var $popover = $(popover)
		options = options || {}
		options = Object.assign({trigger: 'manual', animation: false}, options)
		if ($popover.hasClass('append-to-body')) {
			options.container = $('body')
		}
		var popover = $popover.popover(options)
		if (options.trigger === 'manual') {
			popover.on('mouseenter', function () {
				var that = this
				that.leaved = false
				$(that).on('mouseleave', function () {
					that.leaved = true
				})
				setTimeout(function () {
					if (!that.leaved) {
						$(that).popover('show')
						$('.popover').find('span.help-popover-close').on('click', function () {
							$(that).popover('hide')
						})
						$('.popover').on('mouseleave', function () {
							$(that).popover('hide')
						})
					}
				}, options.delay || 0)}).on('mouseleave', function () {
					var _this = this
					setTimeout(function () {
						if (!$('.popover:hover').length) {
							$(_this).popover('hide')
						}
					}, 300)
				})
			} else {
				popover.on('click', function (e) {
					popover.popover('show')
					$('body').one('click', function (e) {
						popover.popover('hide')
					})
					e.stopPropagation()
				}).on('mouseout', function (e) {
					var _this = this
					setTimeout(function () {
						if (!$('.popover:hover').length) {
							$(_this).popover('hide')
						}
					}, 1500)
				})
			}
		})
	}

export function handleDisableAll(target) {
	target.find('.disable-all, .disable-all *').prop('disabled', 'disabled').attr('data-disabled', 'true')
}

export function postAndRedirect(url) {
	const form = document.createElement('form');
	form.method = 'post';
	form.action = url;

	const CSRFtoken = document.querySelector("meta[name='_csrf']").content;
	const CSRFparameter = document.querySelector("meta[name='_csrf_parameter']").content;
	const input = document.createElement('input');
	input.type = 'hidden';
	input.name = CSRFparameter;
	input.value = CSRFtoken;
	form.appendChild(input);

	form.style.hidden = true;
	document.body.appendChild(form);

	form.submit();
}

/**
 * @param {string} url - URL to download script from
 */
export function getScript (url) {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: "GET",
      url: url,
      success: resolve,
      dataType: "script",
      cache: true,
      async: true
    })
  })
}

function getScriptSynchronously (url) {
		var xhrObj = new XMLHttpRequest();
		xhrObj.open('GET', url, false);
		xhrObj.send('');
		const script = xhrObj.responseText;
		var result = function(str){
		  return eval(str);
		}.call(window, script);
}
export { getScriptSynchronously }

/**
 * @param {string} url - PathToCSS to download css from
 */
export function getStyle (pathToCSS) {
	return new Promise((resolve, reject) => {
		let link = document.createElement('link')
		link.setAttribute('rel', 'stylesheet')
		link.setAttribute('type', 'text/css')
		link.setAttribute('href', pathToCSS)
		link.onload = resolve
		document.getElementsByTagName('head')[0].appendChild(link)
	})
}

export function farFutureDate() {
	let current = new Date();
	current.setTime(current.getTime() + 30 * 24 * 60 * 60 * 1000);
	return current;
}

export function addCookie(options) {
	document.cookie = `${options.name}=${options.value};expires=${options.expiresTime};path=${options.path}`
}

/**
* The function is used to load moment.timezone.main.js lazily
*/
export function loadMomentJsTimezonePackage() {
	if (global.moment && global.moment.tz) {
		return;
	}
	// moment.timezone.main.js calls require('moment'),
	// but this function is not defined in runtime
	let originalRequire = global.require;
	global.require = function(module) {
		if (module == 'moment') {
			return global.moment;
		} else if (originalRequire) {
			return originalRequire.apply(this, arguments);
		} else {
			return null;
		}
	}
	getScriptSynchronously('/resources/bundles/moment.timezone.main.js');
}


export function subscribeToHubLogin() {
	if (!app.hubRoot) {
		throw new Error('app.hubRoot is empty');
	}
	window.addEventListener('message', function (e) {
		if (e.origin != app.hubRoot) {
			return;
		}
		if (e.data === 'login') {
			window.location.href = app.urls.hubLogin();
		}
	}, false);
}

export function getView(viewId, typeId){
	let url = 'entityView/condensed/?'
	if (!viewId){
		url += 'typeId=' + typeId
	} else {
		url += 'viewId=' + viewId
	}
	if (!app.builderMode) {
		url += `&v=${app.info.buildNumber}`
	}
  return utils.getRequest(app.urls.home + url)
}

export function calcFixedTopOffset(scrollTop, parentElementOffsetTop, elementHeight, parentHeight) {
	if ((window.innerHeight + window.pageYOffset) >= (parentElementOffsetTop + elementHeight)){
		return 0 - parentHeight - scrollTop
	} else {
		return 0 - elementHeight - scrollTop
	}
}

export function openLink(target, e, parentModel) {
	let openMode = target.data('kind'), objectId = target.data('objectid'), typeId = target.data('type'),
	linkFormView = target.data('linkform'), isIndex = target.data('index');
	showInstance({openMode: openMode,
		exactParent: parentModel,
		openInNewTab:	e && e.button == 1,
		objectId: objectId,
		typeId: typeId,
		viewId: linkFormView,
		isIndex: isIndex,
		callback: () => {
			app.entityManager.evictStringView(objectId);
			app.entityManager.fetchStringView(null, objectId).then((data) => {
				target.text(data);
			});
		}
	})
}

function buildUrl(options) {
	if (options.isIndex) {
		return app.urls.indexPage(options.typeId, {
			rowViewId: options.viewId
		});
	} else if (options.objectId && options.typeId) {
		return app.urls.update(options.typeId, options.objectId, {
			formViewId: options.viewId,
			edit: options.edit
		});
	} else if (options.objectId && !options.typeId) {
		return app.urls.openDynamicInstance(options.objectId);
	} else {
		return app.urls.create(options.typeId, options.viewId, {
			parentId: options.parentId,
			fillFunctionId: options.fillFunctionId,
			fillInstanceId: options.fillInstanceId
		});
	}
}

export function openPresentationUrl(url, openMode, opts) {
	const callback = opts && opts.callback && (data => opts.callback(data));
	const forceReplace = opts && opts.forceReplace;
	if (openMode == OpenMode.NEW_CJ_TAB && app.cjTabs.canShow()) {
		return app.cjTabs.createPresentationTab(url, callback, opts);
	} else if (openMode == OpenMode.NESTED_WINDOW
			&& (app.instanceViewer.canShow() || forceReplace)) {
		return app.instanceViewer.show(url, callback, opts);
	} else if (openMode == OpenMode.SAME_CJ_TAB && app.cjTabs.canShow()) {
		return app.cjTabs.showInCurrentTab(url, opts)
	} else if (openMode == OpenMode.SAME_BROWSER_TAB) {
		return utils.redirectTo(url, false);
	} else {
		return utils.redirectTo(url, true);
	}
}

export function showInstance(options){
	options.openInNewTab = options.openInNewTab || options.openMode == 'open.in.new.tab'
	const url = buildUrl(options);
	let openMode = options.openMode;
	if (options.openInNewTab) {
		openMode = OpenMode.NEW_BROWSER_TAB;
	}
	return openPresentationUrl(url, openMode, options);
}

export function removeNotActiveLanguages($el) {
	let translatable = $el.find('.translatable');
	app.disabledLanguages.forEach((lang) => {
		translatable.find('[data-language="' + lang.languageTag + '"]').remove();
	});
}
export function getListPropValue(listProperties, key) {
	if (listProperties && listProperties.get(key)) {
		return listProperties.get(key).get('value')
	}
	return null
}

export function defineHeaderViewOnBuilder(additionalSpaceOnButtons) {
	let $fixedToolbar = $('.fixed-toolbar')
	let $contentHeader = $('.fixed-toolbar .content-header')
	$fixedToolbar.removeClass('only-icon-buttons two-rows')
	let difference = $contentHeader.width() - ($contentHeader.find('.pull-left').width() + $contentHeader.find('.pull-right').width())
	if ( additionalSpaceOnButtons && difference < 0 && difference > -additionalSpaceOnButtons ) {
		$fixedToolbar.addClass('only-icon-buttons')
	} else if ( (additionalSpaceOnButtons && difference < -additionalSpaceOnButtons) || (!additionalSpaceOnButtons && difference < 0)) {
		$fixedToolbar.addClass('two-rows')
	}
}

export function defineHeaderEventsInit(additionalSpaceOnButtons) {
	let elementsForReinitHeader = [$('#close-tutorial'), $('#tutorials-button'), $('#home'), $('.sidebar-toggle')]
	elementsForReinitHeader.forEach(($el) => {
		$el.on('transitionend', () => {
			defineHeaderViewOnBuilder(additionalSpaceOnButtons)
		})
	})
	$(window).resize(() => { defineHeaderViewOnBuilder(additionalSpaceOnButtons) })
	defineHeaderViewOnBuilder(additionalSpaceOnButtons)
}

export function checkRequired(data, $el) {
	let missedFields = []
	Object.keys(data).forEach((attr) => {
		let $elem = $el.find(`[data-field=${attr}]`)
		if (!!$elem.data('required') && !$elem.val()) {
			missedFields.push(attr)
		}
	})
	return missedFields
}


export default utils;
