import PropertyKey from '../../../common/enums/propertyKey.js';
import ViewKind from '../../../common/enums/viewKind';

function _specialLabels () {
  var specialLabels = {}
  specialLabels['number'] = {}
  specialLabels['time'] = {}
  specialLabels['period'] = {}
  specialLabels['string'] = {}
  specialLabels['binary'] = {}
  specialLabels['boolean'] = {}

  specialLabels['string'][PropertyKey.MIN] = app.getResource('minimal.length');
  specialLabels['string'][PropertyKey.MAX] = app.getResource('maximal.length');
  specialLabels['binary'][PropertyKey.MAX] = app.getResource('maximal.size');
  specialLabels['boolean'][PropertyKey.TEMPLATE] = app.getResource('view.mode');
  return specialLabels
}

function _rowLabels () {
  var rowLabels = {}
  rowLabels[PropertyKey.HIDDEN] = app.getResource('code.only')
  return rowLabels
}

function _listLabels () {
  var listLabels = {}
  listLabels[PropertyKey.VIEW] = app.getResource('table.view')
  return listLabels
}

var labels
var specialLabels
var rowLabels
var listLabels

function propertyLabel(key, renderType, viewKind) {
  labels = labels || _labels()
  specialLabels = specialLabels || _specialLabels()
  rowLabels = rowLabels || _rowLabels()
  listLabels = listLabels || _listLabels()
  let label = labels[key]
  if (renderType){
    let special = specialLabels[renderType][key]
    if (special) {
      label = special
    }
  }
  if (viewKind && viewKind == ViewKind.ROW) {
    let special = rowLabels[key]
    if (special) {
      label = special
    }
  }
  if (viewKind && viewKind == ViewKind.LIST) {
    let special = listLabels[key]
    if (special) {
      label = special
    }
  }
  return label
}

function _labels () {
  var labels = {};
  labels[PropertyKey.TITLE] = app.getResource('title');
  labels[PropertyKey.SIZE] = app.getResource('size');
  labels[PropertyKey.SHOW_HEADER] = app.getResource('show.header');
  labels[PropertyKey.COLLAPSE] = app.getResource('collapse');
  labels[PropertyKey.TRIGGER] = app.getResource('custom.trigger');
  labels[PropertyKey.VIEW] = app.getResource('view');
  labels[PropertyKey.NAME] = app.getResource('name');
  labels[PropertyKey.COMMENT] = app.getResource('comment');
  labels[PropertyKey.VALIGN] = app.getResource('vertical.alignment');
  labels[PropertyKey.MULTILINE] = app.getResource('multiline');
  labels[PropertyKey.TYPE] = app.getResource('content.type');
  labels[PropertyKey.TEMPLATE] = app.getResource('template.title.formbuilder');
  labels[PropertyKey.CAN_ADD] = app.getResource('can.add');
  labels[PropertyKey.CAN_DELETE] = app.getResource('can.delete');
  labels[PropertyKey.MIN] = app.getResource('minimal.value');
  labels[PropertyKey.MAX] = app.getResource('maximum.value');
  labels[PropertyKey.STEP] = app.getResource('step');
  labels[PropertyKey.PATTERN] = app.getResource('pattern');
  labels[PropertyKey.CSS] = app.getResource('css');
  labels[PropertyKey.STYLE] = app.getResource('style');
  labels[PropertyKey.SOLID] = app.getResource('solid');
  labels[PropertyKey.LABEL] = app.getResource('label');
  labels[PropertyKey.LINK] = app.getResource('link');
  labels[PropertyKey.FIELD_LABEL] = app.getResource('use.field.label');
  labels[PropertyKey.SHARED_STYLES] = app.getResource('shared.styles');
  labels[PropertyKey.REQUIRED] = app.getResource('required');
  labels[PropertyKey.DISABLED] = app.getResource('disabled');
  labels[PropertyKey.HIDDEN] = app.getResource('hidden');
  labels[PropertyKey.IDENTIFIER] = app.getResource('identifier');
  labels[PropertyKey.BEFORE_CUSTOM_SERVER_EVENT] = app.getResource('before.server.event');
  labels[PropertyKey.CUSTOM_SERVER_EVENT] = app.getResource('server.event'),
  labels[PropertyKey.AFTER_CUSTOM_SERVER_EVENT] = app.getResource('after.server.event');
  labels[PropertyKey.PREVIEW] = app.getResource('preview');
  labels[PropertyKey.UPLOAD] = app.getResource('source');
  labels[PropertyKey.ZOOM] = app.getResource('zoom');
  labels[PropertyKey.DATA_SOURCE] = app.getResource('data.source');
  labels[PropertyKey.READ_ONLY_VIEW] = app.getResource('read.only.mode.view');
  labels[PropertyKey.ICON] = app.getResource('icon');
  labels[PropertyKey.ALIGN] = app.getResource('horizontal.alignment');
  labels[PropertyKey.HEADER] = app.getResource('header');
  labels[PropertyKey.READ_ONLY] = app.getResource('read.only');
  labels[PropertyKey.IS_LINK] = app.getResource('is.link');
  labels[PropertyKey.CLASSES] = app.getResource('classes');
  labels[PropertyKey.SHOW_TOOLBAR] = app.getResource('show.toolbar');
  labels[PropertyKey.FORM_OPTIONS] = app.getResource('form.options');
  labels[PropertyKey.BACK_LINK] = app.getResource('back.link');
  labels[PropertyKey.DROPDOWN_STYLE] = app.getResource('dropdown.style');
  labels[PropertyKey.BUTTON_STYLE] = app.getResource('button.style');
  labels[PropertyKey.ADD_ACTION] = app.getResource('add.alternative.action');
  labels[PropertyKey.DROPDOWN_ALIGN] = app.getResource('dropdown.align');
  labels[PropertyKey.HAS_CREATE_NEW] = app.getResource('has.create.new');
  labels[PropertyKey.HAS_POST_COLUMN] = app.getResource('has.post.column');
  labels[PropertyKey.CAN_REORDER] = app.getResource('can.reorder');
  labels[PropertyKey.PLACEHOLDER] = app.getResource('placeholder');
  labels[PropertyKey.HAS_VIEW_LINK] = app.getResource('has.view.link');
  labels[PropertyKey.MAX_ROW_COUNT] = app.getResource('max.row.count');
  labels[PropertyKey.HAS_FOLDER_TREE] = app.getResource('has.folder.tree');
  labels[PropertyKey.USE_TREE_ONLY] = app.getResource('use.tree.only');
  labels[PropertyKey.IS_TREE_SELECTABLE] = app.getResource('is.tree.selectable');
  labels[PropertyKey.SHOW_ALL_CHILDREN] = app.getResource('show.all.children');
  labels[PropertyKey.MIN_DATE] = app.getResource('min.date');
  labels[PropertyKey.MAX_DATE] = app.getResource('max.date');
  labels[PropertyKey.WIDTH] = app.getResource('width');
  labels[PropertyKey.HIDE_TITLE_XS] = app.getResource('hide.title.xs');
  labels[PropertyKey.ICON_COLOR] = app.getResource('icon.color');
  labels[PropertyKey.LINK_FORM_VIEW] = app.getResource('link.form.view');
  labels[PropertyKey.COMMENT_PLACEMENT] = app.getResource('comment.placement');
  labels[PropertyKey.HIDE_CHECKBOX] = app.getResource('hide.checkbox');
  labels[PropertyKey.HIDE_FILLFUNCTIONS] = app.getResource('hide.fillfunctions');
  labels[PropertyKey.INITIAL_STATE] = app.getResource('initial.state');
  labels[PropertyKey.CUSTOM_RESOURCES] = app.getResource('custom.resources');
  labels[PropertyKey.CLIENT_PAGE_TITLE] = app.getResource('client.page.title');
  labels[PropertyKey.CLIENT_PAGE_HEAD_ENTRIES] = app.getResource('client.page.head.entries');
  labels[PropertyKey.STATIC_RESPONSE] = app.getResource('static.response');
  labels[PropertyKey.USE_STATIC_RESPONSE_FOR_CRAWLER] = app.getResource('use.static.response.for.crawler');
  labels[PropertyKey.USE_STATIC_RESPONSE_FOR_BROWSER] = app.getResource('use.static.response.for.browser');
  labels[PropertyKey.DONT_SHOW_BY_DEFAULT] = app.getResource('hidden');
  labels[PropertyKey.FORMAT] = app.getResource('format');
  labels[PropertyKey.DEFAULT_SORTING] = app.getResource('default.sorting');
  labels[PropertyKey.LINK_ACTION] = app.getResource('link.action');
  labels[PropertyKey.LOAD_IN_EDIT_MODE] = app.getResource('load.in.edit.mode');
  labels[PropertyKey.FIELD_TYPE] = app.getResource('field.type.text');
  labels[PropertyKey.FIELD_NAME] = app.getResource('field.name');
  labels[PropertyKey.OPEN_LINK_IN_SAME_WINDOW] = app.getResource('open.link.in.same.window');
  labels[PropertyKey.MINUTES_STEP] = app.getResource('minutes.step');
  labels[PropertyKey.TIME_FORMAT] = app.getResource('time.format');
  labels[PropertyKey.HTML_STRING_POPOVER] = app.getResource('html.string.popover');
  labels[PropertyKey.FORMATTER] = app.getResource('formatter');
  labels[PropertyKey.CLIENT_VIEW] = app.getResource('client.view')
  labels[PropertyKey.SHOW_DATE_TIME_IN_POPOVER] = app.getResource('show.date.time.in.popover')
  labels[PropertyKey.DURATION_FORMATTER] = app.getResource('duration.formatter')
  labels[PropertyKey.ITEM_KIND] = app.getResource('component')
  labels[PropertyKey.FIELD_EVENTS] = app.getResource('field.events')
  labels[PropertyKey.SELECT_WIDTH] = app.getResource('select.width')
  labels[PropertyKey.TRACK_CHANGES_ON_PAGE] = app.getResource('track.changes.on.page')
  labels[PropertyKey.CSS_THEME] = app.getResource('css.theme')
  labels[PropertyKey.CREATE_NEW_VIEW] = app.getResource('create.new.link');
  labels[PropertyKey.OPEN] = app.getResource('open');
  labels[PropertyKey.ROW_HEIGHT] = app.getResource('row.height');
  labels[PropertyKey.SELECTION_TYPE] = app.getResource('selection.type');
  labels[PropertyKey.WIDGET_HEIGHT] = app.getResource('widget.height')
  labels[PropertyKey.COLUMNS_QUANTITY] = app.getResource('columns.quantity')
  labels[PropertyKey.PAGE_SIZE] = app.getResource('page.size')
  labels[PropertyKey.WIDTH_CRITERIA] = app.getResource('width.criteria')
  labels[PropertyKey.TREE_VIEW_KIND] = app.getResource('tree.view.kind')
  labels[PropertyKey.ADD_STRING_VIEW_TO_TITLE] = app.getResource('add.string.view.to.title')
  labels[PropertyKey.MODAL_WIDTH] = app.getResource('width')
  labels[PropertyKey.MODAL_HEIGHT] = app.getResource('height')
  labels[PropertyKey.MODAL_FLOAT] = app.getResource('placement')

  return labels
}

export default propertyLabel;
