/* @flow */

import utils from '../../common/components/utils';
import BaseModel from '../../common/models/baseModel';
import AppUpdateMode from '../../common/enums/appUpdateMode';
import SystemInstancesMergeModal from '../../deploy/views/systemInstancesMergeModal';
import { translate } from '../../common/service/stringResourceService.js';
import RadioGroupInputs from '../../common/components/radioGroupInputs';
import { buildHelpPopover } from '../../help/docsResource'
import CheckboxInput from '../../common/components/checkboxInput';
import Formatter from '../../common/components/formatter';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType'
import Instant from '../../time/models/instant';
import ApplicationType from '../../common/enums/applicationType';
import {renderDeployList} from "../utils";
import {multilingualStringService} from "../../common/service/multilingualStringService";
import DeploymentForbiddanceReason from "../../common/enums/deploymentForbiddanceReason";

export default class DeploymentOptionsModal extends Backbone.View {

	initialize () {
		let $el = $('#deploy-opts-modal');
		if ($el.length == 0) {
			var el = document.createElement('div')
			el.id = 'deploy-opts-modal'
			el.classList.add('modal')
			el.classList.add('fade')
			document.body.appendChild(el)
		}
		$el = $('#deploy-opts-modal')
		$el.html(this.getHTML())
		this.setElement($el);
		this.deployBtn = this.$el.find('.okButton');
	}

	getHTML () {
		return `
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header create-modal-header">
							<h4>
							<span id="headerText"></span>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
							</button>
							</h4>
						</div>
						<div class="modal-body row">
							<div id="firstStepDeploy" class="col-lg-6">
								<h4 style="margin-top: 0; margin-bottom: 20px;">${app.getResource('app.instances')}</h4>
								<ul id="application-select" style="list-style-type: none;padding-inline-start:0px"></ul>
							</div>
							<div id="secondStepDeploy" class="col-lg-6" style="display: none;margin-top: 10px;">
							</div>
							<div class="choose-any-app-to-deploy col-lg-6" style="padding-top: 20px;text-align: center;font-size: 16px;font-weight: 500;color: #a9a9a9">
								${multilingualStringService.formatSystemString(app.getResource('choose.any.app.to.deploy'), 
								[`<a href="${app.urls.dashboardContainerManagement}">Container management</a>`])}
							</div>
						</div>
						<div class="modal-footer" style="margin-top: 30px">
							<button type="button" class="btn btn-default pull-left" data-action="cancel"
									data-dismiss="modal">${app.getResource('cancel')}
							</button>
							<button type="button" class="btn btn-danger pull-right okButton" data-action="create" disabled="true"
									data-dismiss="modal">${app.getResource('ok')}
							</button>
						</div>
					</div>
				</div>`;
	}

	show (options: Object) {
		const that = this;

		this.model = options.model || new BaseModel({
				applicationUpdateMode: AppUpdateMode.READ_ONLY
			});

		this.headerResource = options.headerResource;
		this.isDev = options.isDev;

		this.$el.find('.modal-header h4 #headerText').html(options.headerResource);

		this.deployBtn.off('click').on('click', e => {
				if (options.onSubmit) {
					const application = _.find(that.applications, {instanceId: that.selectedAppId})
					const isRemote = ApplicationType.isRemote(application.applicationType)
					that.model.set('appId', that.selectedAppId)
					that.model.set('isRemote', isRemote)
					options.onSubmit(that.model);
				}
				that.$el.modal('hide');
				e.stopPropagation();
			});

		if (options.okResource) {
			this.deployBtn.text(translate(options.okResource));
		}
		utils.getRequest(app.urls.getApps).then(applications => {
			this.applications = applications;
			renderDeployList({
				$el: this.$el.find('#application-select'),
				applications: this.applications
			});
			this.models = new Backbone.Collection();
			this.$el.find('.selection-checkbox').each((idx, el) => {
				let model = new Backbone.Model({
					app: this.applications[idx],
					isSelected: false
				});
				that.models.add(model);
				new CheckboxInput({
					el: el,
					model: this.models.at(idx),
					modelAttr: 'isSelected'
				});
			});
			this.listenTo(this.models, 'change', this.onChange);
		});

		this.$el.modal('show')
	}

	_onCustomMergeModeOpts(el) {
		const that = this;
	 	const systemInstancesMergeModal = new SystemInstancesMergeModal();
		systemInstancesMergeModal.show({
				 headerResource: translate('choose.seed.instances'),
				 onSubmit: (instancesToMerge) => {
					 that.model.set('instancesToMerge', instancesToMerge);
				 }
		 });
		 el.preventDefault();
	}

	renderVersionList(versions, selected) {
		const wrapper = this.$el.find('#versionRadioGroupWrapper');
		wrapper.empty();
		wrapper.append('<div></div>');

		const options = [];

		let selectedValue = null;

		versions
			.sort((a, b) => b.buildNumber - a.buildNumber)
			.forEach(projectJar => {
				if (projectJar.deployEnabled) {

					let versionLabel = projectJar.buildNumber;
					if (projectJar.isDevMode) {
						versionLabel += ` (${app.getResource('dev.build')})`;
					}
					versionLabel += ': ' +
						Formatter.formatWithPredefinedFormat(Instant.fromJSON(projectJar.jarTimestamp), {
							primitiveType: PrimitiveEntityType.TIMESTAMP
						});

					if (projectJar.currentVersion) {
						versionLabel = versionLabel + ' ' + app.getResource('current.application.version');
					}

					const option = {
						value: projectJar.id,
						text: versionLabel
					}

					if (projectJar == selected) {
						selectedValue = option.value;
					}

					options.push(option);
				}
			});

		this.model.set('jarVersion', selectedValue);

		new RadioGroupInputs({
			el: wrapper.find('div')[0],
			options: options,
			value: selectedValue,
			selectionChanged: (value) => {
				this.model.set('jarVersion', value);
			}
		});
	}

	onChange(model) {
		if (model.get('isSelected')) {
			_.each(model.collection.models, (m) => {
				if (m.cid !== model.cid) {
					m.set('isSelected', false);
				}
			});
			this.model.set('app', model.get('app'));
			this.selectedAppId = model.get('app').instanceId;
			this.$el.find('#secondStepDeploy').show();
			this.$el.find('.choose-any-app-to-deploy').hide();
			this.enableSecondDeployStep();
			if (model.get('app').deploymentForbiddanceReason !== DeploymentForbiddanceReason.NONE ||
				(model.get('app').deploymentForbiddanceReason === DeploymentForbiddanceReason.APPLICATION_IS_NOT_ACTIVE &&
					model.get('app').applicationType !== ApplicationType.DEVELOPMENT)) {
				this.deployBtn.prop('disabled', true);
			} else {
				this.deployBtn.prop('disabled', false);
			}
		} else {
			this.deployBtn.prop('disabled', true);
			this.$el.find('.choose-any-app-to-deploy').show();
			this.$el.find('#secondStepDeploy').hide();
		}
	}

	getLastSuccessfulVersion() {
		const lastSuccessfulBuild = this.isDev
			? app.buildView.build.lastSuccessful
			: app.buildView.build.lastSuccessfulFull;

		const initVersions = [];

		if (lastSuccessfulBuild != null) {
			initVersions.push({
				id: -1,
				buildNumber: lastSuccessfulBuild.buildNumber,
				jarTimestamp: lastSuccessfulBuild.buildTimestamp,
				deployEnabled: true,
				currentVersion: false,
				isDevMode: lastSuccessfulBuild.isDevMode
			});
		}

		return initVersions;
	}

	_onUpdateVersions(ev) {
		utils.getRequest(app.urls.getApplicationVersionList(this.selectedAppId))
			.then(data => this.updateVersionsImpl(data))
			.catch((xhr, textStatus, errorThrown) => {
				console.log('Failed to fetch getApplicationVersionList.');

				this.updateVersionsImpl([]);
			});

		if (ev != null) {
			ev.preventDefault();
		}
	}

	updateVersionsImpl(data) {
		let lastSuccessfulBuildInServerList = false;
		const lastSuccessfulBuild = this.getLastSuccessfulVersion();
		const lastSuccessfulBuildExist = lastSuccessfulBuild.length > 0;
		let selected = null;

		this.$el.find('#loadMoreVersionsButton').hide(0);

		data.forEach(pj => {
			if (lastSuccessfulBuildExist) {
				if (pj.buildNumber == lastSuccessfulBuild[0].buildNumber) {
					lastSuccessfulBuildInServerList = true;

					selected = pj;
				}
			}
			else {
				if (pj.currentVersion) {
					selected = pj;
				}
			}
		});

		if (lastSuccessfulBuildExist && !lastSuccessfulBuildInServerList) {
			selected = lastSuccessfulBuild[0];

			data.push(selected);
		}

		if (data.length === 0) {
			if (this.model.get('app').applicationType === ApplicationType.DEVELOPMENT && this.model.get('app').deploymentState) {
				this.$el.find('#selectApplicationVersionLabelDiv').hide(0);
				this.deployBtn.prop('disabled', false);
			} else {
				this.$el.find('#noVersionsAvailable').show(0);
				this.deployBtn.prop('disabled', true);
			}
		}
		else {
			if (selected == null) {
				selected = data[0];
			}

			this.renderVersionList(data, selected);
		}
	}

	enableSecondDeployStep() {
		let that = this;
		this.$el.find('#secondStepDeploy').html(this.getDeployOptionsHTML());
		this.$el.find('.custom-mode-opts-link').off().click((e)=>{this._onCustomMergeModeOpts.call(this,e)})
		this.$el.find('#loadMoreVersionsButton').off().click((ev)=>{this._onUpdateVersions.call(this, ev)})
		new RadioGroupInputs({
			el: that.$el.find('#radioGroup')[0],
			options: [{
				text: translate('update.readonly.system.instances'),
				value: AppUpdateMode.READ_ONLY
			}, {
				text: translate('update.all.system.instances'),
				value: AppUpdateMode.ALL
			}, {
				text: translate('update.specific.system.instances'),
				value: AppUpdateMode.CUSTOM
			}],
			value: that.model.get('applicationUpdateMode'),
			mixin: {
				mounted: function () {
					$(this.$el).find('label').each((i, elem) => {
						let val = $(elem).find('input').val()
						$(elem).append(`
							<a style="margin-left: 5px;" help-popover="deployment.${val}.options">
								<img class="help-image" src="/resources/images/question-mark.svg"/>
							</a>`)
					})

					that.$el.find('[help-popover]').each(function (a, elem) {
						buildHelpPopover((elem))
					});
				}
			},
			selectionChanged: (value) => {
				that.model.set('applicationUpdateMode', value);
				if (value === AppUpdateMode.CUSTOM) {
					$('#forcedUpdateWarningDiv').slideUp()
					$('#customListDiv').slideDown()
				} else if (value === AppUpdateMode.ALL) {
					$('#forcedUpdateWarningDiv').slideDown()
					$('#customListDiv').slideUp()
				} else {
					$('#forcedUpdateWarningDiv').slideUp()
					$('#customListDiv').slideUp()
				}
			}
		})

		const lastSuccessfulVersion = this.getLastSuccessfulVersion();

		if (lastSuccessfulVersion.length == 0) {
			this._onUpdateVersions();
		}
		else {
			this.renderVersionList(lastSuccessfulVersion, lastSuccessfulVersion[0]);
		}
		this.model.set('overrideConfiguration', false);
		new CheckboxInput({
			el: this.$el.find('#overwriteConfiguration')[0],
			model: this.model,
			modelAttr: 'overrideConfiguration'
		})
	}


	getDeployOptionsHTML() {
		return `
								<div class="grid-2x-padding deploy-danger">
									<label>${app.getResource('application.will.be.forced.to.stop')}</label>
								</div>
	
								<div class="grid-2x-padding modal-component" style="padding-left: 20px;">
									<div id="radioGroup"></div>
								</div>
								
								<div class="grid-2x-padding" style="padding-left: 20px;">
									<div id="overwriteConfiguration"></div>
									<span>${translate('overwrite.configuration')}</span>
								</div>
	
								<div id="forcedUpdateWarningDiv" class="deploy-danger" style="display:none; padding-bottom: 16px;">
									<label>${app.getResource('all.system.objects.will.be.updated.on.app')}</label>
								</div>
	
								<div id="customListDiv" class="grid-2x-padding"  style="display:none;">
									<a class="custom-mode-opts-link" href="#">${app.getResource('system.instances.to.merge')}</a>
								</div>
	
								<div id="selectApplicationVersionLabelDiv" class="grid-2x-padding deploy-text-info">
									<label>${app.getResource('select.application.version.for.deploy')}</label>
									<a style="margin-left: 5px;" help-popover="select.application.version.for.deploy.help">
										<img class="help-image" src="/resources/images/question-mark.svg"/>
									</a>
								</div>
	
								<div style="padding-left: 20px;">
									<div id="versionRadioGroupDiv" style="padding-bottom: 16px; max-height: 300px; overflow-y: auto; overflow-x:hidden;">
										<div id="versionRadioGroupWrapper">
										</div>
	
										<div id="noVersionsAvailable" style="display: none;">${app.getResource('no.application.versions.available.for.deploy')}</div>
									</div>
								</div>
	
								<div class="grid-2x-padding" id="loadMoreBox">
									<a id="loadMoreVersionsButton" href="#" style="display: block;">${app.getResource('load.more.application.versions')}</a>
								</div>
		`;
	}

}
