import utils from '../components/primitiveUtils';
import MultilingualString from '../models/multilingualString';

export default class Primitives {
	static SYSTEM_STRING: any = {
		class: 'String',
		blockly: 'String',
		widensTo: ['STRING'],
		castFunction: 'MultilingualString.getValue',
		primitive: null,
		defaultValue: '""',
		jsDefaultValue: '\'\'',
		runtimeDefaultValue: ''
	}

	static STRING: any = {
		class: 'MultilingualString',
		blockly: 'String',
		widensTo: ['SYSTEM_STRING'],
		castFunction: 'MultilingualString.fromString',
		primitive: null,
		defaultValue: 'new MultilingualString()',
		jsDefaultValue: "new app.classes.MultilingualString()",
		runtimeDefaultValue: new MultilingualString()
	}

	static SYSTEM_INTEGER: any = {
		class: 'Integer',
		blockly: 'Number',
		widensTo: ['INTEGER', 'DOUBLE', 'DECIMAL'],
		primitive: 'int',
		castFunction: 'BlockUtils.castToInteger',
		defaultValue: '0',
		jsDefaultValue: '0',
		runtimeDefaultValue: 0
	}

	static INTEGER: any = {
		class: 'Long',
		blockly: 'Number',
		widensTo: ['SYSTEM_INTEGER', 'DOUBLE', 'DECIMAL'],
		primitive: 'long',
		castFunction: 'BlockUtils.castToLong',
		defaultValue: '0L',
		jsDefaultValue: '0',
		runtimeDefaultValue: 0
	}

	static DOUBLE: any = {
		class: 'Double',
		blockly: 'Number',
		widensTo: ['DECIMAL'],
		primitive: 'double',
		castFunction: 'BlockUtils.castToDouble',
		defaultValue: '0d',
		jsDefaultValue: '0',
		runtimeDefaultValue: 0
	}

	static DECIMAL: any = {
		class: 'Double',
		blockly: 'Number',
		widensTo: ['DOUBLE'],
		primitive: 'double',
		castFunction: 'BlockUtils.castToDouble',
		defaultValue: '0d',
		jsDefaultValue: '0',
		runtimeDefaultValue: 0
	}

	static BOOLEAN: any = {
		class: 'Boolean',
		blockly: 'Boolean',
		widensTo: [],
		primitive: 'boolean',
		defaultValue: 'false',
		jsDefaultValue: 'false',
		runtimeDefaultValue: false
	}

	static BINARY: any = {
		class: 'Binary',
		blockly: 'Binary',
		widensTo: []
	}

	static TIMESTAMP: any = {
		class: 'Instant',
		blockly: 'Instant',
		widensTo: []
	}

	static ZONED_DATE_TIME: any = {
		class: 'ZonedDateTime',
		blockly: 'ZonedDateTime',
		widensTo: []
	}

	static PERIOD: any = {
		class: 'Period',
		blockly: 'Period',
		widensTo: []
	}

	static DURATION: any = {
		class: 'Duration',
		blockly: 'Duration',
		widensTo: []
	}

	static LOCAL_DATE: any = {
		class: 'LocalDate',
		blockly: 'LocalDate',
		widensTo: []
	}

	static LOCAL_TIME: any = {
		class: 'LocalTime',
		blockly: 'LocalTime',
		widensTo: []
	}

	static LOCAL_DATE_TIME: any = {
		class: 'LocalDateTime',
		blockly: 'LocalDateTime',
		widensTo: []
	}

	static DAY_OF_WEEK: any = {
		class: 'DayOfWeek',
		blockly: 'DayOfWeek',
		widensTo: []
	}

	static MONTH: any = {
		class: 'Month',
		blockly: 'Month',
		widensTo: []
	}

	static MONTH_DAY: any = {
		class: 'MonthDay',
		blockly: 'MonthDay',
		widensTo: []
	}

	static YEAR: any = {
		class: 'Year',
		blockly: 'Year',
		widensTo: []
	}

	static YEAR_MONTH: any = {
		class: 'YearMonth',
		blockly: 'YearMonth',
		widensTo: []
	}

	static ZONE_OFFSET: any = {
		class: 'ZoneOffset',
		blockly: 'ZoneOffset',
		widensTo: []
	}

	static CHRONO_FIELD: any = {
		class: 'ChronoField',
		blockly: 'ChronoField',
		widensTo: []
	}

	static AGGREGATE_KIND: any = {
		class: 'AggregateKind',
		blockly: 'AggregateKind',
		widensTo: []
	}

	static FILTER_OPERATOR: any = {
		class: 'FilterOperator',
		blockly: 'FilterOperator',
		widensTo: []
	}

	static FILTER_KIND: any = {
		class: 'FilterKind',
		blockly: 'FilterKind',
		widensTo: []
	}

	static DOCUMENT_REGISTER_STATE: any = {
		class: 'DocumentRegisterState',
		blockly: 'DocumentRegisterState',
		widensTo: [],
		runtimeDefaultValue: null
	}

	static META_OBJECT_STATE: any = {
		class: 'MetaObjectState',
		blockly: 'MetaObjectState',
		widensTo: [],
		runtimeDefaultValue: null
	}

	static LOCAL_DATE_RANGE: any = {
		class: 'LocalDateRange',
		blockly: 'LocalDateRange',
		defaultValue: 'new LocalDateRange()',
		jsDefaultValue: 'new app.classes.LocalDateRange()',
		widensTo: []
	}

	static JSON_NODE = {
		class: 'JsonNode',
		blockly: 'JsonNode',
		widensTo: []
	}
}
