import StateRecovery from '../components/stateRecovery'

const baseOptions = ['fieldsToShow', 'filters']
const propsSavedInUIComp = ['columsSizes', 'sorting']
const shouldDeleteKey = (key) => { return propsForClean.includes(key) || key.includes('undefined') }
const rewriteOldKey = (key) => {
	if (key.startsWith('_') && key.endsWith('f')) {
		key = app.currentUser ? app.currentUser.id : 'anonymous' + key
	}
	return key
}

let LocalStorageKeysService = {
	// Use this keys for filters, extended mode amd fields to show
	buildCollectionKey: (storedId) => { return (app.currentUser ? app.currentUser.id : 'anonymous') + '__' + storedId },
	// Use this key to save if folders tree is open
	buildKeyForFoldersTree: (fieldId) => { return 'foldersClosed-' + fieldId },
	// Use this key for columsSizes and sorting
	buildKeyForComponent: (fieldId) => { return (app.currentUser ? app.currentUser.id : 'anonymous') + '_' + fieldId },
	// Use this key for not show attributes
	buildKeyForNotShow:  (attr) => { return 'not_show_' + attr },
	parseKeyAndCheck: function (key) {
		key = rewriteOldKey(key)
		let id = null, checkedResult = null
		if (key.startsWith('anonymous') || (app.currentUser && key.startsWith(app.currentUser.id))) {
			if (key.endsWith('f')) {
				id = key.substring(1, key.length - 1)
				if (id.includes('_')) {
					if (id.includes('index-table')) {
						id = id.substring(0, id.indexOf('_'))
					} else {
						id = id.substring( id.indexOf('_') + 1 )
					}
				}
				checkedResult = this.checkFiltersAndFieldsAndExtendMode(key)
			} else {
				id = key.substring(key.indexOf('_') + 1)
				if (id.includes('index-table')) {
					id = key.substring(key.indexOf('index-table') + 11)
				} else if (id.includes('-')) {
					id = key.substring(key.indexOf('_') + 1, key.indexOf('-'))
				}
				checkedResult = this.checkColumsSizesAndSorting(key)
			}
		} else if (key.startsWith('foldersClosed')) {
			id = key.substring(19)
			if (app.fields.get(id)) {
				checkedResult = StateRecovery.get(key)
			}
		}
		if (shouldDeleteKey(key) || (id && ((_.isObject(checkedResult) && _.isEmpty(checkedResult)) || checkedResult == null))) {
			delete localStorage[key]
		} else if (id) {
			StateRecovery.put(key, checkedResult)
		}
		return id
	},
	checkFiltersAndFieldsAndExtendMode: function (key) {
		let savedObject = StateRecovery.get(key)
		if (!savedObject) {
			return {}
		}
		let checkedResult = {}, fieldsToShow = [], filters = []
		savedObject.fieldsToShow && savedObject.fieldsToShow.forEach((f) => {
			if (getField(f)) {
				fieldsToShow.push(f)
			}
		})
		savedObject.filters && savedObject.filters.forEach((f) => {
			if (getField(f.field.id)) {
				filters.push(f)
			}
		})
		if (fieldsToShow.length) {
			checkedResult['fieldsToShow'] = fieldsToShow
		}
		if (filters.length) {
			checkedResult['filters'] = filters
		}
		return checkedResult
	},
	checkColumsSizesAndSorting: function (key) {
		let savedObject = StateRecovery.get(key)
		let checkedResult = {}, columsSizes = {}, sorting = {}
		savedObject.columsSizes && Object.keys(savedObject.columsSizes).forEach((c) => {
			if (getField(c) || c == '_checkbox') {
				columsSizes[c] = savedObject.columsSizes[c]
			}
		})
		if (savedObject.sorting && savedObject.sorting.order != null && getField(savedObject.sorting.field)) {
			sorting = savedObject.sorting
		}
		if (!_.isEmpty(columsSizes)) {
			checkedResult['columsSizes'] = columsSizes
		}
		if (!_.isEmpty(sorting)) {
			checkedResult['sorting'] = sorting
		}
		return checkedResult
	}
}

const propsForClean = [ LocalStorageKeysService.buildKeyForNotShow('release-notes'), 'tasks2']

function getField(f) {
	return app.fields.find((fld) => { return fld.get('systemName') == f || fld.get('id') == f.substring(5) || fld.get('id') == f })
}

export default LocalStorageKeysService
