export class FieldService {

	constructor(context) {
		this.context = context;
	}

	disableItem(viewItemId) {
		const field = this.getItem(viewItemId) || this.context.wizardView.forms[0].findComponentObject(viewItemId);
		if (field) {
			field.disabled = true;
			field.disable();
		}
	}

	enableItem(viewItemId) {
		let field = this.getItem(viewItemId) || this.context.wizardView.forms[0].findComponentObject(viewItemId);
		if (field) {
			field.disabled = false;
			if (this.context.updating) {
				field.enable();
			}
		}
	}

	putFocusInField(viewItemId) {
		const field = this.getItem(viewItemId);
		if (field) {
			field.el.focus();
		}
	}

	setRequiredField(viewItemId, required) {
		const field = this.getItem(viewItemId);
		if (field) {
			const group = this.getGroupForField(field);
			if (group) {
				const name = group.querySelector('.field-name');
				if (required) {
					name.classList.add('required-sign');
				} else {
					name.classList.remove('required-sign');
				}
			}			
		}
	}

	hideItem(viewItemId) {
		this.getGroupAndPerformAction(viewItemId, (group) => {
			group.classList.add('hidden');
		})
	}

	showItem(viewItemId) {
		this.getGroupAndPerformAction(viewItemId, (group) => {
			group.classList.remove('hidden');
		})
	}

	setStyleItem(viewItemId, styles) {
		this.getGroupAndPerformAction(viewItemId, (group) => {
			group.style.cssText = styles;
		})
	}

	getGroupAndPerformAction(viewItemId, callback) {
		const field = this.getItem(viewItemId);
		if (field) {
			const group = this.getGroupForField(field);
			if (group) {
				callback(group);
			}
		} else {
			const group = this.getElementByDataItemId(viewItemId);
			if (group) {
				callback(group);
			}
		}
	}

	getGroupForField(field) {
		return field.el.closest('.form-group');
	}

	getItem(viewItemId) {
		return this.context.wizardView.forms[0].findComponentObjectByViewId(viewItemId);
	}

	getElementByDataItemId(dataItemId) {
		return this.context.el[0].querySelector(`[data-item-id="${dataItemId}"]`);
	}


}
