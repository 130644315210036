/* @flow */

import utils from '../../common/components/utils.js';
import BaseModel from '../models/baseModel';
import MultilingualInputField from './multilingualInputField.js';
import MultilingualString from '../models/multilingualString';

export default class CreateModal extends Backbone.View {

	initialize (op) {
		const identifier =  op && op.id || 'create-modal'
		this.setElement($('#' + identifier));
		$('#' + identifier).on('shown.bs.modal', () => {
			$('#' + identifier).find('input[data-field]').first().focus();
		});
	}

	show (options: Object) {
		if (options.confirmRequired === undefined ||
			options.confirmRequired === true ||
				(_.isFunction(options.confirmRequired) && options.confirmRequired())) {

			this.model = options.model || new BaseModel({ name: { translations: {} } });

			this.$el.find('.modal-body').empty();
			this.url = options.url;
			this.afterCreateOnSuccess = options.afterCreateOnSuccess;
			this.viewModel = options.createView &&
				new options.createView({
					model: this.model,
					$el: this.$el.find('.modal-body'),
					initOptions: options.initOptions,
					addShared: options.addShared // TODO: rewrite this.
				})
			Object.entries(this.viewModel.selects).forEach(([attr, select]) => select.render())
			Object.entries(this.viewModel.inputs).forEach(([attr, input]) => input.render())
			if (!options.preventCreateOnEnter) {
				this.initializeEnterPressingEvent();
			}
			const resource = app.getResource(options.headerResource);
			const customHeader = options.customHeader;
			this.$el.find('.modal-header h4').html(customHeader || resource);
			this.$el.find('.modal-header h4')
						.append(`<button type="button" class="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">×</span>
										</button>`);
			this.$el.find('.createButton')
				.off('click')
				.on('click', e => {
					this.onCreateClick(options);
					e.stopPropagation();
				});
			this.$el.modal('show')
		} else {
			if (options.then) {
				options.then();
			}
		}
	}

	onCreateClick(options) {
		let missedFields = this.viewModel.requiredCheck && this.viewModel.requiredCheck()
		if (missedFields && missedFields.length) {
			missedFields.forEach((attr) => {
				let resource
				switch (attr) {
					case 'fieldType':
						resource = 'data.type'
						break
					default:
						resource = attr
				}
				app.notificationManager.addError(app.getResource('missed.required.field') + ' ' + app.getResource(resource))
			})
			return
		}
		_.each(this.viewModel.inputs, (input) => {
			input.prepareToSave && input.prepareToSave();
		});
		utils.postRequest(this.prepareData(), this.url)
			.then((response) => {
				if (this.afterCreateOnSuccess) {
					this.afterCreateOnSuccess(response);
				}
				this.$el.find('select').select2('close');
				this.$el.modal('hide');
				this.removeHandler();
			}).catch(e => {
				if (e.status === 403) {
					this.$el.modal('hide');
				}
				throw e;
			});
	}

	createFunc(event) {
		var keycode = (event.keyCode ? event.keyCode : event.which);
		if(keycode == '13' && !event.data.$el.find('.btn[data-action="cancel"]').is(':focus')) {
			event.data.onCreateClick();
		}
	}

	removeHandler() {
		$(document).off("keyup", this.createFunc);
	}

	initializeEnterPressingEvent() {
		$(document).on("keyup", this, this.createFunc);
		this.$el.on('hidden.bs.modal',() => {
				this.removeHandler()
		})
	}

	prepareData(): Object {
		return this.viewModel.saveConfiguration();
	}
}
