var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Table", {
    staticClass: "highViewport",
    attrs: {
      table: { loadMore: _vm.loadMore, rowClasses: _vm.rowClasses },
      rows: _vm.rows,
      columns: _vm.columns
    },
    on: { rowClicked: _vm.onClickCell }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }