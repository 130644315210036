import utils from '../../common/components/utils.js';
import MetaObjectTreeViewKind from '../../common/enums/metaObjectTreeViewKind';
import { entityFolderService } from '../../common/service/entityFolderService';
import { entityInstanceService } from '../../common/service/entityInstanceService';
import { metaObjectService } from '../../common/service/metaObjectService';
import BaseModel from '../../common/models/baseModel';
import MultilingualString from '../../common/models/multilingualString';
import MultilingualInputField from '../../common/components/multilingualInputField.js';
import { multilingualStringService } from '../../common/service/multilingualStringService';
import {translate} from '../../common/service/stringResourceService'

class FoldersTree extends Backbone.View {

		initialize(options) {
				let that = this;
				this.$captionEl = options.captionEl;
				this.table = options.table;
				this.addInstance = options.addInstance;
				this.showInstance = (typeId, rowId) => options.showInstance(typeId, rowId);
				this.rootText = (new MultilingualString(app.types.get(options.typeId).get('name'))).getCurrentValue();
				this.clientRootId = 'root';
				this._selectedNode = {};
				this.typeId = options.typeId;
				this.fieldId = options.fieldId || null;
				this.treeViewKind = options.treeViewKind
				this.showRoot = options.showRoot
				this.addNewInContextMenu = options.addNewInContextMenu
				this.dndEnabled = options.dndEnabled
				this.statefull = options.statefull
				this.wholeTree = options.wholeTree
				this.isTreeSelectable = options.isTreeSelectable,
				this.onlyFolderTree = options.onlyFolderTree
				this._checkedNodes = []
				this.updateTableSelection = options.updateTableSelection
				this.changedFromTable = false
				this.loadAllInstances = options.loadAllInstances
		}

		render() {
				var that = this;
				let plugins = ['types', 'contextmenu']
				if (this.statefull) {
					plugins.push('state')
				}
				if (this.dndEnabled) {
					plugins.push('dnd')
				}
				if (this.isTreeSelectable) {
					plugins.push('checkbox')
				}
				this.tree = Promise.resolve(this.$el.jstree({
								core: {
										data: {
												url: app.urls.getFolders,
												data: function(node) {
														var info = {
																instanceId: (node.id === that.clientRootId ||
																		node.id === '#') ? null : node.id,
																typeId: that.typeId,
																treeRoot: node.id === '#' && that.showRoot === true,
																wholeTree: that.wholeTree === true,
																treeViewKind: that.treeViewKind
														};
														return info;
												},
												success: function(folders) {
														return that.process(folders);
												},
										},
                    check_callback: function(operation, node, nodeParent, nodePosition, more) {
                      if (operation === 'move_node') {
                        let folders = nodeParent.children.filter((c) => {
                          return this.get_node(c).original.isFolder
                        })
                        if (node.original.isFolder){
                          if (nodePosition > folders.length) {
                            return false
                          }
                        }else{
                          if (folders.length > nodePosition) {
                            return false
                          }
                        }
						if ( more.ref && that.treeViewKind == MetaObjectTreeViewKind.NO_INSTANCE_CHILDREN &&
						    ((!more.ref.original.isFolder) || (more.ref.parent && (more.pos == 'a' || more.pos == 'b') && !this.get_node(more.ref.parent).original.isFolder))) {
							    return false
						}
                      }
                      if (more && (more.pos === 'a' || more.pos === 'b') && operation === 'move_node') {
                        if (this.get_node(nodeParent).id === '#') {
                          return false
                        }
                      }
                      return true
                    },
										force_text: true,
										themes: {
												name: 'proton',
												responsive: true
										}
								},
								contextmenu: {
										show_at_node: false,
										items: function ($node) {
												var menuItems = {};
												if (that.addNewInContextMenu) {
													menuItems['AddNew'] = {
															label: app.getResource('add.item'),
															icon: 'fa fa-file-o',
															action: function () {
																that.addInstance();
															}
													};
												}
												if (that.treeViewKind !== MetaObjectTreeViewKind.NO_FOLDERS) {
													menuItems['Create'] = {
															label: app.getResource('create.folder'),
															icon: 'fa fa-folder-o',
															action: function (data) {
																	var inst = $.jstree.reference(data.reference);
																	var obj = inst.get_node(data.reference);
																	inst.create_node(obj, {
																			type: 'default',
																			icon: 'fa fa-folder-o'
																	}, 'first', function(newObj){
																		console.log(newObj);
																		let el = inst.get_node(newObj.id, true);
																	});
															}
													};
												}
												if ($node.original.childrenCnt > 0) {
														menuItems['Expand'] = {
																label: app.getResource('expand'),
																icon: 'fa fa-level-down',
																action: function(data) {
																		var inst = $.jstree.reference(data.reference);
																		var obj = inst.get_node(data.reference);
																		that.expandSubTree(obj.id);
																}
														};
												}
												if ($node.original.id !== that.clientRootId && $node.original.isFolder) {
													menuItems['Rename'] = {
															label: app.getResource('rename'),
															_disabled: false,
															shortcut: 113,
															icon: 'fa fa-pencil',
															action: function(data) {
																	let inst = $.jstree.reference(data.reference);
																	let obj = inst.get_node(data.reference);
																	let el = data.reference;
																	that.edit(obj, el);
															}
													}
												}
												if ($node.original.id !== that.clientRootId) {
													menuItems['Delete'] = {
															label: app.getResource('delete'),
															_disabled: false,
															icon: 'fa fa-trash-o',
															action: function(data) {
																// Get node ids
																const tree = $.jstree.reference(data.reference);
																let treeNode = tree.get_node(data.reference);
																let nodeIds = [treeNode.id];
																if (tree.is_selected(treeNode)) {
																		nodeIds = tree.get_selected();
																}
																// Confirm and process the deletion
																utils.postRequest(nodeIds, app.urls.hierarhySize(that.typeId)).then(response => {
																	let resource = '';
																	if (response.size > nodeIds.length) {
																		resource = response.fullSize ? 'action.delete.items.question.with.hierarchy.precise' :
																			'action.delete.items.question.with.hierarchy';
																		resource = multilingualStringService.format(app.getResource(resource), [response.size]);
																	}
																	utils.confirmDelete(
																		nodeIds,
																		(app.builderMode ? entityInstanceService.getDependeciesUrl() : null),
																		entityInstanceService.getDeleteSelectedUrl(that.typeId),
																		{beforeDelete: resource},
																		() => { _.each(nodeIds, (nodeId, index) => {tree.delete_node(nodeId)}) });

																	});
															}
													}
												}
												return menuItems;
										}
								},
								dnd: {
										copy: false
								},
								unique: {
										'duplicate': function(name, counter) {
												return name + ' (' + counter + ')';
										}
								},
								state: {
										'key': 'tree-state-' + that.typeId + '-' + this.fieldId
								},
								checkbox: {
									three_state: false,
									tie_selection: false,
									whole_node: false,
									cascade: 'down+undetermined'
								},
								plugins: plugins
						}));

						this.tree.then(jstree => {
							jstree.on('delete_node.jstree', function(e, data) {
									that.$el.jstree('select_node', data.parent);
							})
							.on('create_node.jstree', function (e, data) {
								if (data.node.parent === '#') {
									return;
								}
								entityFolderService.create({
									name: MultilingualString.fromStringInCurrentLanguage(app.getResource('new.folder')).toJSON(),
									parent: that.getFolder(data.node.parent),
									owner: {
										id: that.typeId
									}
								})
								.then(d => {
									let name = new MultilingualString(d.name)
									data.node.original.isFolder = true;
									data.node.original.id = d.id;
									data.node.original.name = name
									data.instance.set_id(data.node, d.id);
									data.instance.set_text(data.node, name.getCurrentValue())
									data.node.id = d.id;
								});
							})
							.on('move_node.jstree', function(e, data) {
									if (data.parent !== data.old_parent) {
										metaObjectService.move({
														'id': data.node.id,
														'parent': that.getFolder(data.parent)
												})
												.then(() => {
													metaObjectService.moved({
														'id': data.node.id,
														'parent': that.getFolder(data.parent)
													})
												})
												.then(function() {
														data.instance.open_node(data.parent);
														if (data.node.id === that.selectedFolderId) {
																setTimeout(that.buildCaptionPath(data.node.id), 0); // in case selected was moved.
														}
												}).catch(function(e) {
														data.instance.refresh();
												}).finally(() => {
													that.loadAllInstances && that.loadAllInstances(true)
												})
									} else {
										let parent = data.instance.get_node(data.parent)
										entityFolderService.reorder(parent.children).catch(() => {
											data.instance.refresh()
										})
									}
							})
							.on('select_node.jstree', function(e, _data) {

									if (that._selectedNode.id !== _data.node.id) {

											if (that._selectedNode.id) {
													that.previousSelected = that._selectedNode.id
													_data.instance.deselect_node(that._selectedNode);
											}
											that._selectedNode = _data.node;

											var jstree = that.$el.jstree(true);
											jstree.open_node(that._selectedNode.id, function() {});

											that.buildCaptionPath(that._selectedNode.id);
											that.selectedFolderId = that.getFolder(that._selectedNode.id);

											that.table.selectedFolderId = that.selectedFolderId;
											that.table.reload();
									} else if (_data.event && _data.node.children != 0) {
										that.openInstanceNode(_data.node)
									}
							});

							if (this.isTreeSelectable) {
								jstree.on('ready.jstree', function(){ $(this).jstree('open_all') })
								jstree.on('check_node.jstree', function(obj, data) {
									if (!that.isFolder(data.node.id)) {
										that._checkedNodes.push(data.node.id)
									}

									if (!that.changedFromTable) {
										data.node.children_d.forEach((node) => {
											if (!that._checkedNodes.includes(node) && !that.isFolder(node)) {
												that._checkedNodes.push(node)
											}
										})
										that.table.selectedFolderIds = that._checkedNodes
										that.updateTableSelection()
									} else {
										that.table.selectedFolderIds = that._checkedNodes
									}

									that.$el.jstree(true).settings.checkbox.cascade = 'down+undetermined'
									that.changedFromTable = false
								})
								jstree.on('uncheck_node.jstree', function(obj, data) {
									that._checkedNodes = that._checkedNodes.filter((el) => {
										return (el !== data.node.id) && (!data.node.children_d.includes(el)) && !that.isFolder(el)
									})
									that.table.selectedFolderIds = that._checkedNodes

									if (!that.changedFromTable) {
										data.selected.forEach((n) => {
											var currentNode = that.getNode(n)
											that.table.selectedFolderIds.concat(currentNode.children_d)
										})
										that.updateTableSelection()
									}

									that.$el.jstree(true).settings.checkbox.cascade = 'down+undetermined'
									that.changedFromTable = false
								})
							}

							jstree.on('click.jstree', function(event) {
								let node = $(event.target).closest('li')
								that.$el.jstree(true).open_node(node[0].id, function() {
									let obj = that.$el.jstree(true).get_node(node[0].id)
									if (obj.children.length == 0) {
										if (!that.previousSelected) {
											that.selectNode(obj.parent)
										} else {
											that.selectNode(that.previousSelected)
										}
										that.openInstanceNode(obj)
									}
								})
							})
					});

					if (this.onlyFolderTree) {
						this.$el.closest('.row').find('.folders-button-view-builder').remove()
						let elem = this.$el.closest('.row').find('.folders-tree').parent()
						elem.css('width', '100%')
						if (elem.hasClass('col-0')) {
							elem.removeClass('col-0').addClass('col-md-2')
						}
					}
		}

		openInstanceNode(obj) {
			if (!(obj.original.isFolder || obj.original.isRoot)) {
					this.showInstance(this.typeId, obj.id);
			}
		}

		getFolder(folder) {
				return folder === this.clientRootId ? null : folder;
		}

		buildCaptionPath(nodeId) {
				if (!nodeId) {
						return;
				}
				var path = [];
				while (nodeId && nodeId != '#') {
						var folder = this.$el.jstree(true).get_node(nodeId);
						path.push(folder.text);
						nodeId = folder.parent;
				}
				path.reverse();
				this.$captionEl && this.$captionEl.text(path.join(' / '));
		}

		getNode (nodeId) {
			return this.$el.jstree(true).get_node(nodeId)
		}

		getPath(nodeId) {
				if (!nodeId) {
						return;
				}
				var path = [];
				let jstree = this.$el.jstree(true)
				while (nodeId && nodeId != '#') {
						let folder = jstree.get_node(nodeId)
						path.push({id: folder.id, text: folder.text});
						nodeId = folder.parent;
				}
				path.reverse();
				return path
		}

		process(folders) {
				var that = this;
				$.each(folders, function(index, node) {
						node.state = node.state || {};
						node.state.loaded = false;
						if (node.isRoot) {
								node.parent = '#'
								node.id = that.clientRootId
								node.text = that.rootText
								node.icon = 'fa fa-folder-o'
								node.children = true;
						} else {
								if (node.childrenCnt > 0) {
										node.state.closed = true;
										node.children = true;
								} else {
										node.state = 'leaf';
								}
								node.parent = node.parent || that.clientRootId;
								if (node.isFolder) {
										node.icon = 'fa fa-folder-o';
								} else {
										node.icon = 'fa fa-file-o';
								}
								if (!node.isFolder){
									node.a_attr = {
										href: app.urls.update(that.typeId, node.id),
										title: translate('double.click.to.open'),
										'data-toggle': 'tooltip'
									}
								}
						}
						node.name = new MultilingualString(node.name);
				});
				return folders;
		}

		/*
		reload selected node's children
		*/
		reload() {
				var node = this._selectedNode.id ? this._selectedNode.id : this.clientRootId;
				var nodeToRefresh = node == 'root' ? node : this.getNode(node).parent;
				this.$el.one('refresh_node.jstree', () => this.selectNode(node));
				this.$el.jstree(true).refresh_node(nodeToRefresh);
		}

		/*
			expand subtree
		*/
		expandSubTree(nodeId) {
				var jstree = this.$el.jstree(true);
				var that = this;
				jstree.open_node(nodeId, function(node) {
						_.forEach(node.children, function(child) {
								that.expandSubTree(child);
						});
				});
		}

		/*
			edit name
		*/
		edit(obj, el){
			const that = this;
			if (that.editing_object) {
					throw 'cant edit more than 1 node';
			}
			let $wrapper = $(`<div class="control-wrapper" style="padding-left:24px;position:fixed;left:${el.offset().left}px;top:${el.offset().top}px"></div>`)
			let $input = $('<input class="form-control" data-is-string-multilingual="short" data-field="name" type="text" style="display: inline-flex"/>');
			$('body').append($wrapper)
			$wrapper.append($input)
			let model = new BaseModel();
			model.set('id', obj.original.id);
			model.set('name', obj.original.name || new MultilingualString({}));

			let mlsInput = new MultilingualInputField({
					model: model,
					modelAttr: 'name',
					el: $input
			});
			mlsInput.render();
			that.editing_object = {
					obj: obj,
					input: mlsInput,
					el: el,
					model: model,
					save: function() {
							return entityFolderService.update(that.editing_object.model.toJSON());
					},
					close: function() {
							that.editing_object.input.destroy();
							let text = that.editing_object.model.get('name').getCurrentValue();
							that.$el.jstree('rename_node', that.editing_object.obj, text);
							that.editing_object.obj.text = text;
					}
			};
			let doSave = () => {
				that.editing_object.save().then(() => {
					that.editing_object.close();
					that.editing_object = null;
				});
			};
			let registerClick = () => {
				$('body').one('click', e => {
					if (!that.inside(e.target, '.jstree-clicked') &&
							!that.inside(e.target, '.multilingual-btn-group') &&
							!that.inside(e.target, '.control-wrapper')){
						doSave();
					} else {
						registerClick();
					}
				});
			};
			$input.click(() => {
				$input.focus();
			});
			setTimeout(registerClick, 100);
			$input.focus();
		}

		inside(el, parent){
			return $(el).parents(parent).length != 0;
		}

		openParents (parents, callback) {
			var jstree = this.$el.jstree(true)
			if (parents.length) {
				var pid = parents[0]
				jstree.load_node(pid, () => {
					jstree.open_node(pid)
					parents.shift()
					this.openParents(parents, callback)
				})
			} else {
				callback()
			}
		}

		selectNode (id) {
			this.$el.jstree(true).select_node(id)
		}

		addToCheckedNodes (id) {
			this.changedFromTable = true
			this.$el.jstree(true).settings.checkbox.cascade = ''
			this.$el.jstree(true).check_node([id])
		}

		removeFromCheckedNodes (id) {
			this.changedFromTable = true
			this.$el.jstree(true).settings.checkbox.cascade = ''
			this.$el.jstree(true).uncheck_node([id])
		}

		isFolder (id) {
			return this.$el.jstree(true).get_node(id).original.isFolder || this.$el.jstree(true).get_node(id).original.isRoot
		}

};

app.components = app.components || {}
app.components.Tree = FoldersTree

export default FoldersTree;
