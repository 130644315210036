/* @flow */

import FieldInput from '../../common/components/fieldInput.js';
import {translate} from '../../common/service/stringResourceService';
import DatepickerSuggestions from '../../common/components/datepickerSuggestions';

var DatepickerInput = FieldInput.extend({

	initialize: function(options) {
		DatepickerInput.__super__.initialize.apply(this, arguments);
	},

	initSuggestions() {
		_.defer(() => {
			this.datepicker.find('.datepicker')
					.addClass('datepicker-with-suggestions');

			const $container = this.datepicker.find('.datepicker--container1');
			new DatepickerSuggestions(
				this.$el,
				this.setSuggestedValue.bind(this),
				this.modelAttr,
				this.model,
				this.parentField,
				this.context,
				$container);
		});
	},

	setSuggestedValue(value) {
		this.setDataToModel(value);
		this._onHideDropDown();
	},

	_addOkButton: function () {
		const button = $(`<div class="btn btn-success" style="margin:5px;margin-left:auto">${translate('ok')}</div>`)
		button.click(() => {
			this._hideDropdown()
		})
		const buttonPlacement = $('<div class="button-placement" style="display:flex"></div>').append(button)
		this.dropdown.find('.datepicker').append(buttonPlacement)
	},

	_buildDOM: function() {
		this.widget = $(`<div class="dropdown input-group-button-inside inline-block"></div>`);
		this.dropdown = $(`<ul class="dropdown-menu" style="padding-top: 0; padding-bottom: 0"><li></li></ul>`);
		this.datepicker = $(`<div></div>`);
		this.button =
			$(`<button class="btn btn-link input-button soft-button dropdown-toggle"
				tabindex="-1" data-toggle="dropdown" aria-expanded="true">
				<span class="fa fa-calendar"></span>
			</button>`);
		this.dropdown.find('li').append(this.datepicker);
		this.widget.insertAfter(this.$el);
		this.widget.append(this.$el);
		this.widget.append(this.button);
		this.widget.append(this.dropdown);
	},

	_initializeWidgetEvents: function() {
		const that = this;

		this.dropdown.click(function (e) {
			that._onDropdownClick(e);
		});

		this.widget.on('show.bs.dropdown', function () {
			that._onShowDropDown();
		});

		this.widget.on('shown.bs.dropdown', function () {
			that._onShownDropDown();
		});

		this.widget.on('hide.bs.dropdown', function () {
			that._onHideDropDown();
		});

		this.widget.on('hidden.bs.dropdown', function (e) {
			that._onHidenDropDown(e);
		});

		this.button.click(() => {
			this.widget.hasClass('open') ?
				this.widget.addClass('closing') :
				this.widget.addClass('opening');
		});

		this.button.dropdown();
	},

	_onDropdownClick: function(e) {
		e.stopPropagation();
		this.$el.focus();
	},

	_onShowDropDown: function() {
		$('body').append(this.dropdown);
	},

	_onShownDropDown: function() {
		var width = this.dropdown.width();
		var height = this.dropdown.height();
		var top = Math.max(this.widget.offset().top + this.widget.height(), 0);
		var left = Math.max(this.widget.offset().left, 0);

		var diffHeight = $(window).height() - height - top + $(window).scrollTop();
		var diffWidth = $(window).width() - left - width + $(window).scrollLeft();

		this.dropdown.css('top', top + (diffHeight < 0 ?
			- this.widget.height() - height - 5 : 0));
		this.dropdown.css('left', left + (diffWidth < 0 ?
			this.widget.width() - width : 0));

		this.dropdown.css('display', 'block');
		this.dropdown.css('z-index', 100000);
		this.widget.removeClass('opening');
	},

	_onHideDropDown: function() {
		this.dropdown.hide();
		this.widget.append(this.dropdown);
	},

	_onHidenDropDown(e) {
		this.widget.removeClass('closing');
	},

	_showDropdown: function() {
		if (this.widget && !this.widget.hasClass('open')) {
			this.widget.addClass('opening');
			this.button.dropdown('toggle');
			this.$el.focus();
		}
	},

	_hideDropdown: function() {
		if (this.widget && this.widget.hasClass('open')) {
			this.widget.addClass('closing');
			this.button.dropdown('toggle');
		}
	},

	_toggleDropdown: function() {
		if (this.widget && this.widget.hasClass('open')) {
			this._hideDropdown();
		} else {
			this._showDropdown();
		}
	},

	click: function() {
		this._toggleDropdown();
	},

	// triggered in fieldInput onInput
	keyTyped: function () {
		if(this.validate()) {
			this.setNormalView()
		} else {
			this.setErrorView()
		}
	},

	getValue: function() {
		return this.getObject() && this.getObject().toJSON();
	},

	//is triggered:
	// 1) onFocusLost (if not dropdown opened and value is valid);
	// 2) On hidenDropdown (if value is valid);
	setDataToModel: function (obj) {
		this.changesFromInput = false
		if (this.model && !_.isEqual(this.model.get(this.modelAttr) &&
			this.model.get(this.modelAttr).toJSON(), obj && obj.toJSON())) {
			this.model.set(this.modelAttr, obj);
			if (this.model) {
				this.fullValue = obj
				this.model.trigger('manualChange:' +  this.modelAttr, this.model);
			}
		}
	},

	remove: function () {
		this.dropdown.remove()
	},

	enable: function() {
		DatepickerInput.__super__.enable.apply(this, arguments);
		this.$el.parent().removeClass('hidden');
		this.button.removeAttr('disabled');
	},

	disable: function() {
		DatepickerInput.__super__.disable.apply(this, arguments);
		this.$el.parent().addClass('hidden');
		this.button.attr('disabled', 'disabled');
	},

	prepareToSave: function() {
		let obj = this.getObject();
		if (this.model && !_.isEqual(this.model.get(this.modelAttr) &&
			this.model.get(this.modelAttr).toJSON(), obj && obj.toJSON()) && this.validate()) {
			this.model.set(this.modelAttr, obj);
		}
	}
});

export default DatepickerInput;
