
export default class ProgressBarView extends Backbone.View {

		constructor(){
			super({
				el: '.progress-bar'
			});
		}

    set(percentage){
	    this.$el.show(0, () => {
				this.$el.css('width', percentage);
			});
    }

    hide(){
      this.$el.hide();
      this.$el.css('width', '0%');
    }

}
