/* @flow */
import Constants from '../../common/models/constants';
import FieldSelect from '../../common/components/fieldSelect';

export default /*ListWithFieldSelect*/ Backbone.View.extend({

	initialize(options) {
		this.modelAttr = options.modelAttr;
		this.model = this.model.get(this.modelAttr);
		this.items = [];
		this.waitForJqueryUiLoad = options.waitForJqueryUiLoad

		this.fieldSelectModel = new Backbone.Model({
			field: null
		});
		this.fieldSelect = new FieldSelect({
			el: this.$('.field-select'),
			model: this.fieldSelectModel,
			parentField: this.modelAttr,
			modelAttr: 'field',
			typeId: Constants.ID_TYPE_FIELD,
			context: options.context
		});
		this.listenTo(this.fieldSelectModel, 'change:field', this.onFieldChange);
		this.listenTo(this.model, 'add', this.addRow);
		this.listenTo(this.model, 'remove', this.removeRow);
	},

	onFieldChange() {
		const field = this.fieldSelectModel.get('field');
		if (field) {
			this.model.add(this.constructItem(field));
			this.fieldSelectModel.set('field', null);
		}
	},

	constructItem(field) {
		// abstract
	},

	removeRow(item) {
		this.$('#row' + item.cid).remove();

		if (!this.model.length) {
			this.$('.placeholder').show();
		}
	},

	removeAllRows() {
		this.$('.item-row').remove();
	},

	addRow(item) {
		this.$('.placeholder').hide();

		const row = $('<div/>', {
			'id': 'row' + item.cid,
			'data-item-cid': item.cid,
			'class': 'box-header with-border item-row draggable-item'
		});
		const toolsDiv = $('<div class="report-list-tools pull-left">')
		const closeButton = $('<i class="material-icons notranslate list-button secondary-button" role="button">close</i>');
		closeButton.click(() => this.model.remove(item));
		toolsDiv.append(closeButton);
		row.append(toolsDiv);

		if (item.field() == null) {
			row.addClass('has-error');
			row.append($('<span>').text('DELETED FIELD'));
			this.$('.items').append(row);
		} else {
			this.constructRow(row, item);
			this.$('.items').append(row);
			this.initializeRow(row, item);
		}
	},


	constructRow(row, item) {
		// abstract
	},

	initializeRow(row, item) {
		// abstract
	},

	_makeDraggable() {
		this.waitForJqueryUiLoad.then(()=>{
			this.$('.items').sortable({
				items: '.draggable-item',
				connectWith: this.$('.items'),
				zIndex: 10001,
				scroll: false,
				helper: function (event, el) {
					return $('<div>').append(el.clone());
				},
				tolerance: 'pointer',
				opacity: 0.6,
				stop: (event, helper) => {
					helper.item.parent().children('.draggable-item').each((index, el)=>{
						this.model.get(el.getAttribute('data-item-cid')).set('relativeOrder',index)
					})
				},
				appendTo: 'body',
				placeholder: 'sort-highlight',
				forcePlaceholderSize: true
			})
		})
	},
	render() {
		this.removeAllRows();
		this.model.sort();
		this.model.each(item => {
			this.addRow(item);
		});
		this._makeDraggable()
	}

});
