/* @flow */

import Constants from './constants';
import EmbeddedEntity from './embeddedEntity';

export default class ReportValue extends EmbeddedEntity {

	defaults() {
		return _.extend(super.defaults(), {
			includeTotal: true
		});
	}

	get entityTypeId(): number {
		return Constants.ID_TYPE_REPORT_VALUE;
	}

	field() {
		return app.fields.get(this.get('field').id);
	}

	customReturnType() {
		const type = this.get('customReturnType');
		return app.types.get(type && type.id);
	}

	includeTotal() {
		return this.get('includeTotal');
	}

	stringViewId() {
		const stringView = this.get('stringView');
		return stringView && stringView.id;
	}
}
