var Tab = Backbone.View.extend({

	initialize: function (options) {
		this.viewId = options.viewId;
		this.$el.find('[data-target]').off('keypress').on('keypress', function (e) {
			if (e.keyCode == 13 || e.keyCode == 32) {
				e.preventDefault();
				$(e.target).click();
			}
		});
		this.$el.find(`>.tab-content > div`).first().addClass('active');
		this.$el.find('> .nav-tabs > li > a').click(e => {
			let tab = $(e.currentTarget).closest('.nav-tabs-custom');
			tab.find(`> .tab-content > div.active`).removeClass('active');
			let tabId = $(e.currentTarget).data('target');
			if (tabId) {
				tabId = tabId.substring(1, tabId.length);
				tab.find(`> .tab-content  > div[data-item-id=${tabId}]`).addClass('active');
			}
		});
	}

});

export default Tab;
