/* @flow */

import ListWithFieldSelect from './listWithFieldSelect';
import ReportValue from '../../common/models/reportValue';
import AggregateKind from '../../common/enums/aggregateKind';
import MultilingualString from '../../common/models/multilingualString';
import Constants from '../../common/models/constants';
import entityManager from '../../common/components/entityManager';
import utils from '../../common/components/utils';

const ReportValues = ListWithFieldSelect.extend({

	initialize: function (options) {
		ListWithFieldSelect.prototype.initialize.apply(this, arguments);
		const field = app.fields.get(Constants.ID_FIELD_REPORT_VALUES);
		const fieldNameHTML = MultilingualString.toHTML(field.name());
		this.$('.box-title').html(fieldNameHTML);
	},

	constructItem: function (field) {
		return ReportValue.fromJSON({
			field: {'id': field.id, 'isReference': true},
			relativeOrder: this.model.length
		});
	},

	constructRow: function (row, value) {
		const field = app.fields.get(value.field().id);

		const stringView = $('<div />');
		stringView.html(utils.stringView(
			entityManager.fetchStringView(null, field.id),
			null, field.id));
		row.append(stringView);

		const toolsDiv = $('<div class="box-tools pull-right"/>');
		row.append(toolsDiv);

		const select = $('<select class="form-control"/>');
		toolsDiv.append(select);
		select.on('change', () => {
			value.set('aggregateKind', select.val());
		});
	},

	initializeRow: function (row, value) {
		const field = app.fields.get(value.field().id);

		const select = row.find('select');

		const selectData = [];
		_.each(_.keys(AggregateKind), kind => {
			selectData.push({
				id: kind,
				text: kind
			});
		});
		select.select2({
			placeholder: 'Aggregate function',
			data: selectData
		});
		select.val(value.get('aggregateKind') || 'COUNT').trigger('change');

	}

});

export default ReportValues;
