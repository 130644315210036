import { ModalConfirm } from '@codejig/ui-components'

export function showConfirmModal (options) {
  var el = document.createElement('div')
  document.body.appendChild(el)
  new Vue({
    el,
    render: h => h(ModalConfirm, {
      ref: 'modal',
      props: { options }
    })
  })
}
