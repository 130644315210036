import { multilingualStringService } from '../common/service/multilingualStringService'
import utils from '../common/components/utils';
import Stomp from '@stomp/stompjs'
import ProposeToReplaceRouteModal from "../EntityRoute/views/proposeToReplaceRouteModal";
import {hideMergeLoader} from "../help/utils";

const WS_CONNECT = 'WebSocket connect'
class WSWorker {

	postMessage (kind, payload) {
		if (kind === 'connect') {
			this.connect(payload.headers)
		}
		if (kind === 'subscribe') {
			this.client.subscribe(payload.topic, this.callback(payload.topic))
		}
		if (kind === 'disconnect') {
			this.client.disconnect()
		}
	}

	connect (headers) {
		let protocol = {
			'https:': 'wss:',
			'http:': 'ws:'
		}[location.protocol]
		this.endpoint = protocol + '//' + location.host + '/websocket'
		this.client = Stomp.client(this.endpoint)
		this.client.reconnect_delay = 5000
		this.client.debug = () => {}
		this.client.connect(headers, () => {
			this.connectAttemptFailed = 0
			this.onmessage('connected')
		}, (e) => {
			if (e.command === 'ERROR') {
				this.onmessage('error', e.headers.message)
			}
			if (e === 'Whoops! Lost connection to ' + this.endpoint) {
				if (this.connectAttemptFailed > 3) {
					this.onmessage('failed.to.connect.multiple.times')
				} else {
					this.onmessage('failed.to.connect')
				}
				this.connectAttemptFailed++
			}
		})
	}

	callback (topic) {
		return (data) => {
			this.onmessage('message', { data: data.body, topic: topic })
		}
	}

}

class WebSocketClient {

	customUserListeners = [];

	connect() {
		return new Promise((resolve, reject) => {
			this._connect(resolve, reject)
		})
	}

	_connect(resolve, reject) {
		let buildStatusTopic = app.urls.websocket.topic(app.configuration.id)
		let taskManagerTopic = '/user' + app.urls.websocket.taskmanager
		this.worker = new WSWorker()
		this.worker.onmessage = (kind, payload) => {
			if (kind === 'connected') {
				if (this.worker.client.ws.readyState === this.worker.client.ws.OPEN) {
					if (app.builderMode) {
						this.worker.postMessage('subscribe', { topic: buildStatusTopic });
					}
					this.worker.postMessage('subscribe', { topic: taskManagerTopic });
				}
				resolve();
			}
			if (kind === 'failed.to.connect') {
				reject()
			}
			if (kind === 'error') {
				console.error(payload)
			}
			if (kind === 'message') {
				const dataJSON = payload.data;
				const data = dataJSON && JSON.parse(dataJSON);
				const topic = payload.topic
				if (topic === buildStatusTopic) {
					if (data.infoKind) {
						app.taskManager.onTaskInfoReceived(data);
					} else if (_.has(data, 'needToRebuildStatus')) {
						if (data.needToRebuildStatus) {
							app.buildView.showNeedToRebuildNotification();
						} else {
							app.buildView.hideNeedToRebuildNotification();
						}
					} else {
						app.buildView.message(data)
					}
				}
				if (topic === taskManagerTopic) {
					if (data.kind === 'MERGE' && data && data.message && data.message.text && data.message.text.value === 'Template is ready for use') {
						$('.merge-progressbar #line-loader').removeClass('loading').addClass('loaded');
						setTimeout(() => {
							hideMergeLoader();
							app.mergeTemplateModal.show({});
						}, 2000);
						return;
					}
					app.taskManager.onTaskInfoReceived(data);
				}
				if (topic == "/user/custom") {
					this.customUserListeners.forEach(listener => {
						listener(data);
					});
				}
			}
			if (kind === 'failed.to.connect.multiple.times') {
				let err = app.getResource('lost.connection.to.server');
				let lostConnectionMessage = app.notificationManager.showMessage(err, 'error', {
					hide: false
				});
				this.disconnect();

				app.utils.request(undefined, '/containerstatus', 'get')
					.then((statusDto) => {
						if (!statusDto || !statusDto.hasOwnProperty('status'))
							return;

						let statusValue = statusDto.status;

						if (WebSocketClient.containerStatusMapping().hasOwnProperty(statusValue)) {
							let errReason = app.getResource(WebSocketClient.containerStatusMapping()[statusValue]);

							app.notificationManager.updateMessage(
									lostConnectionMessage,
									multilingualStringService.format(errReason, [err])
									);
						}
					});
			}
		}
		this.worker.postMessage('connect', {
			headers: utils.getCSRFHeaders()
		})
	}

	disconnect() {
		this.worker.postMessage('disconnect')
	}

	registerCustomUserListener(listener) {
		if (this.customUserListeners.length == 0) {
			this.worker.postMessage('subscribe', { topic: '/user/custom' });
		}
		this.customUserListeners.push(listener);
	}

	static containerStatusMapping() {
		return {
			'UNKNOWN': 'lost.connection.to.server.reason.stopped',
			'STARTING': 'lost.connection.to.server.reason.restarting',
			'STARTED': 'lost.connection.to.server.reason.restarting',
			'STOPPED': 'lost.connection.to.server.reason.stopped',
			'FAILED': 'lost.connection.to.server.reason.stopped',
//			'BACKING_UP': '',
			'STOPPING': 'lost.connection.to.server.reason.stopped',
			'STOP_REQUESTED': 'lost.connection.to.server.reason.stopped',
			'STOPPED_IDLE': 'lost.connection.to.server.reason.stopped',
//			'START_CONFIRMED': '',
			'FAILED_NO_JAR': 'lost.connection.to.server.reason.stopped',
			'RESTARTING': 'lost.connection.to.server.reason.restarting'
		};
	}

}

export default new WebSocketClient();
