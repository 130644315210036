import MultilingualString from '../../common/models/multilingualString';
import { multilingualStringService } from '../../common/service/multilingualStringService';

/**
 * Available as `app.notificationManager`
 */
class NotificationManager {

	constructor() {
		this.configurePNotify();
	}

	configurePNotify() {
		PNotify.defaults.styling = "bootstrap3";
		PNotify.defaults.icons = "fontawesome4";
		PNotify.modules.Buttons.defaults.sticker = false;
		this.constants = {};
		this.constants.stack_bar_bottom = {
			"dir1": "up",
			"firstpos1": 30,
			"spacing1": 10,
			"push": "bottom"
		};
	}

	/**
	 * @param {MultilingualString} message - error message to be shown
	 */
	addError(message: MultilingualString){
		message = multilingualStringService.stringToModel(message);
		return this.showMessage(message.getCurrentValue(), 'error')
	}

	/**
	 * @param {MultilingualString} message - error message to be shown and trow ex
	 */
	addErrorAndThrow(message: MultilingualString){
		message = multilingualStringService.stringToModel(message);
		this.showMessage(message.getCurrentValue(), 'error')
		throw message
	}

	/**
	 * @param {MultilingualString} message - warning message to be shown
	 */
	addWarning(message: MultilingualString){
		message = multilingualStringService.stringToModel(message);
		return this.showMessage(message.getCurrentValue(), 'notice')
	}

	/**
	 * @param {MultilingualString} message - info message to be shown
	 */
	addInfo(message: MultilingualString){
		message = multilingualStringService.stringToModel(message);
		return this.showMessage(message.getCurrentValue(), 'info')
	}

	/**
	 * @param {MultilingualString} message - success message to be shown
	 */
	addSuccess(message: MultilingualString){
		message = multilingualStringService.stringToModel(message);
		return this.showMessage(message.getCurrentValue(), 'success')
	}

	updateMessage(notification: PNotify, message: MultilingualString) {
		notification.update({
			text: message.getCurrentValue()
		});
	}

	addMessage(message: any){
		this.addMessages([message])
	}

	addMessages(messages: any){
		_.each(messages, m => {
			if (m.kind === 'ERROR'){
				this.addError(m.text);
			}
			if (m.kind === 'WARNING'){
				this.addWarning(m.text);
			}
			if (m.kind === 'INFO'){
				this.addInfo(m.text);
			}
			if (m.kind === 'SUCCESS'){
				this.addSuccess(m.text);
			}
		});
	}

	showMessage(message: string, type: string, options: any) {
		let opts = {
			text: message,
			addClass: "pnotify-width",
			stack: this.constants.stack_bar_bottom,
			delay: 2500,
			width: "35vw"
		};
		if (options) {
			_.extend(opts, options);
		}
		let notification;
		switch (type) {
			case 'error':
				opts.delay = 10000;
				notification = new PNotify.error(opts);
				break;
			case 'info':
				notification = new PNotify.info(opts);
				break;
			case 'notice':
				opts.delay = 10000;
				notification = new PNotify.notice(opts);
				break;
			case 'success':
				notification = new PNotify.success(opts);
				break;
		}
		return notification;
	}
}

export default NotificationManager
