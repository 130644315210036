import MultilingualString from '../../common/models/multilingualString';
import Constants from '../../common/models/constants'

var UsageTree = Backbone.View.extend({

	el: '#usageTree',

	groupByType(data, options) {
		let nodeProperties = (options && options.nodeProperties) || {}
		if (nodeProperties.openNodes === undefined) {
			nodeProperties.openNodes = true
		}
		let nodes = [];
		let grouped = _.groupBy(data, e => e.parentInfo.id);
		let keys = Object.keys(grouped);
		for(let i = 0, l = keys.length; i < l; i++) {
			const parentInfo = grouped[keys[i]][0].parentInfo;
			const configuration = grouped[keys[i]][0].configuration;
			let childrenNodes = [];
			for(let j = 0, p = grouped[keys[i]].length; j < p; j++) {
				let leaf = grouped[keys[i]][j].leafInfo;
				if (!leaf) continue;
				childrenNodes.push({
					text: MultilingualString.getCurrentValue(grouped[keys[i]][j].leafInfo.name),
					children: false,
					a_attr: {
						'href': app.urls.usageDetails(leaf.id, leaf.usageReferrer, parentInfo.id, configuration.id, parentInfo.usageReferrer)
					}
				});
			}
			childrenNodes = _.sortBy(childrenNodes, c => c.text);
			nodes.push({
				text: MultilingualString.getCurrentValue(parentInfo.name),
				children: childrenNodes,
				state: {
					opened: nodeProperties.openNodes
				},
				a_attr: {
					'href': grouped[parentInfo.id][0].leafInfo && grouped[parentInfo.id][0].leafInfo.usageReferrer == 'ENTITY_MENU' ? '#' : app.urls.entityTypeDetails(parentInfo.id, configuration.id, parentInfo.usageReferrer),
					'style': nodeProperties.styleFormatter && nodeProperties.styleFormatter(parentInfo.id)
				}
			});
		}
		return _.sortBy(nodes, node => node.text);
	},

	buildTree(data, options) {
		let nodes = [];
		if (app.configuration.isSystem) {
			let byConfiguration = _.groupBy(data, e => e.configuration.id);
			let keys = Object.keys(byConfiguration);
			for(let i = 0, l = keys.length; i < l; i++) {
				let configurationInfo = byConfiguration[keys[i]][0].configuration;
				nodes.push({
					text: MultilingualString.getCurrentValue(configurationInfo.name),
					children: this.groupByType(byConfiguration[keys[i]], options),
					state: {
						opened: true
					},
					a_attr: {
						'href': app.urls.open(Constants.ID_TYPE_CONFIGURATION, configurationInfo.id)
					}
				});
			}
		} else {
			nodes = this.groupByType(data, options);
		}
		return nodes;
	},

	async render(usages, el, options) {
		var that = this;
		this.setElement(el);
		await this.$el.jstree()
		this.$el.jstree('destroy').empty();
		this.$el.jstree({
			'core': {
				data: function (node, callback) {
					var nodes = [];
					var data = usages.usages;
					nodes = that.buildTree(usages.usages, options);
					callback.call(this, nodes);
				},
				themes: {
					icons: false,
					name: 'proton',
					responsive: true
				}
			},
			'plugins': ['wholerow', 'search']
		}).bind('select_node.jstree', function (e, data) {
			var href = data.node.a_attr.href;
			if (href != '#') {
				window.open(href);
			}
		});
	},

	search(value) {
		this.$el.jstree(true).search(value);
	},

	selectedNodes(full) {
		return this.$el.jstree('get_selected', full);
	}
});

export default UsageTree;
