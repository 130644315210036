import PermissionModel from '../models/permissionModel';
import PermissionFilterKind from '../../common/enums/permissionFilterKind';
import SaveForm from '../../common/views/saveForm';
import MultilingualString from '../../common/models/multilingualString';
import CreatePermissionModal from '../views/createView';
import UpdatePermissionModal from '../views/updateView';
import BaseModel from '../../common/models/baseModel';
import utils from '../../common/components/utils';
import Constants from '../../common/models/constants'

export default class PermissionList extends SaveForm {

	constructor(o) {
		super(_.extend(o || {}, {
			el: '.permission-list',
			events: {
				'click .remove-permission': '_onRemoveButtonClick',
				'click li': '_onListElementClick'
			}
		}));
	}

	initialize(o) {
		super.initialize(o);

		this.listenTo(this.model, 'change:name', this._onNameChanged);
		this.listenTo(this.model, 'add', this._onPermissionAdded);
		this.listenTo(this.model, 'remove', this._onPermissionRemoved);
		this.activePermission = this.model.get(app.defaultPermissionId) || this.model.first();

		this.modal = o && o.modal;
		this.permissionEditor = o && o.permissionEditor;
	}

	render() {
		this._buildPermissionList();

		let link = window.location.href
		let id = link.split("#")[1]
		if (!this.model.get(id)) {
			window.history.replaceState({}, document.title, link.replace(`#${id}`, ''))
			id = app.defaultPermissionId
		}
		let permission = this.model.get(id) || this.model.first();
		this._selectPermission(permission);
	}

  navigationUrl (id) {
    let newUrl = String(document.location).split('#')
    newUrl[1] = id
    document.location = newUrl.join('#')
  }

  _onListElementClick (e) {
    e.stopPropagation()
    let id = e.currentTarget.dataset.id
    this.navigationUrl(id)
    this._selectPermission(this.model.get(id))
  }

	_onRemoveButtonClick(e) {
		e.stopPropagation();
		this._removePermission(this.model.get($(e.currentTarget).attr('data-id')));
	}

	_selectPermission(permission) {
		if (!permission) {
			this.permissionEditor.setModel(permission);
			return ;
		}
		const that = this;
		const $el = this.$el.find(`li[data-id="${permission.id || permission.cid}"]`);
		if (!$el.hasClass('active')) {
			this.modal.show({
				confirmRequired: () => {
					return this.isPermissionChanged();
				},
				resource: 'unstaged.changes',
				buttons: {
					'save': () => {
						this.save(this.permissionEditor.getModel())
					},
					'continue': () => {
						const model = this.permissionEditor.getModel();
						if (model.id) {
							model.initialize(app.permissions[model.id]);
						} else {
							this.model.remove(model);
						}
					}
				},
				then: () => {
					$('.permission-label').html(permission.get('name').toObject());
					this.$el.find('li.active').removeClass('active');
					$el.addClass('active');
					this.permissionEditor.setModel(permission);
				}
			});
		}
	}

	isPermissionChanged() {
		let permission = this.permissionEditor.getModel();
		if (!permission || !this.model.get(permission)) {
			return false;
		}
		const currentData = !permission.id || permission.toServerJSON();
		const originalData = app.permissions[permission.id];
		return !permission.id || !utils.equals(currentData, originalData);
	}

	save(permission) {
		let url = permission.id ? app.urls.update(Constants.ID_TYPE_PERMISSION, permission.id) : app.urls.create(Constants.ID_TYPE_PERMISSION);
		return utils.postRequest(permission.toServerJSON(), url)
			.then(data => {
				this.saveSuccess();
				permission.update(data);
				app.permissions[data.id] = data;
				this.permissionEditor.render();
			})
			.catch(e => {
				this.saveError(e);
			});
	}

	renamePermission() {
		let model = this.activePermission
		let modelToRename = new BaseModel(model.toServerJSON());
		modelToRename.set('name', model.get('name'));
		app.editModal.show({
			url: app.urls.update(Constants.ID_TYPE_PERMISSION, model.id),
			headerResource: 'rename',
			model: modelToRename,
			view: UpdatePermissionModal,
			afterUpdateOnSuccess: (data) => {
				let newName = new MultilingualString(data.name).getCurrentValue();
				$(`.main-sidebar .sub-menu[data-id='${data.id}'] .name,
					 .permission-label span[data-language='${app.currentLanguage}']`).text(newName);
				app.permissions[data.id].name = data.name;
				model.set('name', new MultilingualString(data.name));
			}
		});
	}

	_removePermission(permission) {
		utils.confirmDelete([permission.id], app.urls.dependencies(Constants.ID_TYPE_PERMISSION), app.urls.delete(Constants.ID_TYPE_PERMISSION),
			{ beforeDelete: 'are.you.sure' },
			() => {
				this.permissionEditor.setModel(this.model.models[0]);
				this.model.remove(permission);
				delete app.permissions[permission.id];
			});
	}

	_onNameChanged(permission) {
		const $li = this.$el.find(`li[data-id="${permission.id || permission.cid}"]`);
		if ($li.hasClass('active')) {
			$('.permission-label').html(permission.get('name').toObject());
		}
		$li.children('span.translatable').remove();
		$li.prepend(permission.get('name').toObject());
	}

	_onPermissionAdded(permission) {
		const $li = $('<li class="permission-link"/>');
		$li.attr('data-id', permission.id || permission.cid);
		if (permission.id == app.defaultPermissionId) {
			$li.addClass('default-permission-link');
		}
		if (permission.get('name')) {
			$li.append(permission.get('name').toObject());
		}
		if (permission.id != app.defaultPermissionId) {
			$li.append($(`
				<button class="btn btn-link btn-sm remove-permission" data-id="${permission.id || permission.cid}">
					<span class="glyphicon glyphicon-trash"></span>
				</button>
				`));
		}
		$li.appendTo(this.$el);
		$('.no-default-permission').remove();
	}

	_onPermissionRemoved(permission) {
		let $el = this.$el.find(`[data-id="${permission.id || permission.cid}"]`);
    if ($el.hasClass('active')) {
      let id = $el.prev().attr('data-id')
      this.navigationUrl(id)
      this._selectPermission(this.model.get(id))
    }
		$el.remove();
	}

	_buildPermissionList() {
		if (!this.model.size()) {
			$('.no-default-permission').removeClass('hidden');
		} else {
			this.model.models
				.sort((a,b) => {
					if (a.id && b.id) {
						return a.id - b.id;
					}
					if (a.id || b.id) {
						return a.id ? -1 : 1;
					}
					return a.cid == b.cid ? 0 : a.cid < b.cid ? -1 : 1;
				})
				.forEach(m => this._onPermissionAdded(m));
		}
	}
}

export function createNewPermission() {
	const that = this;
	const newModel = new BaseModel();
	newModel.set('name', new MultilingualString());
	newModel.set('defaultFilter', {
		kind: PermissionFilterKind.EVERYONE
	});
	app.createModal.show({
		url: app.urls.create(Constants.ID_TYPE_PERMISSION),
		headerResource: 'add.permission',
		model: newModel,
		afterCreateOnSuccess: (data) => {
			utils.redirectTo(app.urls.open(Constants.ID_TYPE_PERMISSION, data.id));
		},
		createView: CreatePermissionModal
	});
}
