export default function (select) {
  var multilingual = '';

  if (select === 'short') {
    for (var i = 0; i < app.enabledLanguages.length; i++) {
      multilingual += "<div><label for='name' class='control-label'>" + app.enabledLanguages[i].name +
      "</label><input class='form-control' type='text' data-locale='" + app.enabledLanguages[i].languageTag + "'></div>"
    }
  } else {
    multilingual += "<div class='nav-tabs-custom'>"
    multilingual += "<ul class='nav nav-tabs' role='tablist'>"
    for (var i = 0; i < app.enabledLanguages.length; i++) {
      multilingual += "<li class='lang-tab'><a href='#" + app.enabledLanguages[i].languageTag +
       "' role='tab' data-toggle='tab' data-locale='" + app.enabledLanguages[i].languageTag + "'><span>"
       + app.enabledLanguages[i].name + "</span></a></li>"
    }
    multilingual += "</ul><div class='tab-content' style='padding: 0'>"
    for (var i = 0; i < app.enabledLanguages.length; i++) {
      multilingual += "<div class='tab-pane fade' id='" + app.enabledLanguages[i].languageTag +
       "'><div class='panel panel-default'><textarea class='panel-body form-control custom-control' style='resize: vertical' rows='5' data-locale='"
       + app.enabledLanguages[i].languageTag + "'></textarea></div></div>"
    }
    multilingual += "</div></div>"
  }

  return multilingual;
}
