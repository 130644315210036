class UrlService {
  constructor() {
    this.url = new URL(window.location.href);
  }

  getParameter(key) {
    return this.url.searchParams.get(key);
  }

  putParameter(key, value) {
    this.url.searchParams.set(key, value);
    if (app.cjTabs) {
      app.cjTabs.activeTab.url = this.url.href;
    }
    window.history.replaceState({url: this.url.href}, null, this.url.href);
  }

  deleteParameter(key) {
    this.url.searchParams.delete(key);
    window.history.replaceState({url: this.url.href}, null, this.url.href);
  }

  getAllParameters() {
    let params = {};
    for (const entry of Array.from(this.url.searchParams.entries())) {
      params[entry[0]] = entry[1];
    }
    return params;
  }

  getPrefixURL() {
    return this.url.pathname;
  }

  getURL() {
    return this.url.href;
  }

  setURL(url) {
    this.url.href = url;
    window.history.replaceState({url: this.url.href}, null, this.url.href);
  }
}

export default new UrlService();
