/* @flow */

export default class TypeKind {
	static PRIMITIVE = 'PRIMITIVE';
	static EMBEDDED = 'EMBEDDED';
	static REGISTER = 'REGISTER';
	static DICTIONARY = 'DICTIONARY';
	static REPORT = 'REPORT';
	static DOCUMENT = 'DOCUMENT';
	static TRANSIENT = 'TRANSIENT';
	static isWithMetaObject = function(a) {
		return (( a == this.DICTIONARY ) || ( a == this.DOCUMENT ) || ( a == this.REPORT ))
	}
}
