import ModelFactory from '../../common/models/modelFactory';
import utils, {openLink} from '../../common/components/utils.js';
import Entities from '../../common/collections/entities.js';
import CollectionTable from './collectionTable.js';
import States from '../../common/enums/states';

var EmbeddedFieldTable = CollectionTable.extend({

	baseClass: Entities,

	initialize: function () {
		this.editableOn=true
		this.typeId = utils.getTypeId(this.$el)
		EmbeddedFieldTable.__super__.initialize.apply(this, arguments);
		this.$wrapper=$(`div.table-${this.id}`)
		this.editing = {};
		this.editableClosedCallbacks=[]
		this.tableName=app.fields.get(this.fieldId).fieldName()
	},

	addRow: function () {
		const type = app.types.get(this.typeId);
		const ModelType = ModelFactory.getModelType(type);
		const rowModel = ModelType.fromJSON({});
		rowModel.set('clientState', States.NEW);
		let min = Number.POSITIVE_INFINITY
		this.model.forEach(row => min = Math.min(min, row.get('relativeOrder')))
		if (min==Number.POSITIVE_INFINITY){
			min=1
		}
		rowModel.set('relativeOrder', min - 1);
		this.model.add(rowModel);
		setTimeout(() => {
			this.newTable.openEditMode({id: this.getRowId(rowModel)})
			setTimeout(() =>{
				var editable = this.firstEditable()
				if (editable){
					this.editing[editable.field].focus()
				}
			},0)
		}, 0)
	},

	getRowId (row) {
		return row.getIdOrCid()
	},

	removeRows () {
		let selection = this.newTable.getSelection()
		let rowsToDelete = []
		selection.forEach(rowId => {
			rowsToDelete.push(this.model.get(rowId))
		})
		this.model.remove(rowsToDelete)
		this.newTable.setSelection([])
		this.filters.setFilteredLabelValue(this.data.length)
	},

	firstEditable: function () {
		var editable = this.tableOp.columns.filter((a)=>{return a.editor && !a.isReadOnly})
		return editable && editable[0]
	},

  onReorderRow: function () {
    let relativeOrders=[]
    this.data.forEach(row => {
      relativeOrders.push(row.relativeOrder)
    })
    relativeOrders.sort((a, b) => a - b)
    _.each(this.newTable.getReorderPermutation(), (rowNumber, index) => {
      let item = this.data[rowNumber]
      let model = this.model.get(utils.getIdOrCid(item))
      model.set('relativeOrder', relativeOrders[index], {silent: true})
      if (model.get('clientState') != States.NEW) {
        model.set('clientState', States.UPDATED, {silent: true})
      }
    })
  },

	saveOrder: function () {
		this.onReorderRow();
		this.redraw();
	},
	disable: function(){
		EmbeddedFieldTable.__super__.disable.apply(this, arguments);
		this._get('reorder').attr('disabled','disabled')
		if (this.tableOp){
			this.tableOp.table.editable=false
		}else{
			this.editableOn=false
		}
	},
	enable: function(){
		if (!this.disabled){
			EmbeddedFieldTable.__super__.enable.apply(this, arguments);
			this._get('reorder').removeAttr('disabled')
			if (this.tableOp){
				this.tableOp.table.editable=true
			}else{
				this.editableOn=true
			}
		}
	},
	manualEnable: function(){
			this.disabled = false
			this.enable()
			this._get('selected-button').removeAttr('disabled')
			$(`div.table-${this.id}`).siblings('div.modal').children().removeAttr('disabled')
			_.each(this.tableOp.columns,(column)=>{
				this.setEditor(column)
			})
			let column = this.tableOp.columns[0]
			this.tableOp.columns.splice(0,1,column)
			this.$wrapper.parent().removeClass('disable-all')
	},
	onEditablePreSave: function (item, $tr) {
		let row = this.model.get(utils.getIdOrCid(item))
		if (row.get('clientState') != States.NEW) {
			item.clientState = States.UPDATED;
			row.set('clientState', States.UPDATED, {silent: true});
		}
		_.each(item.editElements, (item) => {
			item._hideDropdown && item._hideDropdown();
		});
	},

	onClickRow: function (row,e) {
		let target=$(e.target)
		if (!((target.parents('a')[0])||e.target.nodeName.toLowerCase()=='a')) {
			if (target.is('.ref')) {
				let key = this.modelAttr + '.' + target.closest('[field]').attr('field')
				if (target.data('kind') == 'use.on.click.event' && app.userObservers.getOnOpenClicked(this.context.type.id + ',' + key)) {
					app.userObservers.getOnOpenClicked(this.context.type.id + ',' + key).call(this.context, this.context.model, this.model.get(row.id))
				} else {
					openLink(target, e, this.model.get(row.id))
				}
			}
		}
	},

  onEditableAfterSave: function () {
    this.context.validationContext.checkCollection(this.tableName);
		this.editableClosedCallbacks.forEach((callback) => {
			callback()
		})
		this.editableClosedCallbacks=[]
  },

  showErrors: function (errors) {
    let forApi = this.transformValidationErrors(errors)
    this.newTable.setValidation(forApi)
  },

	transformValidationErrors (errors) {
		let res = {}
		errors.forEach(e => {
			let rowId = e.path.elementIdentifier
			res[rowId] = res[rowId] || {}
			res[rowId][e.path.innerField] = e.message
		})
		return res
	}

});

export default EmbeddedFieldTable;
