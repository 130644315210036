import TypeKind from '../../common/enums/typeKind';

var TypeKinds = Backbone.View.extend({

	el: '.typeKind',

	events: {
		'click': 'onClick'
	},

	visibleKinds: [],
	initialize: function () {

		this.classStr = 'label label-';
		this.kindToColor = {};
		this.kindToColor[TypeKind.PRIMITIVE] = 'default';
		this.kindToColor[TypeKind.EMBEDDED] = 'gray';
		this.kindToColor[TypeKind.REGISTER] = 'info';
		this.kindToColor[TypeKind.DICTIONARY] = 'warning';
		this.kindToColor[TypeKind.REPORT] = 'purple';
		this.kindToColor[TypeKind.DOCUMENT] = 'success';
		this.kindToColor[TypeKind.TRANSIENT] = 'dark';
	},

	setVisibleTypeKinds: function (kinds) {
		this.visibleKinds = kinds
	},
	
	updateVisibility: function() {
		var that = this
		this.$el.each(function(index,elem) {
			if(_.contains(that.visibleKinds,elem.getAttribute("data-kind"))){
				 elem.style.display=""
			 }else{
				 elem.style.display="none"
			 }
		})
	},
	
	render: function () {
		this.updateVisibility()
		var that = this;
		this.$el.each(function () {
			var clazz = that.classStr + that.kindToColor[$(this).attr('data-kind')];
			$(this).append(
				$('<span>').html(app.getResource($(this).attr('data-kind').toLowerCase()))
			);
			$(this).append(
				$('<span style="margin-left:5px">').attr({
					class: clazz
				}).text(' ')
			);
		});
	},

	onClick: function (event)  {
		$(event.currentTarget).toggleClass('active');
	}
});

export default TypeKinds;
